import React from "react"
import './priceExplanation.scss'




const PriceExplanation = (props: Interfaz) => {
   const {
      tipoTarifa,
      explicaciones,
      tituloExplicacion
   } = props;

   return (

      <div className='m-priceExplanation__details-container'>
         <div className='m-priceExplanation__details-type'>
            <p>{tipoTarifa}</p>
         </div>
         <div className='m-priceExplanation__details-explanation'>
            { tituloExplicacion &&
               <p className='m-priceExplanation__details-explanationTitle'>{tituloExplicacion}</p>
            }
            <div>{explicaciones}</div>
         </div>
      </div>
   )
}

interface Interfaz {
   tipoTarifa: string,
   explicaciones: any,
   tituloExplicacion: any,
}



export default PriceExplanation
