import { IImageBulletInfoContainerProps } from "../imageBulletInfoContainer";

function imageBulletInfoContainerAdapter (data: any): IImageBulletInfoContainerProps {
	return ({
		title: data.tituloGeneral,
		image: data.imagenPrincipal.file.url,
		items: data?.referenciaPlantillaContenido?.map(item => (
			{
				title: item.tituloBanner,
				description: item.tituloDescripcion,
			}
		)) ?? [],
	})
}
  
export default imageBulletInfoContainerAdapter;