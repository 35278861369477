import React from "react";
import "./mapOrListButton.scss";

type MapOrListButtonProps = {
  toRender: string;
  setToRender: any;
};

export default function MapOrListButton({
  toRender,
  setToRender,
}: MapOrListButtonProps) {
  return (
    <div className="a-map__or__list">
      <div
        className={toRender === "lista" ? "a-map__or__list__map__btn" : "a-map__or__list__map__btn--hidden"}
        onClick={() => setToRender("mapa")}
      >
        <i className="icon-map-marker-alt" />
        <p className="a-map__or__list__list__btn__p">Ver mapa</p>
      </div>
      <div
        className={toRender === "mapa" ? "a-map__or__list__list__btn" : "a-map__or__list__list__btn--hidden"}
        onClick={() => setToRender("lista")}
      >
        <i className="icon-list" />
        <p className="a-map__or__list__list__btn__p">Ver lista</p>
      </div>
    </div>
  );
}
