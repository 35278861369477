import React from "react"
import "./cardOffice.scss"
import { findTarget, findRel } from "../../../shared/utils/stringUtilities";

const CardOffice = (props: CardOfficeInterface) => {

	const {
		title,
		info,
		locationLink,
		gtmMark
	} = props;

	return (  
		<a className={`a-card-office ${gtmMark}`}
			href={ locationLink } 
			target={ findTarget(locationLink) }
         rel={findRel(locationLink)}>
			<div className='a-card-office__info'>
				<p className='a-card-office__info-title'> { title } </p>
				<span className='a-card-office__info-txt'> { info } </span>
			</div>
			<div className={`a-card-office__link`}>
				<i className={ `icon-map-marker-alt ${gtmMark}` }></i>
				Ver mapa
			</div>
		</a>
)}

interface CardOfficeInterface {
	title: string,
	info: any,
	locationLink: string,
	gtmMark?: string
}

export default CardOffice
