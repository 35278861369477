import React, { useEffect, useState } from "react";

import GridGroup from "../../molecules/grid-group/gridGroup";
import { normalizeTwoWords } from "../../../shared/utils/stringUtilities";
import { ReferenciaContenido } from "../../../interfaces/ReferenciaContenido";
import useBrowserMode from "../../../shared/hooks/useBrowserMode";

import "./gridCardsSection.scss";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const GridCardsSection = (props: ReferenciaContenido) => {
  const { tituloGeneral, referenciaTabsContenido, nombreCategoria, descripcion } = props;
  
  const { window } = useBrowserMode();
  const [windowWidht, setWindowWidth] = useState(window.innerWidth);
  const [activeItem, setActiveItem] = useState(0);

  const updateWidth = () => setWindowWidth(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
  });

  return (
    <div className="o-grid-cards-sec">
      <h2 className="o-grid-cards-sec__title title-secondary">
        {" "}
        {tituloGeneral}{" "}
      </h2>
      <div className='o-grid-cards-sec__intro'> {descripcion.json.content && documentToReactComponents(descripcion.json) } </div>
      <div className="o-grid-cards-sec__tabs">
        <ul>
          {referenciaTabsContenido &&
            referenciaTabsContenido.map(
              (tab, i) =>
                tab.referenciaCursosDestacados && (
                  <li
                    key={`tab${i}`}
                    className={`gtm${normalizeTwoWords(
                      nombreCategoria
                    )}${normalizeTwoWords(tituloGeneral)}${normalizeTwoWords(
                      tab.nombreTab
                    )}`}
                  >
                    <button
                      className={`o-grid-cards-sec__cont-btn ${
                        activeItem === i
                          ? "o-grid-cards-sec__cont-btn--active"
                          : ""
                      } gtm${normalizeTwoWords(
                        nombreCategoria
                      )}${normalizeTwoWords(
                        tituloGeneral
                      )}${normalizeTwoWords(tab.nombreTab)}`}
                      onClick={() => {
                        setActiveItem(i);
                      }}
                    >
                      {" "}
                      {windowWidht > 960
                        ? tab.nombreTab.length > 22
                          ? tab.nombreTab.slice(0, 22) + "..."
                          : tab.nombreTab.slice(0, 26)
                        : tab.nombreTab}
                    </button>
                  </li>
                )
            )}
        </ul>
      </div>
      <div className="o-grid-cards-sec__cont">
        {referenciaTabsContenido &&
          referenciaTabsContenido.map((tab, i) => (
            <div
              key={`block${i}`}
              className={`o-grid-cards-sec__cont-block ${
                activeItem === i ? "o-grid-cards-sec__cont-block--active" : ""
              }`}
            >
              <GridGroup
                title={tab.nombreTab}
                contentCourses={tab.referenciaCursosDestacados}
                gtmMark={`gtm${normalizeTwoWords(
                  nombreCategoria
                )}${normalizeTwoWords(tituloGeneral)}`}
                href={props.redireccionBoton}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default GridCardsSection;
