import React from "react";
import concatCx from "../../../utils/concatCx";
import "./input.scss";

type InputProps = React.ComponentProps<"input">;

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (props: InputProps, ref) => {
    const { ...rest } = props;
    const compClass = "cfmui-input";
    let attributes = {
      ...rest,
      className: concatCx(compClass, props.className)
    };
    return <input ref={ref} {...attributes} />;
  }
);

export default Input;
