import React, { useState } from "react";
import CountUp from "react-countup";
import ReactVisibilitySensor from "react-visibility-sensor";

// Styles //
import "./amount-circle.scss";

interface AmountCircleProps {
    amount: number;
    title: string;
}

const AmountCircle = ({ amount, title }: AmountCircleProps) => {
    const [focus, setFocus] = useState(false);
    return (
        <div className="m-amount-circle">
            <CountUp
                start={focus ? 0 : undefined}
                end={amount}
                duration={2}
                redraw={true}
                separator="."
            >
                {({ countUpRef }) => (
                    <ReactVisibilitySensor
                        onChange={(isVisible) =>
                            isVisible ? setFocus(true) : setFocus(false)
                        }
                    >
                        <span ref={countUpRef} />
                    </ReactVisibilitySensor>
                )}
            </CountUp>
            <p>{title}</p>
        </div>
    );
};

export default AmountCircle;
