import React from 'react'
import "./videoDescription.scss"

export interface IVideoDescriptionProps {
	title: string;
	description: React.ReactNode;
	video: string;
}

const VideoDescription = (props: IVideoDescriptionProps) => {
	
	const { title, description, video } = props;

	return (
		<div className='o-video-description'>
			<div className='o-video-description__container'>
			<div className='o-video-description__text'>
				<h2 className='o-video-description__text__title'>{title}</h2>
				{description && <span className='o-video-description__text__description'>{description}</span>}
			</div>
			<div className='o-video-description__video'>
				<iframe 
					width="545" 
					height="296" 
					src={`https://www.youtube.com/embed/${video}`} 
					title={title} 
					frameBorder="0" 
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
					allowFullScreen></iframe>
				</div>
			</div>
		</div>
	)
}

export default VideoDescription