import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { IBanner2Props } from '../banner2';

function banner2Adapter(data: any): IBanner2Props {
    return {
        title: data.tituloGeneral,
        description: documentToReactComponents(data.descripcion.json),
        btnLabel: data.tituloBoton,
        link: data.redireccionBoton ? data.redireccionBoton : '',
        bg: data.imagenPrincipal.file.url,
        img: data.listaImagenes && data.listaImagenes[0].file.url,
    };
}

export default banner2Adapter;
