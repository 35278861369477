import React, { useState, useEffect, useContext } from 'react';
import Slider from 'react-slick';
import CardCarousel from '../../molecules/card-carousel/cardCarousel';
import LoadingAlgoritmo from '../../molecules/loading-algoritmo/loadingAlgoritmo';
import { ReferenciaContenido } from '../../../interfaces/ReferenciaContenido';
import GlobalContext from '../../../contexts/globalContext';
import { getRecomendationURL } from '../../../services/recomendationURL';
import './carouselAlgoritmo.scss';
import { getAccessToken } from '../../../services/accessToken';

const CarouselAlgoritmo = (props: ReferenciaContenido) => {
    const [recomendations, setRecomendations] = useState<Array<Object>>([]);
    const [sliderIndex, setSliderIndex] = useState(0);
    const [slider, setSlider] = useState<any>();
    const [pathName, setPathName] = useState<string>('');
    const [header, setHeader] = useState<string>('');
    const [stateTop, setStateTop] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const Context = useContext(GlobalContext);

    // Get random number between two numbers
    const getRandom = (min: number, max: number) => {
        return Math.floor(Math.random() * (max - min)) + min;
    }

    // Transform text to URI
    const changeTextGtm = (text: string | undefined) => {
        if (text !== undefined) {
            return text.toLowerCase().split(' ').join('-');
        }

        return text;
    }

    // Set first letter to uppercase 
    const upperCaseFirstLetter = (text: string) => {
        return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    }    

    const getRecomendationAlgoritmo =  (idUser) => {
        const lastHeaderAlgoritmo = localStorage.getItem('headerAlgoritmo');
        const pathCurrent = window.location.pathname;
        const splitPath = pathCurrent.split('/');

        if (!localStorage.getItem('checkU')) {
            localStorage.setItem('checkU', idUser);
        }

        if (!splitPath[splitPath.length - 1] && !splitPath[splitPath.length - 2] && splitPath.length === 2) {
            setPathName('home');
        } else {
            setPathName(splitPath[splitPath.length - 2]);
        }

        setHeader(lastHeaderAlgoritmo || '');
        
         getAccessToken()
            .then(response => {
                const access_token = response.data.data.id_token;
                 getRecomendationURL(access_token, idUser, props.tituloBoton || '')
                    .then(response => {
                        const resultCourses: Object[] = [];
                        const info = response.data;
                        const today = new Date().getDate().toString();

                        if(info.cursos) {
                            info.cursos.map((e: any) => {
                                if (e.url_curso && e.nombre_curso) {
                                    resultCourses.push({
                                        productTypology: e.nombre_categoria !== null ? upperCaseFirstLetter(e.nombre_categoria) : '',
                                        modality: e.modalidad !== null ? upperCaseFirstLetter(e.modalidad) : '',
                                        percentage: Math.round(e.score * 100),
                                        name: e.nombre_curso,
                                        description: e.descripcion_curso !== null ? e.descripcion_curso : '',
                                        image: e.url_imagen ? e.url_imagen : props.imagenPrincipal.fluid.src,
                                        url: e.url_curso
                                    });
                                }
                            });
                        }
                       

                        if (!localStorage.getItem('dayHeaderAlgoritmo') || localStorage.getItem('dayHeaderAlgoritmo') !== today || localStorage.getItem('checkUser') !== idUser) {
                            const textHeader1 = ['Recomendados para ti', 'Cursos pensados para ti', 'Sugeridos para ti'];
                            const textHeader2 = ['A otros usuarios les gustó', 'Disponible para ti', 'Sugeridos para ti'];
                            const textHeader3 = ['Te puede interesar', 'Cursos más populares', 'Disponible para ti'];
                            let textResultHeader = '';
                            const nRandom = getRandom(0, 3);
                            setStateTop(false);

                            if (info?.segmento === "home") {
                                textResultHeader = textHeader1[nRandom];
                            } else if (info?.segmento === "home2") {
                                textResultHeader = textHeader2[nRandom];
                            } else {
                                textResultHeader = textHeader3[nRandom];
                                setStateTop(true);
                            }

                            setHeader(textResultHeader);
                            setLoading(false);
                            localStorage.setItem('dayHeaderAlgoritmo', today);
                            localStorage.setItem('headerAlgoritmo', textResultHeader);
                            localStorage.setItem('checkU', idUser);
                        }

                        setRecomendations(resultCourses);
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        setLoading(true);

        if (!Context.gigyaContext.gigyaAccount) {
            const timer = setTimeout(() => {
                if (!Context.gigyaContext.gigyaAccount) {
                    getRecomendationAlgoritmo('HomeNoSession');
                }
            }, 2500);

            return () => clearTimeout(timer);
        } else {
            getRecomendationAlgoritmo(Context.gigyaContext.gigyaAccount.data.docNumber);
        }
    }, [Context.gigyaContext.gigyaAccount]);

    const goToNext = () => {
        slider.slickGoTo(sliderIndex + 3);
    }

    const goToBack = () => {
        slider.slickGoTo(sliderIndex - 3);
    }

    const isActiveBack = (_true: any, _false: any) => sliderIndex > 0 ? _true : _false;
    const isActiveNext = (_true: any, _false: any) => sliderIndex < 3 ? _true : _false;

    const settings = {
        dots: true,
        infinite: false,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 3,
        centerMode: false,
        autoplay: false,
        autoplaySpeed: 5000,
        responsive: [
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true
                }
            }
        ],
        beforeChange: (_: number, next: number) => setSliderIndex(next)
    }

    return (
        loading ?
            <LoadingAlgoritmo text={props.tituloGeneral} />
            :
            <div className="o-carousel-algoritmo">
                <h2 className="o-carousel-algoritmo__title title-secondary">{header}</h2>
                <Slider ref={_slider => setSlider(_slider)} {...settings} className="o-carousel-algoritmo__slider">
                    {
                        recomendations.map((e: any, k: any) =>
                            <CardCarousel
                                productTypology={e.productTypology}
                                modality={e.modality}
                                percentage={e.percentage}
                                name={e.name}
                                description={e.description}
                                image={e.image}
                                url={e.url}
                                gtm={`gtmHomeClicCarrusel${stateTop ? 'Top' : ''}Contenedor${k + 1}-<${changeTextGtm(e.name)}>-<${changeTextGtm(e.productTypology)}>-<${changeTextGtm(e.modality)}>-<${changeTextGtm(header)}>`}
                                utm={`?utm_source=com&utm_medium=referal&utm_campaign=carrusel&utm_content=${changeTextGtm(e.name)}&utm_term=${pathName}-${Context.gigyaContext.gigyaAccount?.data.docNumber ? Context.gigyaContext.gigyaAccount?.data.docNumber : ''}`}
                                key={k}
                            />
                        )
                    }
                </Slider>

                <div className='arrow-content arrow-content__left' onClick={isActiveBack(goToBack, undefined)} >
                    <div className='arrows' >
                        <div className='arrows-contain' >
                            <div
                                className={isActiveBack('arrow icon-chevron-left active', 'arrow icon-chevron-left')}
                            />
                        </div>
                    </div>
                </div>

                <div className='arrow-content arrow-content__right' onClick={isActiveNext(goToNext, undefined)} >
                    <div className='arrows' >
                        <div className='arrows-contain' >
                            <div
                                className={isActiveNext('arrow icon-chevron-right active', 'arrow icon-chevron-right')}
                            />
                        </div>
                    </div>
                </div>
            </div>
    );
};

export default CarouselAlgoritmo;