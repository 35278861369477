import xmlToJson from "../../../../helpers/xmlToJson";

export default function parseXMLuser({ xml, num, type }: any) {
   let parser = new DOMParser();
   let xmlDoc = parser.parseFromString(xml || "", "text/xml");
   const json = xmlToJson(xmlDoc || "");

   let singlePac = json["soap:Envelope"]["soap:Body"][
      "ns1:ERP110_PSCD_VerificarDerecho_Resp_MT"
   ].pacs;

   let multiplePac = json["soap:Envelope"]["soap:Body"][
      "ns1:ERP110_PSCD_VerificarDerecho_Resp_MT"
   ].pacs.length

   // if singlePac and multiplePac does not exists, then return false
   if (!!!singlePac.PACNumeroDocumento && !!!multiplePac) return false;

   if(singlePac.PACNumeroDocumento){
      json["soap:Envelope"]["soap:Body"][
         "ns1:ERP110_PSCD_VerificarDerecho_Resp_MT"
      ].pacs = [singlePac]
   }

   let beneficiarios = json["soap:Envelope"]["soap:Body"][
      "ns1:ERP110_PSCD_VerificarDerecho_Resp_MT"
   ].pacs.filter((item: any) => {
      return item.PACNumeroDocumento["#text"] === num;
   });
   

   let process: any = {
      firstName: beneficiarios[0].PACPrimerNombre["#text"],
      secondName: beneficiarios[0].PACSegundoNombre["#text"],
      // lastName:
      //    json["soap:Envelope"]["soap:Body"][
      //       "ns1:ERP110_PSCD_VerificarDerecho_Resp_MT"
      //    ].TitularPrimerApellido["#text"],
      lastName: beneficiarios[0].PACPrimerApellido["#text"],
      secondLastName: beneficiarios[0].PACSegundoApellido["#text"],
      dateOfBirth: beneficiarios[0].PACFechaNacimiento["#text"],
      age: parseInt(beneficiarios[0].PACEdad["#text"]),
      tarifa: beneficiarios[0].PACCategoriaSalarial["#text"],
      gender: beneficiarios[0].PACGenero["#text"],
   };
   process.idNumType = type;
   process.idNum = num;
   return process;
}
