import React from "react";
import concatCx from "../../utils/concatCx";

type AllowedTags =
  | "b"
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "p"
  | "span"
  | "strong";

type TextProps = React.ComponentProps<AllowedTags> & {
  children: React.ReactNode;
  as?: AllowedTags;
};

const Text = React.forwardRef<React.ComponentProps<AllowedTags>, TextProps>(
  (props: TextProps, ref) => {
    const { as, children, ...rest } = props;
    const Component = React.createElement(
      as || "span",
      {
        ...rest,
        className: concatCx(`cfmui-${as || "span"}`, props.className),
        ref
      },
      children
    );
    return Component;
  }
);

export default Text;
