import React, { useState } from 'react';
import { ReferenciaContenido } from "../../../interfaces/ReferenciaContenido";
import Button from '../../atoms/button/button';
import RichtextDescription from '../../atoms/richtext-description/richtextDescription';
import './frequent-questions.scss'

const FrequentlyAskedQuestions = (props: ReferenciaContenido) => {

   const [showanswerdes, setquesanswer] = useState(0);
   const changeanswers = (dess: number) => {
      setquesanswer(showanswerdes === dess ? -1 : dess)
   }

   return (
      <div className='m-frequently'>
         <h2 className='m-frequently-title title-secondary'>{props.tituloGeneral} </h2>
         <div className='m-frequently-questions'>
            {
               props.referenciaPlantillaContenido.map((elem, indexpo) => (
               <div className={`m-questions`} key={ elem.tituloBanner.replace(' ', '') }>
                  <div className={`m-questions-title ${indexpo === showanswerdes ? 'm-questions-title-show' : ''} `}
                     onClick={() => changeanswers(indexpo)}
                  >
                     <h3>{elem.tituloBanner}</h3>
                     <button type='button' onClick={() => changeanswers(indexpo)} aria-label={elem.tituloBanner}>
                        <i className={`m-questions-icon ${indexpo === showanswerdes ? 'icon-minus-thin' : 'icon-plus-thin'}`} />
                     </button>
                  </div>
                  <div className={`m-questions-description`}><RichtextDescription {...elem} /></div>
               </div>))
            }
         </div>
         {props.tituloBoton && <div className='m-frequently-button'>
            <Button
               type={"link"}
               classname={`primary`}
               text={props.tituloBoton}
               href={props.redireccionBoton}
               isDisabled={false}
            />
         </div>}
      </div>
   )
}
export default FrequentlyAskedQuestions;
