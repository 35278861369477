import { ICardButtonContainer } from "../cardButtonContainer";

function cardButtonContainerAdapter(data: any): ICardButtonContainer {
	return ({
		title: data.tituloGeneral,
		cards: data?.referenciaPlantillaContenido?.map(card => (
			{
				image: card.imagenPrincipal[0].file.url,
				description: card.tituloDescripcion,
				redirection: card.redireccionBoton,
			}
		)) ?? [],
	})
}
  
  export default cardButtonContainerAdapter;