import React from "react";
import  './requirementsContainer.scss';
import Button from '../../atoms/button/button';

export interface IRequirementsContainerProps {
	title: string;
	cards: [
		{
			image: string;
			title: string;
		}
	]
	buttons: [
		{
			text: string;
			redirection: string;
		}
	]
}

const RequirementsContainer = (props: IRequirementsContainerProps) =>{

	const {title, cards, buttons } = props

    return (
		<div className="m-requirements-container">
			<div className="m-requirements-container__content">
				<h2 className="m-requirements-container__title">{title}</h2>
				<div className="m-requirements-container-items">
					{cards.map((content) => (
						<div className="m-requirements-container-items__item">
							<img className='m-requirements-container-items__item__image' src={content.image} ></img>
							<p className="m-requirements-container-items__item__title">
								{content.title}
							</p>
						</div>
					))}
				</div>
				<div className="m-requirements-container-buttons">
					{
						buttons.map((button, idx) => (
							<Button
								type="link"
								text={button.text}
								classname="m-requirements-container-buttons__button"
								isDisabled={false}
								gtmMark={idx % 2 === 0 ? 'primary' : 'secondary'}
								href={button.redirection}
							/>
						))
					}
				</div>
			</div>
		</div>
    
    )
}


export default RequirementsContainer

