import React from "react"
import "./cardDownload.scss"
import { findTarget, findRel } from "../../../shared/utils/stringUtilities";

const CardDownload = (props: CardDownloadInterface) => {

	const {
		fileTitle,
		fileSize,
		downloadLink,
		gtmMark
	} = props;

	return (  
      <a className='a-card-download'
         href={ downloadLink } 
         target={ findTarget(downloadLink) } 
			rel={findRel(downloadLink)}>

         <i className='a-card-download__icon icon-file-pdf1 hide-on-mobile'></i>
			<div className='a-card-download__info'>
				<p className='a-card-download__info-title'> { fileTitle } </p>
				<span className='a-card-download__info-size'> { fileSize } </span>
			</div>
			<div className={`a-card-download__link ${gtmMark}`}>
				<i className={`icon-download ${gtmMark}`}></i>
				Descargar
			</div>

		</a>
)}

interface CardDownloadInterface {
	fileTitle: string,
	fileSize: any,
	downloadLink: string,
	gtmMark?:string
}

export default CardDownload
