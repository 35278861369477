import React from "react";
import { normalizeTwoWords } from "../../../shared/utils/stringUtilities";
import Button from '../../atoms/button/button';
import YoutubePlayer from "../../molecules/youtube-player/youtubePlayer";
import "./cardVideo.scss";

const CardVideo = (props: ReviewInterface) => {

  const { review, gtmClass } = props;

  return (
    <div className={`a-card-vid gtm${normalizeTwoWords(gtmClass)}${normalizeTwoWords(review.titulo)}`}>
      <div className="a-card-vid__content">
        <div className={`a-card-vid__content__video gtm${normalizeTwoWords(gtmClass)}${normalizeTwoWords(review.titulo)}`}>
          { review.youtubeId ?
            <YoutubePlayer youtubeId={review.youtubeId} titulo={review.titulo} />
          :
            <video className={`gtm${normalizeTwoWords(gtmClass)}${normalizeTwoWords(review.titulo)}`} controls controlsList="nodownload">
              <source src={review.videoPrincipal.file.url} type="video/mp4"></source>
            </video>
          }
        </div>
        <h3 className={`a-card-vid__content__title hide-on-mobile gtm${normalizeTwoWords(gtmClass)}${normalizeTwoWords(review.titulo)}`}>
          {review.tituloBanner}
        </h3>
        <h3 className={`a-card-vid__content__title show-on-mobile gtm${normalizeTwoWords(gtmClass)}${normalizeTwoWords(review.titulo)}`}>
          {review.tituloBanner}
        </h3>

        <div className="a-card-vid__content__menu">
          {review.redireccionBoton &&
            <div className="a-card-vid__content__menu__button">
              <Button
                id='buttonDescription'
                type='link'
                classname={'tertiary '}
                text={review.tituloBoton}
                isDisabled={false}
                href={review.redireccionBoton}
              />
            </div>
          }
        </div>
      </div>
    </div>
  );
};

interface ReviewInterface {
  review: {
    redireccionBoton: string;
    imagenPrincipal: any;
    titulo: string;
    tituloBoton: string;
    documentos: any;
    tituloBanner?: string;
    videoPrincipal?: any;
    youtubeId?: string;
  };
  gtmClass: string;
}

export default CardVideo;
