import React, { ElementType } from "react";

// Styles //
import "./title-section.scss";

interface TitleSectionProps {
    title: string;
    introText?: string;
    type?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
}

const TitleSection = ({ title, introText, type }: TitleSectionProps) => {
    const Heading = type as ElementType;
    return (
        <div className="m-title-section">
            <Heading>{title}</Heading>
            {introText && (
                <p>{introText}</p>
            )}
        </div>
    );
};

TitleSection.defaultProps = { type: "h2" };

export default TitleSection;
