import React, { useState } from 'react';
import { ReferenciaContenido } from '../../../interfaces/ReferenciaContenido';
import { ReferenciaMapa } from '../../../interfaces/ReferenciaMapa';
import Description from '../../atoms/description/description';
import SedeCard from '../sede-card/sedeCard';
import './mapCards.scss';

const MapCards = (props: ReferenciaContenido) => {

    const [ver, setVer] = useState('todas');
    const [limit, setLimit] = useState(6);

    const handleVerClick = async () => {
        if (ver === "todas") {
            setVer("menos");
            setLimit(props.referenciaMapa.length);
        } else {
            setLimit(6);
            setVer("todas");
        }
    }

    return (
        <div className="m-map-cards">
            { props.tituloGeneral &&
                <h2 className="m-map-cards__title">{ props.tituloGeneral }</h2>
            }
            { props.descripcion &&
                <div className="m-map-cards__desc">
                    <Description
                        description={JSON.stringify(props.descripcion.json)}
                        noCentered
                    />
                </div>
            }
            <div className="m-map-cards__sedes">
                { props.referenciaMapa.slice(0, limit).map((value: ReferenciaMapa, index: number) => {
                    return (
                        <div className="m-map-cards__sedes__card" key={index}>
                            <SedeCard sede={value} />
                        </div>
                    );
                })}
            </div>
            { props.referenciaMapa.length > 6 &&
                <div className={`m-map-cards__all --${ver}`} onClick={handleVerClick}>
                    <p className="m-map-cards__all__text">Ver {ver}</p>
                    <i className="icon-chevron-down1"></i>
                </div>
            }
        </div>
    )
}

export default MapCards