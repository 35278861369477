import React from "react";
import "./sedeCard.scss";

type SedeCardProps = {
  sede: any,
}

type SedeItemsProps = {
  name: string,
  image: any,
  mainPhone: string,
  mainAddress: string,
  slug: string
}

function SedeCard({ sede }: SedeCardProps) {

  const { name, image, mainPhone, mainAddress, slug }: SedeItemsProps = sede;

  const handleClick = async () => {
    window.location.href = `${slug}`;
  }

  return (
    <div className="m-sede__card">
      <div className="m-sede__card__img" style={{ backgroundImage: `url(${image[0].file.url})` }} onClick={handleClick}>
      </div>
      <div className="m-sede__card__info">
        <h3 className="m-sede__card__info__name">
          {name}
        </h3>
        <div className="m-sede__card__info__description">
          <div className="m-sede__card__info__description__icon">
            <i className="icon-map-marker-alt"></i>
          </div>
          <p>
            {mainAddress}
          </p>
        </div>
        <div className="m-sede__card__info__description">
          <div className="m-sede__card__info__description__icon">
            <i className="icon-phone"></i>
          </div>
          <p>
            {mainPhone}
          </p>
        </div>
          <a
            target="_self"
            rel="next"
            className="m-sede__card__info__link"
            href={`${slug}`}>
              Ver más
          </a>
      </div>
    </div>
  );
}

export default SedeCard;