import React from "react";
import './elementCourse.scss';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { findTarget, findRel, OptimizeImage } from "../../../shared/utils/stringUtilities";
import Img from "gatsby-image";

const ElementCourse = (props: ElementCourseInterface) => {

	const {
		title,
		info,
		imageSrc,
		imageAlt,
		gtmMark,
		linkTo
	} = props;

	return (
      <a href={ linkTo } 
         className={`m-elem-course ${gtmMark}`} 
         target={ findTarget(linkTo) }
         rel={ findRel(linkTo) }>
			<div className='m-elem-course__img'>
				{imageSrc.fluid ?
					<Img  className={`${gtmMark}`}
						fluid= { imageSrc.fluid }
						alt= { imageAlt || "imagen lateral" }
					/>
				:
					<img  className={`${gtmMark}`}
						src= { imageSrc.file && OptimizeImage(imageSrc.file.url, imageSrc.file.contentType, 250) }
						alt= { imageAlt || "imagen lateral" }
						loading="lazy"
					/>
				}
			</div>
			<div className={`m-elem-course__cont ${gtmMark}`} >
				<span className={`m-elem-course__pretitle ${gtmMark}`}> 
					{title || 'Consulta' } 
				</span>
            { info &&
               <div className={`m-elem-course__info ${gtmMark}`} > 
                  {info.json.content && documentToReactComponents(info.json)}
               </div>
            }
			</div>
		</a>
	)
}

interface ElementCourseInterface {
	title?: string,
	info?: any,
	imageSrc: any,
	imageAlt: string,
	preTitle?: string,
	gtmMark?: string,
	linkTo?: string
}

export default ElementCourse
