import React, { useState, useEffect, useRef } from "react";
import { ReferenciaContenido } from "../../../interfaces/ReferenciaContenido";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import useBrowserMode from "../../../shared/hooks/useBrowserMode";
import useDirectionScroll from "../../../shared/hooks/useDirectionScroll";
import { normalizeTwoWords, OptimizeImage } from "../../../shared/utils/stringUtilities";
import Img from "gatsby-image";
import YoutubePlayer from "../../molecules/youtube-player/youtubePlayer";
import "./historyLink.scss";

const HistoryLink = (props: ReferenciaContenido) => {

   const [indexTag, setIndexTag] = useState(0);
   const { isBrowser, window, document } = useBrowserMode();
   const [isScrollActive, setIsScrollActive] = useState<boolean>(false);
   const [upScroll, setUpScroll] = useState(false);
   const [hideTags, setHideTags] = useState("");
   const refComponent = useRef(null);
   const { lastDirection } = useDirectionScroll();
   const gtmTagsDinamic = `gtm${normalizeTwoWords(props.nombreCategoria)}${normalizeTwoWords(props.tituloGeneral)}`;
   const tags = props.referenciaPlantillaContenido.filter(
      value => value.tituloBoton
   ).map( value => ({tag: value.tituloBoton, subtitle: value.tituloBanner } ) );

   let timerScroll: any;

   const onScrollAction = () => {
      window.clearTimeout(timerScroll);
      timerScroll = setTimeout(() => {
         if (upScroll) {
            window.scroll(0, window.pageYOffset - 180);
            setUpScroll(false);
         }
         const el: HTMLElement = refComponent.current || new HTMLElement();
         const boundElement = el.querySelector(".o-history__link__container__zone")?.getBoundingClientRect();
         let tagSelect = 0;
         const items = el.querySelectorAll(".o-history__link__container__content__item--principal");
         for (let i = 0; i < items.length; i++) {
            if (items.item(i).getBoundingClientRect().top <= 190) {
               tagSelect = i;
            } else {
               setIndexTag(tagSelect);
               setIsScrollActive((boundElement || { top }).top < 160);
               return;
            }
         }
         setIndexTag(tagSelect);
         setIsScrollActive((boundElement || { top }).top < 160);

      }, 66);
   };

   useEffect(() => {
      isBrowser && document.addEventListener("scroll", onScrollAction);
      return () => {
         isBrowser && document.removeEventListener("scroll", onScrollAction);
      };
   }, [upScroll]);

   const goToDecade = (value: string) => {
      const el: HTMLElement = refComponent.current || new HTMLElement();
      const tag = el.querySelector(`.D-${value}`);
      setUpScroll(true);
      tag?.scrollIntoView();
   };

   function getOffset(el) {
      const rect = el.getBoundingClientRect();
      return {
        bottom: rect.bottom + window.scrollY
      };
    }

   const toggleVisibility = () => {
      let hisLink = document.getElementById("tags_zone-link")
      if (window.pageYOffset >= getOffset(hisLink).bottom - 500) {
         setHideTags("hide-tags-link")
      } else {
         setHideTags("")
      }
    }

   document.addEventListener('scroll', toggleVisibility);

   useEffect(() => {
      toggleVisibility();
   }, [window.pageYOffset])
   
   const Bold = (children:any) => (
      <p className="o-history__link__container__content__item__body__text__description--bold">{children}</p>
   );
   
   const Text = (children:any) => (
      <p className="o-history__link__container__content__item__body__text__description">{children}</p>
   )

   const List = (children:any) => (
      <li className="o-history__link__container__content__item__body__text__description__list">{children}</li>
   )

   const textOptions = {
      renderMark: {
         [MARKS.BOLD]: (text:any) => Bold(text)
      },
      renderNode: {
         [BLOCKS.LIST_ITEM]: (_node:any, children:any) => List(children),
         [BLOCKS.PARAGRAPH]: (_node:any, children:any) => Text(children)
      },
   };

   return (
      <div className="o-history__link" ref={refComponent}>
         <article className="o-history__link__header">
            <h2 className="o-history__link__header__h2">{props.tituloGeneral}</h2>
            {(props.descripcion && props.descripcion.json.content) &&
            documentToReactComponents(props.descripcion.json)
            }
         </article>
         <div id="tags_zone-link" className="o-history__link__container">
            <div className="o-history__link__container__zone">
               <ul
                  className={`${hideTags} o-history__link__container__zone__tags${isScrollActive ? " o-history__link__container__zone__tags--freezer" : ""}`} >
                  {tags.map((tag, key) => (
                     <div key={key}>
                        <li
                           key={key}
                           className={`${gtmTagsDinamic}${normalizeTwoWords(tag.tag)} o-history__link__container__zone__tags__tag${key === indexTag ? " o-history__link__container__zone__tags__tag--active" : ""}`}
                           onClick={() => goToDecade((tag.tag).replace(/\s+/g, ''))}
                        >
                           {tag.tag}
                        </li>
                        <span className="o-history__link__container__zone__tags__subtag">
                           {tag.subtitle}
                        </span>
                     </div>
                  ))}
               </ul>
               <div
                  className={`${hideTags} o-history__link__container__zone__box${
                     isScrollActive
                        ? " o-history__link__container__zone__box--freezer"
                        : ""
                  }${
                     lastDirection == 1
                        ? " o-history__link__container__zone__box--move"
                        : ""
                  }`}
               >
                  <button
                     disabled={indexTag <= 0}
                     className="o-history__link__container__zone__box__circle"
                     onClick={() => goToDecade((tags[indexTag - 1].tag).replace(/\s+/g, ''))}
                  />
                  {tags.map((tag, key) => (
                     <span
                        key={key}
                        className={`o-history__link__container__zone__box__item${
                           key === indexTag
                              ? " o-history__link__container__zone__box__item--active"
                              : ""
                        }`}
                     >
                        {tag.tag}
                        <br />
                        {tag.subtitle}
                     </span>
                  ))}
                  <button
                     disabled={indexTag + 1 >= tags.length}
                     className="o-history__link__container__zone__box__circle"
                     onClick={() => goToDecade((tags[indexTag + 1].tag).replace(/\s+/g, ''))}
                  />
               </div>
            </div>
            <div className="o-history__link__container__content">
               {props.referenciaPlantillaContenido.map((value, key) => (
                  <section
                     key={key}
                     className={`o-history__link__container__content__item${value.tituloBoton ? ` o-history__link__container__content__item--principal D-${(value.tituloBoton).replace(/\s+/g, '')}`: ""}`}
                  >
                     <figure className="o-history__link__container__content__item__cover">
                        {value.youtubeId ? (
                           <YoutubePlayer youtubeId={value.youtubeId} />
                        ) : (value.imagenPrincipal ? 
                           value.imagenPrincipal[0].fluid ?
                              <Img
                                 className="o-history__link__container__content__item__cover__img"
                                 fluid={value.imagenPrincipal[0].fluid}
                                 alt={
                                    value.imagenPrincipal[0].title ||
                                    "imagen historia"
                                 }
                              />
                              :
                              <img
                                 className="o-history__link__container__content__item__cover__img"
                                 src={OptimizeImage(value.imagenPrincipal[0].file.url, value.imagenPrincipal[0].file.contentType, 738)}
                                 alt={
                                    value.imagenPrincipal[0].title ||
                                    "imagen historia"
                                 }
                                 loading="lazy"
                              /> 
                        :
                           <p>{value.titulo}</p>)}
                     </figure>
                     <article className="o-history__link__container__content__item__body">
                        <div className="o-history__link__container__content__item__body__text">
                        {value.titulo &&
                           <h3 className="o-history__link__container__content__item__body__text__h3">
                              {value.titulo || "TÍTULO"}
                           </h3>
                        }
                        {value.tituloDescripcion &&
                           <h4 className="o-history__link__container__content__item__body__text__author">
                              {value.tituloDescripcion || "AUTOR"}
                           </h4>
                        }
                        {value.descripcion && 
                           (value.descripcion.json.content && documentToReactComponents(value.descripcion.json, textOptions))
                        }
                        </div>
                        {value.redireccionBoton && (
                           <div className="o-history__link__container__content__item__body__link">
                              <a
                                 href={value.redireccionBoton}
                                 className="o-history__link__container__content__item__body__link__p"
                              >
                                 CONOCER MÁS
                              </a>
                           </div>
                        )}
                     </article>
                  </section>
               ))}
            </div>
         </div>
      </div>
   );
};

export default HistoryLink;
