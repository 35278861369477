import { ICardsVideoProps } from '../cardsVideo';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

function cardsVideoAdapter(data: any): ICardsVideoProps {
    return {
        title: data.tituloGeneral,
        description: documentToReactComponents(data.descripcion.json),
        cardsList:
            data?.referenciaPlantillaContenido?.map((card) => ({
                title: card.tituloBanner,
                description: documentToReactComponents(card.descripcion.json),
                loop: card.imagenPrincipal[0].file.url,
                youtubeId: card.youtubeId ? card.youtubeId : '',
                bgColor: card.tituloDescripcion ? card.tituloDescripcion : '#5907C0',
                textColor: card.tipoTitulo ? card.tipoTitulo : 'white',
            })) ?? [],
    };
}

export default cardsVideoAdapter;
