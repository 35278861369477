import React, {useEffect, useState} from "react";
import "./goUpButton.scss"

const GoUpButton = () => {

   const [visibility, setVisibility] = useState("");

   const handleClick = () => {
      window.scrollTo({
         top: 0,
         behavior: "smooth"
      });
   }

   const toggleVisibility = () => {
      if (window.pageYOffset > 550) {
         setVisibility("visible");
      } else {
         setVisibility("");
      }
    }

   document.addEventListener('scroll', toggleVisibility);

   useEffect(() => {
      toggleVisibility();
    }, [window.pageYOffset])

   return (
      <div className={`a-go__up__button --${visibility}`} onClick={handleClick} >
         <i className="icon-chevron-down1"></i>
      </div>
   )
}

export default GoUpButton