import React, { useState } from 'react'
import { ReferenciaContenido } from "../../../interfaces/ReferenciaContenido";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import './pressNews.scss'

const pressNews = (props: ReferenciaContenido) => {

    const [selected, setSelected] = useState(0);

    const handleLeft = () => {
        if (selected === 0) {
            setSelected(4)
        } else {
            setSelected(selected - 1)
        }
    }

    const handleRight = () => {
        if (selected === 4) {
            setSelected(0)
        } else {
            setSelected(selected + 1)
        }
    }

    return (
        <div className='o-press-news'>
            <div className="o-press-news__container">
                <div className="o-press-news__container__main">
                    <h2 className="o-press-news__container__main__title">
                        {props.tituloGeneral}
                    </h2>
                    <div className="o-press-news__container__main__cover">
                        <img src={props.referenciaPlantillaContenido[selected].imagenPrincipal[0].file.url}/>
                        <i className="left icon-chevron-left1" onClick={() => handleLeft()}></i>
                        <i className="right icon-chevron-right1" onClick={() => handleRight()}></i>
                    </div>
                    <a className="o-press-news__container__main__info"  href={props.referenciaPlantillaContenido[selected].redireccionBoton} target='_blank' rel="noreferrer">
                        <div className="o-press-news__container__main__info__category">
                            <span className="o-press-news__container__main__info__category__category">{props.referenciaPlantillaContenido[selected].tituloDescripcion}</span>
                            <span className="o-press-news__container__main__info__category__date">{props.referenciaPlantillaContenido[selected].tituloBoton}</span>
                        </div>
                        <h3>{props.referenciaPlantillaContenido[selected].tituloBanner}</h3>
                        <p className="o-press-news__container__main__info__description hide-on-mobile">
                            {props.referenciaPlantillaContenido[selected].descripcion.json.content && documentToReactComponents(props.referenciaPlantillaContenido[selected].descripcion.json)}
                        </p>
                    </a>
                </div>
                <div className="o-press-news__container__news">
                    <h2 className="o-press-news__container__news__title">
                        Top 5: notas de prensa
                    </h2>
                    {props.referenciaPlantillaContenido.map((news, index) => (
                        <div key={index} className="o-press-news__container__news__item">
                            <a className="o-press-news__container__news__item__info" href={news.redireccionBoton} target='_blank' rel="noreferrer">
                                <img src={news.imagenPrincipal[0].file.url} className="o-press-news__container__news__item__info__img"></img>
                                <div className="o-press-news__container__news__item__info__titles">
                                    <div className="o-press-news__container__news__item__info__titles__category">
                                        <span className="o-press-news__container__news__item__info__titles__category__category">{news.tituloDescripcion}</span>
                                        <span className="o-press-news__container__news__item__info__titles__category__date">{news.tituloBoton}</span>
                                    </div>
                                    <h3>{news.tituloBanner}</h3>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default pressNews
