import React from "react";
import './presenter.scss';

interface PresenterInterface {
   title:string;
   subtitle:any;
   buttonName:string;
   buttonLink:string;
   className?:string;
   index: number;
}

const Presenter = (props:PresenterInterface) => {
   return (
      <article className={`m-presenter ${props.className || ''}`} >
         <h2 className='title' >{props.title}</h2>
         <div className='subtitle hide-on-mobile' >{props.subtitle}</div>
         {props.buttonName &&
            <a id={`banner-principal--${props.index}`} className={`button ${props.title.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[\s\t\r\n\f]/g, '-').replace(/[!¡?¿]/g,'')}`} href={props.buttonLink} >{props.buttonName}</a>
         }
      </article>
   )
}

export default Presenter;
