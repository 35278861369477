import React from "react"
import './affiliatedPrices.scss'




const AffiliatedPrices = (props: Interfaz) => {
   const {
      tarifa,
      ahorra,
   } = props;

   return (

      <h3 className='m-affiliatedPrices__details-container'>
         <div className='m-affiliatedPrices__details-price'>
            {tarifa}
         </div>
         {
            ahorra &&
            <div className='m-affiliatedPrices__details-shape'>
               <div className='m-affiliatedPrices__details-triangle'></div>
               <div className='m-affiliatedPrices__details-saving'>
                  {ahorra}
               </div>
            </div>
         }
      </h3>
   )
}

interface Interfaz {
   tarifa: string,
   ahorra: any,
}



export default AffiliatedPrices
