import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import './layoutSlider.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useBrowserMode from '../../../shared/hooks/useBrowserMode';

interface LayoutInterface {
   children:any;
   sliderRef?:any;
   customClass?:string;
   widthRelative?: boolean;
   nSlides ?:number;
   hideArrowDisabled?: boolean;
   showDots?: boolean;
}

const LayoutSlider = (props:LayoutInterface) => {

   const { children, sliderRef, customClass, widthRelative } = props;

   const { isBrowser, window } = useBrowserMode();
   const [width, setWidth] = useState<number>( isBrowser ? window.screen.width : 0 );

   useEffect( ()=> {
      if(isBrowser) {
         setWidth( window.screen.width );
         window.addEventListener('resize', change);
         return () => window.removeEventListener('resize', change);
      }
   } , [])

   function change() {
      if(isBrowser) {
         setWidth( window.screen.width );
      }
   }

   function ButtonSlider (props:any) {
      const { className, onClick, customClass } = props;
      return (
         <button
            className={`${className} ${customClass}`}
            onClick={onClick}
         />
      );
   }

   function DootsContainer (props:any) {
      return (
         <ul className='o-layout-slider__container__dots' > {props.dots} </ul>
      )
   }

   const dotElement = () =>  <div className='o-layout-slider__container__dots__dot' />
   const dotElements = (dots:any) => <DootsContainer dots={dots}  />

   const isMobile = width <= 960;

   const settings = {
      infinite: false,
      dots: isMobile || props.showDots,
      slidesToShow: isMobile ? 2 : props.nSlides || 5,
      slidesToScroll: 1,
      arrows: !isMobile,
      nextArrow: <ButtonSlider customClass={'o-layout-slider__container__next'} />,
      prevArrow: <ButtonSlider customClass={'o-layout-slider__container__back'} />,
      customPaging: dotElement,
      appendDots: dotElements
   }

   return (
      <div className='o-layout-slider' >
         <Slider 
            ref={ (slider) => sliderRef && sliderRef(slider) }
            {...settings} 
            className={`o-layout-slider__container ${customClass ? customClass : ''}`}
         >
            {children}
            { widthRelative && isMobile && <div className='o-layout-slider__container__last' /> }
         </Slider>
      </div>
   )
}

export default LayoutSlider;
