import React, { useEffect } from 'react'
import { ReferenciaContenido } from '../../../interfaces/ReferenciaContenido';
import useChangeTheme from '../../../shared/hooks/useChangeTheme';
import Slider from "react-slick";
import './linktree.scss'

const Linktree = (props: ReferenciaContenido) => {

    const [isDark, changeDarkMode] = useChangeTheme();

    useEffect(() => {
        setTimeout(() => {
            const linktreeDots = document.querySelectorAll('ul.slick-dots > li > button')
            if (linktreeDots) {
                for (let i = 0; i < linktreeDots.length; i++) {
                    linktreeDots[i].setAttribute('tabindex', '-1')
                }
            }
            const linktreeCards = document.querySelectorAll('.o-linktree__container__cards__card')
            if (linktreeCards) {
                for (let i = 0; i < linktreeCards.length; i++) {
                    linktreeCards[i].removeAttribute('tabindex')
                }
            }
        }, 3000);
    }, [])


    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        rows: 6,
        slidesToShow: 1,
        slidesPerRow: 1,
        nextArrow: <></>,
        prevArrow: <></>,
    };

    return (
        <div className="o-linktree">
            <div className="o-linktree__container">
                { props.evento ?
                    <button type="button" aria-label="Modo oscuro" onClick={changeDarkMode} className="o-linktree__container__mode">
                        <i className={`hide-mobile icon ${ isDark ? 'icon-sun gtmMenuTransTonalidadFondoBlanco' : 'icon-moon gtmMenuTransTonalidadFondoNegro' }`}/>
                    </button>
                    :
                    null
                }
                { props.imagenPrincipal ?
                    <a href="https://www.comfama.com" rel="noreferrer follow" target="_blank" className="o-linktree__container__topbar">
                        <img src={props.imagenPrincipal.file.url} alt={props.imagenPrincipal.file.fileName} />
                    </a>
                    :
                    null
                }
                <Slider {...settings} className="o-linktree__container__cards">
                    { props.referenciaTabsContenido.map((card, index) => (
                        <a key={index} className="o-linktree__container__cards__card" href={card.redireccionTab} rel="noreferrer follow" target="_blank">
                            <img src={card.imagenLibro.file.url} alt={card.imagenLibro.file.fileName} />
                            <span className="o-linktree__container__cards__card__label">{card.nombreTab}</span>
                            <span className="o-linktree__container__cards__card__more">Ver más</span>
                        </a>
                    ))}
                </Slider>
            </div>
        </div>
    )
}

export default Linktree