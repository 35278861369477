import axios from "axios";

const verifyRegistered = process.env.GATSBY_TRANSVERSAL_FORMULARIOS || ''

export const verifyUserRegister = async (payload: any) => {
   const response = await axios.post(
      `${verifyRegistered}/is-response-registered`,
      payload
   );
   return response.data;
};