
import React from "react"
import { findTarget, findRel, OptimizeImage } from "../../../shared/utils/stringUtilities";
import "./cardNews.scss"

const CardNews = (props: CardNewsInterface) => {

	const {
		imgSrc,
		imgAlt,
		title,
		link,
		label,
		gtmMark,
		modeGallery,
		type
	} = props;

	return (  
      <a className={`a-card-news${modeGallery ? ' a-card-news-gallery' : '' } ${gtmMark}`} 
         href={ link } 
         target={ findTarget(link) }
         rel={ findRel(link)}
      >
			<div className='a-card-news__img'> 
			{imgSrc &&
				<img
					loading="lazy"
					src={OptimizeImage(imgSrc, type, 500)}
					alt={ imgAlt } 
					className={`${gtmMark}`}
				/>
			}
			</div>
			<span className={`a-card-news__label ${gtmMark}`}> { label } </span>
			<h3 className={`a-card-news__title ${gtmMark}`}>
				{ title }
			</h3>
		</a>
)}

interface CardNewsInterface {
	imgSrc: string,
	imgAlt: string,
	title: any,
	link: string,
	label: string,
   gtmMark?: string,
   modeGallery?:boolean,
   type?:string
}

export default CardNews
