function formAbrazosAdapter(data: any) {
  return data?.map((step: any, stepIdx: number) => {
    return {
      id: stepIdx,
      title: step.tituloGeneral,
      fields: step.referenciaFormulario?.map((field: any, fieldIdx: number) => {
        return {
          id: fieldIdx,
          name: field.nombreId,
          type: field.tipoCampo,
          label: field.inputPrincipal,
          required: field.obligatorio,
          disabled: field.deshabilitado,
          options: field.listaSelect?.map((option: any, optionIdx: number) => ({
            id: optionIdx,
            label: option,
            value: option
          }))
        };
      })
    };
  });
}

export default formAbrazosAdapter;
