import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { IVideoDescriptionProps } from "../videoDescription";

function videoDescriptionAdapter(data: any): IVideoDescriptionProps {
	return ({
		title: data.tituloGeneral,
		description: documentToReactComponents(data.descripcion.json),
		video: data.video,
	})
}
  
export default videoDescriptionAdapter;