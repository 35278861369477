import React from "react";
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import "./richtextDescription.scss";
import { useStaticQuery, graphql } from "gatsby";
import useBrowserMode from "../../../shared/hooks/useBrowserMode";

interface RichtextDescriptionProps {
   descripcion: any;
}

const RichtextDescription = (props: RichtextDescriptionProps) => {

   const { descripcion } = props;

   const { isBrowser, document } = useBrowserMode();

   const sdkQuery = useStaticQuery(graphql`
      query SDK_LIST {
         allContentfulHome (filter: {titulo: {eq: "Home comfama.com"}}) {
            nodes {
               listaSdk
            }
         }
      }
   `);

   const sdks = sdkQuery.allContentfulHome.nodes[0].listaSdk;

   const checkSdk = (sdkMatch: any) => {
      if (
         isBrowser &&
         sdks &&
         sdks.filter((sdk: string) => sdkMatch[0].slice(5).includes(sdk))
      ) {
         const sdkScript = document.createElement("script");
         sdkScript.src = sdkMatch[0].slice(5);
         sdkScript.async = true;
         document.body.appendChild(sdkScript);
      }
   };

   const renderEmbeddedAsset = (node: any) => {
      if (node.data.target.fields) {
         return (
            <img
               src={node.data.target.fields.file["en-US"] ?
                  node.data.target.fields.file["en-US"].url :
                  node.data.target.fields.file.url
               }
               alt={node.data.target.fields.file["en-US"] ?
                  node.data.target.fields.file["en-US"].description :
                  node.data.target.fields.file.description
               }
            />
         );
      } else {
         return <></>;
      }
   };

   const renderHyperlink = (node: any) => {
      return (
         <a href={node.data.uri} target="_blank" rel="noreferrer">
            {node.content[0].value}
         </a>
      );
   };

   const renderQuote = (node: any) => {
      return (
         <div className="a-richtext-description a-richtext-description__content a-richtext-description__quote">
            <div className="icon-quote-left" />
            <div className="icon-quote-right" />
            <span>{node.content[0].content[0].value}</span>
         </div>
      );
   };

   const renderCode = (text: any) => {
      let embeddedScript: string = "";
      
      if (text.indexOf("<script") > -1 && text.indexOf("/script>") > -1) {
         embeddedScript = text.slice(
            text.indexOf("<script"),
            text.indexOf("/script>") + 8
         );
         checkSdk(embeddedScript.match(/src=["'][\w/.:]*/g));
      }

      const createMarkup = () => {
         return {
            __html: text.replace(embeddedScript, ""),
         };
      };

      return (
         <object
            className="a-richtext-description a-richtext-description__content a-richtext-description__embed"
            dangerouslySetInnerHTML={createMarkup()}
         />
      );
   };

   const options = {
      renderMark: {
         [MARKS.CODE]: renderCode,
      },
      renderNode: {
         [BLOCKS.EMBEDDED_ASSET]: renderEmbeddedAsset,
         [BLOCKS.QUOTE]: renderQuote,
         [INLINES.HYPERLINK]: renderHyperlink,
      },
   };

   return (
      <div className="a-richtext-description a-richtext-description__container">
         <div className="a-richtext-description a-richtext-description__content">
            {descripcion.json.content && documentToReactComponents(descripcion.json, options)}
         </div>
      </div>
   );
};

export default RichtextDescription;
