import { ICategoriesMenuProps } from "../categoriesMenu";

function categoriesMenuAdapter(data: any): ICategoriesMenuProps {
	return ({
		title: data.tituloGeneral,
		cards: data?.referenciaPlantillaContenido?.map((card) => (
				{
					redirection: card.redireccionBoton,
					image: card.imagenPrincipal[0].file.url,
					title: card.tituloDescripcion,
				}
			)
		) ?? [],
	})
}
  
export default categoriesMenuAdapter;