import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { IStepContainerProps } from "../stepContainer";

function stepContainerAdapter(data: any): IStepContainerProps {
	return ({
		title: data.tituloGeneral,
		steps: data?.referenciaPlantillaContenido?.map(step => (
			{
				title: step.tituloDescripcion,
				description: documentToReactComponents(step.descripcion.json),
			}
		)) ?? [],
	})
}
  
  export default stepContainerAdapter;