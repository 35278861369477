import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { ReferenciaContenido } from "../../../interfaces/ReferenciaContenido"
import './smallCards.scss'

const SmallCards = (props:ReferenciaContenido) => {

    return (
        <div className='o-small-cards'>
            { props.tituloGeneral && 
                <h2 className="o-small-cards__title">{props.tituloGeneral}</h2> 
            } 
            <div className="o-small-cards__container">
                <div className="o-small-cards__container__content">
                    {
                        props.referenciaPlantillaContenido.map((card, key) => (
                            <div className='o-small-cards__container__content__card' key={key} onClick={() => window.open(card.redireccionBoton, "_blank")}>
                                <div className="o-small-cards__container__content__card__upper">
                                    <img src={card.imagenPrincipal[0].file.url} alt="" />
                                    { card.tituloBanner && 
                                        <h3>{card.tituloBanner}</h3>
                                    }
                                </div>
                                <p>{documentToReactComponents(card.descripcion.json)}</p>
                                <i className='icon-arrow-right'></i>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default SmallCards
