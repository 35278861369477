import React from "react"
import { ReferenciaContenido } from "../../../interfaces/ReferenciaContenido";
import { normalizeTwoWords } from "../../../shared/utils/stringUtilities";
import Description from "../../atoms/description/description";
import ReviewSlider from "../review-slider/reviewSlider";
import "./audiosPlayer.scss"

const AudiosPlayer = (props: ReferenciaContenido) => {

    return (
        <div className="m-audios">
            <div className="m-audios__upper">
                <h2 className="m-audios__upper__title">{props.tituloGeneral}</h2>
                { props.descripcion &&
                    <div className="m-audios__upper__description">
                        <Description
                            description={JSON.stringify(props.descripcion.json)}
                        />
                    </div>
                }
                <div className="m-audios__list">
                    <ReviewSlider 
                        reviews={props.referenciaPlantillaContenido}
                        type="audio"
                        gtmClass={`${normalizeTwoWords(props.nombreCategoria)}${normalizeTwoWords(props.tituloGeneral)}`}
                        slides={{ show: 3, rows: 2, s1440: 2 }}
                    />
                </div>
            </div>
        </div>
    )
}

export default AudiosPlayer