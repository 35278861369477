import React from 'react'
import "./imageBulletInfoContainer.scss";

export interface IImageBulletInfoContainerProps {
	title: string;
	image: string;
	items: [
		{
			title: string,
			description: React.ReactNode;
		}
	] 
}

const ImageBulletInfoContainer = (props: IImageBulletInfoContainerProps) => {
  
	const { title, image, items } = props;

	return (
	<div className='o-image-bullet-info'>
		<h2 className='o-image-bullet-info__title'>{title}</h2>
		<div className='o-image-bullet-info-content'>
			<img className='o-image-bullet-info-content__image' src={image} alt={title} />
			<div className='o-image-bullet-info-content__container'>
				<div className='o-image-bullet-info-content__container-center'>
				{
					items.map((row) => (
						<div className='o-image-bullet-info-content__container__item'>
							<span className='o-image-bullet-info-content__container__item__arrow icon-arrow'></span>
							<div className='o-image-bullet-info-content__container__item__text'>
								<h3 className='o-image-bullet-info-content__container__item__text__title'>{row.title}</h3>
								{row.description && <span className='o-image-bullet-info-content__container__item__text__description'>{row.description}</span>}
							</div>
						</div>
					))
				}
				</div>
			</div>
		</div>
	</div>
  )
}

export default ImageBulletInfoContainer