import { IRequirementsContainerProps } from "../requirementsContainer";

function requirementsContainerAdapter(data: any): IRequirementsContainerProps {
	return ({
		title: data.tituloGeneral,
		cards: data?.referenciaPlantillaContenido?.map(card => (
			{
				image: card.imagenPrincipal[0].file.url,
				title: card.tituloDescripcion,
			}
		)) ?? [],
		buttons: data?.referenciaTabsContenido?.map(button => (
			{
				text: button.nombreTab,
				redirection: button.redireccionTab
			}
		)) ?? [],
	})
}
  
  export default requirementsContainerAdapter;