import React from "react";
import concatCx from "../../../utils/concatCx";
import "./radio.scss";

type InputLabelProps = React.ComponentProps<"input"> & {
    children: React.ReactNode;
    fieldOptions: any;
};

// obtain an array in the props value and iterate over it
const Radio = React.forwardRef<HTMLInputElement, InputLabelProps>(
    (props: InputLabelProps, ref) => {
        const { children, fieldOptions, value, id, ...rest } = props;
        const compClass = "cfmui-radio";
        let attributes = {
            ...rest,
            className: concatCx(compClass, props.className),
        };
        return (
            <div className="cfmui-radio__container">
                {fieldOptions.options?.map((option: any) => (
                    <>
                        <input value={option.value} ref={ref} {...attributes} />
                        <label htmlFor={id}>{option.value}</label>
                    </>
                ))}
            </div>
        );
    }
);

export default Radio;
