import React, {useState} from "react";
import CoursesGroup from "../../molecules/couses-group/coursesGroup";
import { normalizeTwoWords } from "../../../shared/utils/stringUtilities";
import { ReferenciaContenido } from '../../../interfaces/ReferenciaContenido';
import './coursesSection.scss';

const CoursesSection = (props: ReferenciaContenido) => {

   const {
		tituloGeneral,
		referenciaTabsContenido,
		nombreCategoria,
		tituloBoton,
		redireccionBoton
   } = props;

	const [activeItem, setActiveItem] = useState(0);

	return (
		<div className='o-courses-sec'>

			<h2 className='o-courses-sec__title title-secondary'> 
            { tituloGeneral }
         </h2>
			
			<div className='o-courses-sec__cont'>
				<div className='o-courses-sec__cont-tabs hide-on-mobile'>
					<ul>
						{ referenciaTabsContenido && referenciaTabsContenido.map((tab, i)=>(
							tab.referenciaCursosDestacados &&
							(	
								<li key={`tab${i}`}>
									<button 
											className={`o-courses-sec__cont-btn ${activeItem===i?'o-courses-sec__cont-btn--active':''} gtm${normalizeTwoWords(nombreCategoria)}${normalizeTwoWords(tituloGeneral)}${normalizeTwoWords(tab.nombreTab)}`}
											onClick={ ()=>{ setActiveItem(i) } }
										>
										{ tab.nombreTab }
									</button> 
								</li>	
							)
						))}
					</ul>
				</div>

				{ referenciaTabsContenido && referenciaTabsContenido.map((tab, i)=>(
					<div
							key= {`block${i}`} 
							className={`o-courses-sec__cont-block ${activeItem===i?'o-courses-sec__cont-block--active':''}`}
						>
						<CoursesGroup
							title = { tab.nombreTab }
							contentCourses = { tab.referenciaCursosDestacados }
							gtmMark = {`gtm${normalizeTwoWords(nombreCategoria)}${normalizeTwoWords(tituloGeneral)}`}
							btnTitle = { tituloBoton }
							btnLink = { redireccionBoton }
						/>
					</div>
				))}
			
			</div>
		</div>
	)
}

export default CoursesSection
