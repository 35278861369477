import React from "react"
import "./audioCard.scss"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import axios from 'axios';
import fileDownload from 'js-file-download';
import { OptimizeImage } from "../../../shared/utils/stringUtilities";

interface AudioCardProps {
   audio: any;
   download?: boolean;
}

const AudioCard = (props: AudioCardProps) => {
   
   const Text = (children:any) => (
      <p className="a-audio__content__titles__text">{children}</p>
   )
   
   const textOptions = {
      renderNode: {
         [BLOCKS.PARAGRAPH]: (_node:any, children:any) => Text(children)
      }
   };

   const handleDownload = () => {
      axios.get(props.audio.audioPrincipal.file.url, {
         responseType: 'blob',
      })
      .then((res) => {
         fileDownload(res.data, `${props.audio.audioPrincipal.title}.${props.audio.audioPrincipal.description ? props.audio.audioPrincipal.description : "mp3"}`)
      })
   }

   return (
      <div className="a-audio">
         <div className="a-audio__cover">
            <img
               loading="lazy"
               src={OptimizeImage(props.audio.imagenPrincipal[0].file.url, props.audio.imagenPrincipal[0].file.contentType, 152)}
               alt={props.audio.imagenPrincipal[0].file.title}
            />
         </div>
         <div className="a-audio__content">
            <div className="a-audio__content__titles">
               <h3 className="a-audio__content__titles__main">{props.audio.tituloBanner}</h3>
               {props.audio.descripcion.json.content && documentToReactComponents(props.audio.descripcion.json, textOptions)}
            </div>
            {props.download &&
               <div className="a-audio__content__download">
                  <span onClick={ () => handleDownload() } >
                     DESCARGAR <i className="icon-download"></i>
                  </span>
               </div>
            }
            <audio id="audioCard" className="a-audio__content__audio" src={props.audio.audioPrincipal.file.url} preload="auto" controls></audio>
         </div>
      </div>
   )
}

export default AudioCard
