import React from "react";
import Marquee from "react-fast-marquee";

// Components //
import TitleSection from "../../molecules/title-section";

// Styles //
import "./companies-trust-us.scss";
import { ReferenciaContenido } from "../../../interfaces/ReferenciaContenido";

const ComponiesTrustUs: React.FC<ReferenciaContenido> = (props) => {

    const {
        tituloGeneral,
        descripcion,
        referenciaPlantillaContenido: Contenido,
    } = props;

    return (
        <div className="container">
            <div className="o-companies">
                <div className="o-companies__container">
                    <TitleSection
                        title={tituloGeneral}
                        introText={descripcion.json.content[0].content[0].value}
                    />
                </div>
                <div className="o-companies__carousel">
                    <Marquee gradient={false} speed={30}>
                        <div
                            className="o-companies__carousel__slider"
                            style={{
                                width: `${(Contenido[0]?.imagenPrincipal?.length / 2) *
                                    180
                                    }px`,
                            }}
                        >
                            {Contenido[0]?.imagenPrincipal?.map((image, i) => (
                                <div className="o-companies__carousel__slider__item" key={i}>
                                    <div className="o-companies__carousel__slider__item__image">
                                        <img
                                            src={image.file.url}
                                            alt={image.title}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Marquee>
                </div>
            </div>
        </div>
    );
};

export default ComponiesTrustUs;
