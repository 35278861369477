import React from "react";
import './stepContainer.scss';

export interface IStepContainerProps {
	title: string;
	steps: [
		{
			title: string;
			description: React.ReactNode;
		}
	]
}

const StepContainer = (props: IStepContainerProps) => {

	const { title, steps } = props;

	return (
		<div className="m-step-container">
			<h2 className="m-step-container__title">{title}</h2>
			<div className='m-step-container-desktop'>
				{
					steps.map((step, idx) => (
						<div className="m-step-container-desktop__item">
							<span className="m-step-container-desktop__item__number">{idx + 1}</span>
							<h3 className="m-step-container-desktop__item__title">
								{step.title}
							</h3>
							<p className="m-step-container-desktop__item__description">
								{step.description}
							</p>
						</div>
					))
				}
			</div>
		</div>
	)

}

export default StepContainer   