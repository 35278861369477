import React,{ useRef }from "react";
import "./file.scss";

type FileProps = React.ComponentProps<"input">;

const File = React.forwardRef<HTMLInputElement, FileProps>(
    (props: FileProps, ref) => {
        const fileInputRef = useRef(null);
        
        const { ...rest } = props;
        let attributes = {
            ...rest
        };
        const handleOnClickRefFile = () => {
           fileInputRef.current.click()
        };
        return (
            <div className="cfmui-file">
                <label>{props.name}</label>
                <input style={{display: props.nameFile[`${props.name}`] && "none" }} ref={fileInputRef} {...attributes}  type="file" />
                {props.nameFile[`${props.name}`] && (
                    <div class="cfmui-file__name">
                        <span>{props.nameFile[`${props.name}`]}</span>
                    </div>
                )}
                <span class="cfmui-file__custom" {...attributes} onClick={handleOnClickRefFile}></span>
            </div>
        );
    }
);

export default File;
