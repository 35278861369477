import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import { ReferenciaContenido } from "../../../interfaces/ReferenciaContenido";
import YoutubePlayer from '../../molecules/youtube-player/youtubePlayer';
import './bannerInforme.scss';

const BannerInforme = (props: ReferenciaContenido) => {

    const [slider, setSlider] = useState<any>();
    const [selected, setSelected] = useState<number>(0);
    const [sliderIndex, setSliderIndex] = useState<number>(0);
    const [translateX, setTranslateX] = useState<number>(340);
    const [videoActive, setVideoActive] = useState<boolean>(false);

    const settings = {
        arrows: false,
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: (_: number, next: number) => handleSlider(next),
        vertical: true,
        verticalSwiping: true
    };

    const handleSlider = (index) => {
        setSliderIndex(index)
        setSelected(index);
    }

    const goTo = (index) => {
        slider.slickGoTo(index);
        setSelected(index);
    }

    const goToNext = () => {
        slider.slickGoTo(sliderIndex + 1);
        setSelected(sliderIndex + 1)
    }

    const goToBack = () => {
        slider.slickGoTo(sliderIndex - 1);
        setSelected(sliderIndex - 1)
    }

    const handleResize = (screenWidth) => {
        if (screenWidth > 1700) {
            setTranslateX(340)
        } else if (screenWidth > 1440 && screenWidth <= 1700) {
            setTranslateX(260)
        } else if (screenWidth > 960 && screenWidth <= 1440) {
            setTranslateX(205)
        } else {
            setTranslateX(168)
        }
    }

    useEffect(() => {
        handleResize(document.body.clientWidth)
    }, [document.body.clientWidth])

    window.addEventListener("resize", function() {
        handleResize(document.body.clientWidth)
    })

    const isActiveBack = ( _true: any, _false: any ) => sliderIndex > 0 ? _true : _false;
    const isActiveNext = ( _true: any, _false: any ) => sliderIndex < props.referenciaPlantillaContenido.length-1 ? _true : _false;

    return (
        <div className='o-banner-informe'>
            <img className='o-banner-informe__cover' src={props.listaImagenes[selected].file.url} alt={props.listaImagenes[selected].title} />
            <Slider ref={ _slider => setSlider(_slider)} {...settings} className='o-banner-informe__carrusel' >
                { props.referenciaPlantillaContenido.map((card, key) => (
                    <div key={key} className="o-banner-informe__carrusel__card">
                        <div className="o-banner-informe__carrusel__card__content">
                            <div className="o-banner-informe__carrusel__card__content__text">
                                {card.descripcion.json.content && documentToReactComponents(card.descripcion.json)}
                            </div>
                            {props.tituloBoton && props.redireccionBoton &&
                                <a className={`button ${props.nombreCategoria.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[\s\t\r\n\f]/g, '-').replace(/[!¡?¿]/g,'')}`} href={props.redireccionBoton} >{props.tituloBoton}</a>
                            }
                        </div>
                    </div>
                ))}
            </Slider>
            {props.referenciaPlantillaContenido.length > 1 ?
                <div className='o-banner-informe__arrows' >
                    <div className='o-banner-informe__arrows__arrows-content' >
                        <div className='arrow arrow-left' onClick={ isActiveBack(goToBack, undefined) }>
                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="73.457" height="17.845" viewBox="0 0 73.457 17.845">
                                <defs>
                                    <clipPath id="a"><rect width="73.457" height="17.845" fill="none"/></clipPath>
                                </defs>
                                <g transform="translate(73.457 17.845) rotate(180)">
                                    <g transform="translate(0 0)" clipPath="url(#a)">
                                        <path d="M22.694,4.162,2.772,17.508A1.144,1.144,0,0,1,1.1,16.986l-.75-1.507a1.556,1.556,0,0,1,.447-1.944L20.719.189a1.144,1.144,0,0,1,1.675.522l.75,1.507a1.561,1.561,0,0,1-.45,1.944" transform="translate(0.316 0.001)" fill="#fff"/>
                                        <path d="M71.558,8.652H1.9A2.074,2.074,0,0,1,0,6.448a2.074,2.074,0,0,1,1.9-2.2H71.558a2.074,2.074,0,0,1,1.9,2.2,2.074,2.074,0,0,1-1.9,2.2" transform="translate(0 9.193)" fill="#fff"/>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <div className='arrow' onClick={ isActiveNext(goToNext, undefined) }>
                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="73.457" height="17.845" viewBox="0 0 73.457 17.845">
                                <defs>
                                    <clipPath id="a"><rect width="73.457" height="17.845" fill="none"/></clipPath>
                                </defs>
                                <g transform="translate(73.457 17.845) rotate(180)">
                                    <g transform="translate(0 0)" clipPath="url(#a)">
                                        <path d="M22.694,4.162,2.772,17.508A1.144,1.144,0,0,1,1.1,16.986l-.75-1.507a1.556,1.556,0,0,1,.447-1.944L20.719.189a1.144,1.144,0,0,1,1.675.522l.75,1.507a1.561,1.561,0,0,1-.45,1.944" transform="translate(0.316 0.001)" fill="#fff"/>
                                        <path d="M71.558,8.652H1.9A2.074,2.074,0,0,1,0,6.448a2.074,2.074,0,0,1,1.9-2.2H71.558a2.074,2.074,0,0,1,1.9,2.2,2.074,2.074,0,0,1-1.9,2.2" transform="translate(0 9.193)" fill="#fff"/>
                                    </g>
                                </g>
                            </svg>
                        </div>
                    </div>
                </div>
                : null
            }
            <div className="o-banner-informe__loop">
                <video id="video-loop" muted loop autoPlay>
                    <source src={props.imagenPrincipal.file.url} type="	video/webm" />
                    Your browser does not support the video tag.
                </video>
            </div>
            <div className='o-banner-informe__num'>
                <div className='o-banner-informe__num__container' style={{transform:`translateX(${-selected * translateX}px)`}}>
                    { props.referenciaPlantillaContenido.map((card, key) => (
                        <span key={key}>0{ key + 1 }</span>
                    ))}
                </div>
            </div>
            <div className="o-banner-informe__dots">
                {
                    props.referenciaPlantillaContenido.map((card, key) => (
                        <div key={key} className={`${card ? 'o-banner-informe__dots__dot' : ''} ${key === selected ? 'dot-selected' : ''}`} onClick={() => goTo(key)}></div>
                    ))
                }
            </div>
            { props.video &&
                <>
                    <div className="o-banner-informe__play" onClick={() => setVideoActive(true)}>
                        <span id="banner-play">▶</span>
                    </div>
                    <div className='o-banner-informe__curtain' style={{left: `${videoActive ? '0vw' : '100vw'}`}} />
                    <div className='o-banner-informe__video' style={{left: `${videoActive ? '0vw' : '100vw'}`}}>
                        <div className="o-banner-informe__video__black" />
                        <YoutubePlayer youtubeId={props.video} />
                        <div className="o-banner-informe__video__close" onClick={() => setVideoActive(false)}>
                            <i className="icon-close" />
                            <span>Cerrar</span>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}

export default BannerInforme
