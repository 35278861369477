import React, { useState } from "react"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import LayoutSlider from '../../templates/layout-slider/layoutSlider';
import { ReferenciaContenido } from '../../../interfaces/ReferenciaContenido';
import { ReferenciaPlantillaContenido } from '../../../interfaces/ReferenciaPlantillaContenido';
import useBrowserMode from '../../../shared/hooks/useBrowserMode';
import { OptimizeImage } from "../../../shared/utils/stringUtilities";
import Button from '../../atoms/button/button';
import Img from "gatsby-image";
import axios from 'axios';
import fileDownload from 'js-file-download';
import './gallery.scss';


const Gallery = (props:ReferenciaContenido) => {

   const handleDownload = () => {
      axios.get(props.descargarContenido.file.url, {
         responseType: 'blob',
      })
      .then((res) => {
         fileDownload(res.data, `${props.descargarContenido.title}.zip`)
      })
   }

   const imgs = props.referenciaPlantillaContenido;

   const [isModal, setIsModal] = useState(false);
   const [index, setIndex] = useState(-1);
   const [content, setContent] = useState<ReferenciaPlantillaContenido| undefined>();
   const [slider, setSlider] = useState<any>(null);
   const { isBrowser, document } = useBrowserMode();

   const showModal = (index:number) => {
      if(isBrowser) {
         const querySelector = document.querySelector('body');
         querySelector?.classList.add('freeze-page')
      }
      setIndex(index);
      setContent(imgs[index]);
      setIsModal(true);
   }

   const hideModal = () => {
      if(isBrowser) {
         const querySelector = document.querySelector('body');
         querySelector?.classList.remove('freeze-page')
      }
      setIndex(-1);
      setContent(undefined);
      setIsModal(false);
   }

   const next = () => {
      slider.slickGoTo(index);
      setContent(imgs[index+1]);
      setIndex(index+1);
   }

   const back = () => {
      slider.slickGoTo(index-1);
      setContent(imgs[index-1]);
      setIndex(index-1);
   }

   return (
      <div className='o-gallery' >
         { props.tituloGeneral &&
            <h2 className='o-gallery__title'>{props.tituloGeneral}</h2>
         }
         <LayoutSlider widthRelative={true} customClass='o-gallery__layout-slider' sliderRef={setSlider} >
            {imgs && 
               imgs.map( (value: ReferenciaPlantillaContenido, index: number) => (
                  <div className='o-gallery__container__cover'  key={index} onClick={ () => showModal(index) } >
                     {value.imagenPrincipal && value.imagenPrincipal[0].fluid ?
                        <Img  className="o-gallery__container__cover__img"
                              fluid= { value.imagenPrincipal[0].fluid }
                              alt={ value.imagenPrincipal[0].title || "imagen galeria"}
                        />
                        :
                        <img  className="o-gallery__container__cover__img"
                           loading="lazy"
                           src={ value.imagenPrincipal[0].file.url }
                           alt={ value.imagenPrincipal[0].title || "imagen galeria"}
                        />
                     }
                  </div>
               ) )
            }
         </LayoutSlider>
         { isModal &&
            <div className='o-gallery__modal'  >
               <div className='o-gallery__modal__background' onClick={hideModal} />
               <div className='o-gallery__modal__container' >
                  <div className='o-gallery__modal__container__top'>
                     <span className='o-gallery__modal__container__top__label' >{index+1}/{imgs.length}</span>
                     <button className='o-gallery__modal__container__top__button' onClick={hideModal} >Cerrar</button>
                  </div>
                  <div className='o-gallery__modal__container__cover' >
                     {content?.imagenPrincipal[0].fluid ?
                        <Img
                           className='o-gallery__modal__container__cover__img'
                           fluid={content?.imagenPrincipal[0].fluid} 
                           alt={content?.imagenPrincipal[0].title || "imagen galeria"}
                        />
                        :
                        <img
                           className='o-gallery__modal__container__cover__img'
                           loading="lazy"
                           src={OptimizeImage(content?.imagenPrincipal[0].file.url, content?.imagenPrincipal[0].file.contentType, 1000)}
                           alt={content?.imagenPrincipal[0].title || "imagen galeria"}
                        />
                     }
                     <button 
                        hidden={index === 0}
                        className='o-gallery__modal__container__cover__back' 
                        onClick={back} 
                     />
                     <button 
                        hidden={index >= imgs.length-1}
                        className='o-gallery__modal__container__cover__next' 
                        onClick={next}  
                     />
                  </div>
                  {content?.descripcion && <div className='o-gallery__modal__container__text' >{content?.descripcion.json.content && documentToReactComponents(content?.descripcion.json) }</div>}
               </div>
            </div>
         }
         {props.descargarContenido ?
            <div className="o-gallery__button">
               <div className="o-gallery__button__content">
                  <Button
                     id='buttonDescription'
                     type='link'
                     href={props.redireccionBoton ? props.redireccionBoton : '#'}
                     classname={'tertiary '}
                     text={props.tituloBoton}
                     isDisabled={false}
                     download={true}
                     onClickAction={() => handleDownload()}
                  />
               </div>
            </div>
            :
            null
         }
      </div>
   )
}

export default Gallery;
