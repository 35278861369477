import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import Breadcrumb from "../../../components/atoms/breadcrumb/breadcrumb";
import { BLOCKS } from "@contentful/rich-text-types";
import FormAbrazos from "../../../components/organisms/form-abrazos";
import formAbrazosAdapter from "../../../components/organisms/form-abrazos/adapters/form-abrazos.adapter";
import { INLINES } from "@contentful/rich-text-types";
import "./styles/style.scss";
import Button from "../../atoms/button/button";

const FormAbrazosQueEmocionan = () => {
   const data = useStaticQuery(graphql`
      query CONTACT_ABRAZOS_EMOCIONAN {
         allContentfulContactenos(
            filter: {
               contentful_id: { eq: "3ILR0qyKnvFWrTe4oFNkU7" }
            }
         ) {
            nodes {
               desactivarFormulario
               metaTitulo
               metaDescripcion {
                  json
               }
               tituloPrincipal
               descripcion {
                  json
               }
               tituloFormulario
               descripcionFormulario {
                  json
               }
               tituloUbicacion
               tituloBoton
               redireccionBoton
               referenciaUbicaciones {
                  titulo
                  tituloUbicacion
                  localizacion {
                     lat
                     lon
                  }
               }
               imagenFormulario {
                  title
                  file {
                     url
                  }
                  fluid(maxWidth: 960, quality: 75) {
                     src
                     srcSet
                     base64
                     aspectRatio
                     sizes
                  }
               }
               titulosFormulario {
                  tituloGeneral
                  tituloBoton
                  redireccionBoton
                  referenciaFormulario {
                     tipoCampo
                     deshabilitado
                     inputPrincipal
                     mensajeError
                     listaSelect
                     nombreId
                     obligatorio
                     tituloBotonTerminos
                     textoTerminos {
                        json
                     }
                  }
               }
            }
         }
         allContentfulPlantillaContenido(
            filter: {
               contentful_id: { eq: "5NuIhLCvnwwqiwghV0rjef" }
            }
         ) {
            nodes {
               redireccionBoton
               tituloBoton
               descripcion {
                  json
               }
            }
         }
      }
   `);

   const Text = (children: any, classname: string) => (
      <p className={classname}>{children}</p>
   );

   const options_subtitle = {
      renderNode: {
         [BLOCKS.PARAGRAPH]: (_node: any, children: any) =>
            Text(children, "o-contact__header-subtitle"),
      },
   };

   const renderHyperlink = (node: any) => {
      return (
         <a href={node.data.uri} target="_blank" rel="noreferrer">
            {node.content[0].value}
         </a>
      );
   };

   const options_message = {
      renderNode: {
         [INLINES.HYPERLINK]: renderHyperlink,
      },
   };

   const ConditionsAndTerms = () => {
      return (
         <div className="o-abrazos-form__terms">
            {documentToReactComponents(
               data.allContentfulContactenos.nodes[0].titulosFormulario[4]
                  .referenciaFormulario[0].textoTerminos.json,
               options_message
            )}
         </div>
      );
   };

   return (
      <>
         <div className="o-contact o-abrazos-form">
            <Breadcrumb
               content={`Inicio / ${data.allContentfulContactenos.nodes[0].tituloPrincipal}`}
            />

            <div className={`o-contact__header`}>
               <h1 className="o-contact__header-title title-primary">
                  {data.allContentfulContactenos.nodes[0].tituloPrincipal}
               </h1>
               {!data.allContentfulContactenos.nodes[0].desactivarFormulario &&
                  <>
                     {data.allContentfulContactenos.nodes[0].descripcion.json
                        .content &&
                        documentToReactComponents(
                           data.allContentfulContactenos.nodes[0].descripcion.json,
                           options_subtitle
                        )}
                     <p className="o-contact__header-message">
                        {data.allContentfulContactenos.nodes[0].tituloFormulario}
                        {data.allContentfulContactenos.nodes[0].descripcionFormulario
                           .json.content &&
                           documentToReactComponents(
                              data.allContentfulContactenos.nodes[0]
                                 .descripcionFormulario.json,
                              options_message
                           )}
                     </p>
                  </>
               }

            </div>

            <div className={`o-contact__cont`}>
               <div className="o-contact__cont-form o-form-abrazos-cont">
                  {data.allContentfulContactenos.nodes[0].desactivarFormulario ?
                     <div className="o-form-abrazos-cont__disabled">
                        {documentToReactComponents(
                           data.allContentfulPlantillaContenido.nodes[0].descripcion.json
                        )}
                        {data.allContentfulPlantillaContenido.nodes[0].redireccionBoton && 
                           <Button target="_self" type="link" text={data.allContentfulPlantillaContenido.nodes[0].tituloBoton || "Ver más"} isDisabled={false} classname="primary" href={data.allContentfulPlantillaContenido.nodes[0].redireccionBoton}></Button>
                        }
                     </div>
                     :
                     <FormAbrazos
                        conditionsComponent={ConditionsAndTerms}
                        fields={formAbrazosAdapter(
                           data.allContentfulContactenos.nodes[0].titulosFormulario
                        )}
                     />
                  }
               </div>
               <div
                  className={
                     "o-contact__cont-img hide-on-mobile"
                  }
               >
                  <img
                     loading="lazy"
                     src={
                        data.allContentfulContactenos.nodes[0].imagenFormulario
                           .file.url
                     }
                     alt="Imagen contacto"
                  />
               </div>
            </div>
         </div>

         <div style={{ padding: "150px 0" }}></div>
      </>
   );
};

export default FormAbrazosQueEmocionan;