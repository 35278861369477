import React, { useEffect, useRef, useState } from 'react';
import { Container, Fade, Grow, Typography } from '@material-ui/core';
import styles from './banner2.module.scss';

export interface IBanner2Props {
    title: string;
    description: any;
    btnLabel: string;
    link: string;
    bg: string;
    img?: string;
}

const Banner2 = ({
    title,
    description,
    btnLabel,
    link,
    bg,
    img,
}: IBanner2Props) => {
    const [isVisible, setIsVisible] = useState(false);
    const elemRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                setIsVisible(entries[0].isIntersecting);
            }
        });
        if (elemRef.current) {
            observer.observe(elemRef.current);
        }
        return () => observer.disconnect();
    }, []);

    return (
        <Fade in={isVisible} timeout={1500}>
            <Container
                component="section"
                className={styles.o_banner2}
                ref={elemRef}
            >
                <img src={bg} alt="" className={styles.o_banner2__bg} />
                {img && (
                    <img src={img} alt="" className={styles.o_banner2__extra} />
                )}
                <Grow in={isVisible} timeout={2000}>
                    <div className={styles.o_banner2__info} ref={elemRef}>
                        <Typography
                            className={styles.o_banner2__h2}
                            variant="h2"
                        >
                            {title}
                        </Typography>
                        <Typography
                            className={styles.o_banner2__body}
                            variant="body1"
                        >
                            {description}
                        </Typography>
                        {link && (
                            <Typography
                                className={styles.o_banner2__link}
                                variant="body2"
                                component="a"
                                href={link}
                            >
                                {btnLabel} <i className="icon-arrow-right" />
                            </Typography>
                        )}
                    </div>
                </Grow>
            </Container>
        </Fade>
    );
};

export default Banner2;
