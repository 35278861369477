import React, { useState } from "react"
import AffiliatedPrices from "../../molecules/affiliated-prices/affiliatedPrices"
import PriceExplanation from "../../molecules/price-explanation/priceExplanation"
import './priceInformation.scss'
import Button from "../../atoms/button/button"
import { ReferenciaContenido } from "../../../interfaces/ReferenciaContenido"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import ButtonAuth from '../../molecules/button-auth/buttonAuth'
import { normalizeTwoWords } from "../../../shared/utils/stringUtilities"


const PriceInformation = (props:ReferenciaContenido) => {

   const [isOpenModal, setOpenModal] = useState(false)
   const elementExepcion = props.referenciaPlantillaContenido.find( elem => !elem.tituloBoton || elem.tituloBoton.length === 0  );
   return (

      <div className='o-priceInformation__details-container'>
         <div className='o-priceInformation__details-mainSection'>
            <div className='o-priceInformation__details-affiliatedPrices'>
               <h2 className='title-secondary'>{props.tituloGeneral}</h2>
               {props.descripcion &&
                  <div className='o-priceInformation__details-priceDisplay show-on-mobile'>
                     <div className='o-priceInformation__details-priceDisplay-content' >{props.descripcion.json.content && documentToReactComponents(props.descripcion.json)}
                           <span>
                           <button onClick={() => { setOpenModal(true) }} className='o-priceInformation__details-infoIcon icon-info-circle1'></button>
                        </span>
                     </div>
                  </div>
               }
               {props.referenciaPlantillaContenido.map((elem, i) => (
                  <AffiliatedPrices 
                     key={`price${i}`}
                     tarifa={elem.tituloBanner}
                     ahorra={elem.tituloBoton}
                  />
               ))}
               { elementExepcion &&
                  <div className='o-priceInformation__details-calltoAction show-on-mobile'>
                     <p className='o-priceInformation__details-calltoActionTitle'>{elementExepcion.tituloDescripcion}</p>
                     {elementExepcion.descripcion &&
                        <div className='o-priceInformation__details-calltoActionText'>{elementExepcion.descripcion.json.content && documentToReactComponents(elementExepcion.descripcion.json)}</div>
                     }
                     <div className='o-priceInformation__details-button show-on-mobile'>
                        <Button
                           id='buttonVideoLink'
                           type='link'
                           classname={'secondary '}
                           text='QUIERO AFILIARME'
                           isDisabled={false}
                        />
                     </div>
                  </div>
               }
            </div>
            <div className={`o-priceInformation__details-priceExplanation ${isOpenModal ? 'o-priceInformation__details-priceExplanation--shown' : ''}`}>
               <button onClick={() => { setOpenModal(false) }} className='o-priceInformation__details-darkbg show-on-mobile'></button>
               <div className='o-priceInformation__details-cont'>
                  {props.descripcion && 
                     <div className='o-priceInformation__details-explanationHeader'>
                        <div className=' o-priceInformation__details-headertitle' >
                           {props.descripcion.json.content && documentToReactComponents(props.descripcion.json)}
                              <span>
                              <button onClick={() => { setOpenModal(false) }} className='icon-close show-on-mobile'></button>
                           </span>
                        </div>
                     </div>
                  }
                  {props.referenciaPlantillaContenido.map((elem, i) => {
                     return (
                        <PriceExplanation
                           key={`price${i}`}
                           tipoTarifa={elem.tipoTitulo}
                           explicaciones={(elem.descripcion && elem.descripcion.json.content) && documentToReactComponents(elem.descripcion.json)}
                           tituloExplicacion={elem.tituloDescripcion}
                        />
                     )
                  })}
                  <div className='o-priceInformation__details-button hide-on-mobile'>
                     <ButtonAuth
                        className='secondary '
                        validateAuth= { props.validarBotonAutenticacion }
                        text= { props.tituloBoton }
                        href= { props.redireccionBoton }
                        gtmMark= { `gtm${normalizeTwoWords(props.tituloBoton)}` }
                     />
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}




export default PriceInformation
