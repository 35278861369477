import { IColumnItemsProps } from "../columnItems";

function columnItemsAdapter(data: any): IColumnItemsProps {
	return ({
		title: data.tituloGeneral,
		items: data?.referenciaPlantillaContenido?.map(item => (
			{
				image: item.imagenPrincipal[0].file.url,
				title: item.tituloBanner,
				description: item.tituloDescripcion,
			}
		)) ?? [],
	})
}
  
export default columnItemsAdapter;