import React, { useState, useLayoutEffect, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import "./addressSap.scss";
import getAddressSap from "../../../services/fosfecForm/getDdlAddressSap";
import classNames from "classnames";
import Select from "../select/Select";
import Input from "../input/Input";

type AddressSapProps = React.ComponentProps<"div"> & {
    field: any;//an Field from an adapter
    register: any;//a Register from an react-hook-form
    setValue: any;//a setValue from an react-hook-form
    errors:any;//a errors from an react-hook-form
    actualValue:string//a actualValue from an react-hook-form or a main value of the field
};

type addresBaseFields = {
  via : string,
  number : string,
  letterOne : string,
  cardinalPointOne : string,
  numberTwo : string,
  letterTwo : string,
  cardinalPointTwo : string,
  inter : string,
  aditionalInfoAddress : string,
};

const AddressSap = (props: AddressSapProps) => {
    const [disabledFields, setDisabledFields] = useState(false);
    const [addressToShow, setAddressToShow] = useState<string>("");
    const {
        register, 
        trigger,
        getValues,
        formState, 
        setValue,
        watch
    } =  useForm<addresBaseFields>();
    const {  isValid, isDirty } = formState;
    const watchAllFields = watch();
    const classComp = "cfmui-address-base";
    const myClass = classNames({
        [classComp]: true,
    });
   
    const handleAddressStructure = () => {
        if(!watchAllFields.via) return '';
        const bodyOneAdress =  watchAllFields.via.length <= 3 && watchAllFields.number ? `${getValues("number")}${getValues("letterOne")}${getValues("cardinalPointOne")} ` : "";
        const bodyTwoAdress = getValues("numberTwo")
           ? `# ${getValues("inter")}${getValues("letterTwo")}${getValues("cardinalPointTwo")} - ${getValues("numberTwo")}`
           : "";
        const body =
        watchAllFields.via.length  <= 3
            ?
               bodyOneAdress +
                bodyTwoAdress +
                " " + 
                getValues("aditionalInfoAddress") : 
                getValues("aditionalInfoAddress");
        return  getValues("via") + " " +  body;
     };

     useLayoutEffect(() => {
        if(!(watchAllFields.via?.length > 3)) {
            setDisabledFields(false);
            return;
        }
        setValue("number","");
        setValue("letterOne","");
        setValue("cardinalPointOne","");
        setValue("numberTwo","");
        setValue("letterTwo","");
        setValue("cardinalPointTwo","");
        setValue("inter","");
        setDisabledFields(true)
     },[watchAllFields.via])

     
    useLayoutEffect(() => {
        const address = handleAddressStructure();
        setAddressToShow(address);
        if(isValid){ 
            props.setValue(props.field.name, address);
        } else {
            if(props.actualValue && !isDirty) return
            props.setValue(props.field.name, "");
        }	
    }, [watchAllFields])

    return (
        <form >
        <div className={myClass}>
            <label className={classComp + "__title"}>{props.field.label}</label>
        
            <div className={classComp + "__main"}>
                <div>
                <label>
                    Vía <span>*</span>
                </label>
                <Select {...register("via", { required: true })}>
                    {getAddressSap
                        .getVia()
                        .map((option: { label: string; value: string }) => (
                            <option key={option.label} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                </Select>
                </div>
                <div>
                <label>
                    Número{!disabledFields && <span>*</span>}
                </label>
                <Input type="number" {...register("number", { required: !disabledFields, disabled: disabledFields , pattern : {
                                    value: /^(?!-)[0-9]+$/,
                                    message: "Número no es válido",
                                    }})}/>
                </div>
                <div>
                <label>Letra</label>
                <Select {...register("letterOne", {disabled: disabledFields})}>
                    {getAddressSap
                        .getLetter()
                        .map((option: { label: string; value: string }) => (
                            <option key={option.label} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                </Select>
                </div>
                <div>
                <label>Punto car</label>
                <Select {...register("cardinalPointOne", { disabled: disabledFields })}>
                    {getAddressSap
                        .getCardinalPoint()
                        .map((option: { label: string; value: string }) => (
                            <option key={option.label} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                </Select>
                </div>
                <div>
                <label>
                    Inter{!disabledFields && <span>*</span>}
                </label>
                <Input type="number" {...register("inter", { required: !disabledFields , disabled: disabledFields, pattern : {
                                    value: /^(?!-)[0-9]+$/,
                                    message: "Inter no es válido",
                                    } })}/>
                </div>
                <div>
                <label>Letra</label>
                <Select {...register("letterTwo", {disabled: disabledFields})}>
                    {getAddressSap
                        .getLetter()
                        .map((option: { label: string; value: string }) => (
                            <option key={option.label} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                </Select>
                </div>
                <div>
                <label>Punto car</label>
                <Select {...register("cardinalPointTwo", {disabled: disabledFields})}>
                    {getAddressSap
                        .getCardinalPoint()
                        .map((option: { label: string; value: string }) => (
                            <option key={option.label} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                </Select>
                </div>
                <div>
                <label>
                    Número {!disabledFields && <span>*</span>}
                </label>
                <Input type="number" {...register("numberTwo", { required: !disabledFields, disabled: disabledFields, pattern : {
                                    value: /^(?!-)[0-9]+$/,
                                    message: "Número no es válido",
                                    }})}/>
                </div>
            </div>
            <div className={classComp + "__complementary"}>
                <label>Información adicional - Complemento {disabledFields && <span>*</span>}</label>
                <Input type="text" {...register("aditionalInfoAddress", { required: disabledFields})} />
            </div>
            <div className={classComp + "__result-value"}>
            <input  onFocus={() => {
             trigger(undefined, {shouldFocus:true})} } data-error={!props.actualValue ? "true": "false"} placeholder={addressToShow} onKeyDown={(e:any) => {e.preventDefault();return false;}} type="text" {...props.register(props.field.name, { ...props.field.validations})} />
            </div>
        </div>
        </form>
    );
};

export default React.memo(AddressSap);
