import React, { useState } from "react"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { normalizeTwoWords } from "../../../shared/utils/stringUtilities"
import { ReferenciaContenido } from '../../../interfaces/ReferenciaContenido'
import Button from '../../atoms/button/button';
import CardDownload from '../../atoms/card-download/cardDownload' 
import './downloadsList.scss'

const DownloadsList = (props: ReferenciaContenido) => {

	const { 
		tituloGeneral,
		referenciaPlantillaContenido,
		nombreCategoria
   } = props;
   
   const CARDS_ON_VIEW = 4;
   const [cardsLimit, setCardsLimit] = useState(CARDS_ON_VIEW)

	return (
		<div className='m-downloads-list'>
			<h2 className='m-downloads-list__title title-secondary'> { tituloGeneral } </h2>
			<div className='m-downloads-list__cont'>
				{ referenciaPlantillaContenido && referenciaPlantillaContenido.slice(0, cardsLimit).map((card, i)=>(		
					<CardDownload 
						key={ `card${i}` }
						fileTitle= { card.tituloBanner }
						fileSize= { (card.descripcion && card.descripcion.json.content) && documentToReactComponents(card.descripcion.json) }
						downloadLink = { card.redireccionBoton || '/' }
						gtmMark= { `gtm${normalizeTwoWords(nombreCategoria)}${normalizeTwoWords(tituloGeneral)}Descarga${i}` }
					/>	
				))}
			</div>

         {  referenciaPlantillaContenido && 
            referenciaPlantillaContenido.length > CARDS_ON_VIEW && 
            referenciaPlantillaContenido.length > cardsLimit &&
            <div className='m-downloads-list__btn'>
               <Button 
                  id='buttonSeeMoreDownloads'
                  type= {"button"} 
                  classname= {`primary`}
                  text= { "Ver más" }
                  onClickAction= { ()=>{ setCardsLimit(cardsLimit + CARDS_ON_VIEW) } } 
                  isDisabled= {false}
                  gtmMark= { `gtm${normalizeTwoWords(nombreCategoria)}${normalizeTwoWords(tituloGeneral)}VerMas` }
               />
            </div>
         }
		</div>
	)
}

export default DownloadsList
