import React from "react";
import Slider from "react-slick";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import Button from "../../atoms/button/button";
import { ReferenciaContenido } from "../../../interfaces/ReferenciaContenido";
import { normalizeTwoWords } from "../../../shared/utils/stringUtilities";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./carousel-rates.scss";

const CarouselRates = (props: ReferenciaContenido) => {
   const carouselData = props;
   const datasRates = props.referenciaPlantillaContenido;

   const setting = {
      dots: false,
      arrows: false,
      infinite: false,
      speed: 500,
      slidesToShow: 4.4,
      slidesToScroll: 1.2,
      initialSlide: 0,
      responsive: [
         {
            breakpoint: 1024,
            settings: {
               slidesToShow: 2,
               slidesToScroll: 1,
            },
         },
         {
            breakpoint: 960,
            settings: {
               slidesToShow: 2,
               slidesToScroll: 1,
               dots: true
            },
         },
         {
            breakpoint: 560,
            settings: {
               slidesToShow: 2,
               slidesToScroll: 1,
               dots: true
            },
         },
         {
            breakpoint: 440,
            settings: {
               slidesToShow: 1.6,
               slidesToScroll: 1,
               dots: true
            },
         },
         {
            breakpoint: 320,
            settings: {
               slidesToShow: 1.2,
               slidesToScroll: 1,
               dots: true
            },
         }
      ],
   };

   const List = (children: any) => {
      const row = children[0].props.children[0].split([": "]);

      return (
         <div className="m-carousel-rates__internal__info__row">
            <li className="m-carousel-rates__internal__info__row__label">{row[0]}</li>
            <li className="m-carousel-rates__internal__info__row__value">{row[1]}</li>
         </div>
      );
   };
   const options = {
      renderNode: {
         [BLOCKS.LIST_ITEM]: (_node: any, children: any) => List(children),
      },
   };

   return (
      <div className="m-carousel">
         <div className="m-carousel-rates">
            <h2 className="title-secondary">{carouselData.tituloGeneral}</h2>
            <div className="m-carousel-rates__transversal">
               <Slider {...setting}>
                  {datasRates.map((val, key) => {
                     return (
                        <div className={`m-carousel-rates__mini `} key={key}>
                           <div className="m-carousel-rates__internal">
                              <div className="m-carousel-rates__internal__title">
                                 <h3>{val.tituloBanner}</h3>
                              </div>
                              <div className="m-carousel-rates__internal__info">
                                {documentToReactComponents(
                                  val.descripcion.json,
                                  options
                                )}
                              </div>
                              <div className="m-carousel-rates__internal__button">
                                 <Button
                                    type="link"
                                    text={val.tituloBoton}
                                    classname="secondary m-carousel-rates__internal__button__btn"
                                    gtmMark={`gtmRedDeAmorTarifaAdquirirServicio${normalizeTwoWords(
                                       val.tituloBanner
                                    )}`}
                                    href={val.redireccionBoton}
                                    isDisabled={false}
                                    onClickAction={() => {}}
                                 />
                              </div>
                           </div>
                        </div>
                     );
                  })}
               </Slider>
            </div>
         </div>
      </div>
   );
};

export default CarouselRates;
