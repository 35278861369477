import React, { useState } from 'react'
import useBrowserMode from '../../../shared/hooks/useBrowserMode';
import './tabSelector.scss'

interface TabSelectorInterface {
    mainItem: string;
    tabs: any;
}

const TabSelector = (props: TabSelectorInterface) => {

    const [selected, setSelected] = useState(0)
    const { isBrowser, document } = useBrowserMode();

    if (isBrowser) {
        const observerTransX = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                entry.target.classList.add('tree-transX')
              } else {
                entry.target.classList.remove('tree-transX')
              }
            })
        })
        const transElem = document.querySelectorAll('.m-tab-selector__btns')
        transElem.forEach((elem) => observerTransX.observe(elem))
    }

    return (
        <div className="m-tab-selector">
            <div className="m-tab-selector__btns tree--hidden">
                {
                    props.tabs.map((tab, index) => (
                        <button className={`m-tab-selector__btns__btn ${selected === index ? 'm-tab-selector__btns__btn--selected' : ''}`} key={index} onClick={() => setSelected(index)}>
                            <img loading='lazy' src={tab.imagenLibro.file.url} alt={tab.nombreTab} />
                        </button>
                    ))
                }
            </div>
            <p className="m-tab-selector__info tree--hidden">{props.tabs[selected].redireccionTab}</p>
        </div>
    )
}

export default TabSelector
