import React from 'react'
import './filterButton.scss';

type FilterButtonProps = {
    active: any,
    name: string,
    onClick: any;
}

export default function FilterButton({ active, name, onClick }: FilterButtonProps) {

    return (
        <div onClick={onClick} className={active ? "a-filter__button --active" : "a-filter__button"}>
            <p>{name}</p>
        </div>
    )
}
