import React from "react";
import Map from "../../molecules/map-component/mapComponent";
import SedeInfo from "../../molecules/sede-info/sedeInfo";
import "./mapSedeInfo.scss";
import { ReferenciaContenido } from "../../../interfaces/ReferenciaContenido";

const MapSedeInfo = (props: ReferenciaContenido) => {

  const handleClick = async () => {
    window.location.href = `https://www.comfama.com/mapa-de-sedes/`;
  }

    if (!props.referenciaMapa[0]) {
      return (
        <div className="error">
          <h3>No fue encontrada esa sede</h3>
          <p className="btn-primary" onClick={handleClick}>
            Volver al buscador de sedes
          </p>
        </div>
      );
    }

    const {
      hours,
      services,
      spaces,
    } = props.referenciaMapa[0];

    return (
      <div className="o-sede">
        <div className="o-sede__container">
          <section className="o-sede__container__titles">
            <h2 className="o-sede__container__titles__main">Encuéntranos</h2>
          </section>
          <div className="o-sede__container__map">
            <Map
              sedes={[props.referenciaMapa[0]]}
              geolocationLat={0}
              geolocationLon={0}
              center={{
                lat: props.referenciaMapa[0].location.lat,
                lng: props.referenciaMapa[0].location.lon,
              }}
              zoom={17}
            />
          </div>
          <section className="o-sede__container__info" >
            <SedeInfo address={props.referenciaMapa[0].address ? props.referenciaMapa[0].address : props.referenciaMapa[0].mainAddress} phone={props.referenciaMapa[0].phone ? props.referenciaMapa[0].phone : props.referenciaMapa[0].mainPhone} hours={hours} services={services} spaces={spaces} />
          </section>
        </div>
      </div>
    );
}

export default MapSedeInfo;
