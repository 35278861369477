import React from "react"
import './discoverAppSection.scss'
import { ReferenciaContenido } from "../../../interfaces/ReferenciaContenido"
import { normalizeTwoWords } from "../../../shared/utils/stringUtilities"
import Button from '../../atoms/button/button';


const DiscoverAppSection = (props: ReferenciaContenido) => {
   const plantillaContenido = props.referenciaPlantillaContenido[0];

   return (
      <div className='m-discover-app-sec'>
         <div className='m-discover-app-sec__discover'>
            <div className='m-discover-app-sec__discover-title'>{plantillaContenido.tituloBanner}</div>
            <div className='m-discover-app-sec__discover-link'>
               <Button
                  id='buttonDiscoverApp'
                  type='link'
                  href={plantillaContenido.redireccionBoton}
                  classname={'secondary '}
                  text={plantillaContenido.tituloBoton}
                  isDisabled={false}
                  gtmMark= {`gtm${normalizeTwoWords(plantillaContenido.tituloBanner)}${normalizeTwoWords(plantillaContenido.tituloBanner)}Btn`}
               />
            </div>


            <div className='m-discover-app-sec__discover-imgcontainer'>
               {plantillaContenido.appStore && 
                  <div className='m-discover-app-sec__discover-imgcontainer-appstore'>
                     <a href={'appStore.link'} target='_blank' rel="nofollow noreferrer" >
                        <img src={(plantillaContenido.appStore && plantillaContenido.appStore.file) && plantillaContenido.appStore.file.url}
                           alt={plantillaContenido.appStore.title}
                           loading="lazy"
                           className='m-discover-app-sec__discover-imgcontainer-imgplay gtmSubcategoriaNuevaAppCulturaAppStore' ></img>
                     </a>
                  </div>
               }

               {plantillaContenido.playStore &&
                  <div className='m-discover-app-sec__discover-imgcontainer-playstore'>
                     <a href={'playStore.link'} target='_blank' rel="nofollow noreferrer" >
                        <img src={(plantillaContenido.appStore && plantillaContenido.appStore.file) && plantillaContenido.playStore.file.url}
                           alt={plantillaContenido.playStore.title}
                           loading="lazy"
                           className='m-discover-app-sec__discover-imgcontainer-imgapp gtmSubcategoriaNuevaAppCulturaAppStore'></img>
                     </a>
                  </div>
               }
            </div>



         </div>

         <div className='m-discover-app-sec_image'>
            <img className='onlydesktop'
               src={(plantillaContenido.imagenPrincipal) && plantillaContenido.imagenPrincipal[0].file.url}
               alt={plantillaContenido.imagenPrincipal[0].title}
               loading="lazy"
            />
            <img className='onlymobile'
               src={(plantillaContenido.imagenPrincipal) && plantillaContenido.imagenPrincipal[1].file.url}
               alt={plantillaContenido.imagenPrincipal[1].title}
               loading="lazy"
            />
         </div>
      </div>


   )
}
export default DiscoverAppSection
