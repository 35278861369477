import React from "react"
import SubsectionInfo from '../subsection-info/subsection-info'
import './subsectionContainer.scss'
import { ReferenciaContenido } from "../../../interfaces/ReferenciaContenido"



const SubsectionContainer = (props: ReferenciaContenido) => {
   
   return (

      <div className='m-subsectioncontainer__info-container-body'>
         {
            (props.referenciaPlantillaContenido[0].tituloBanner != "¿Cómo acceder?") ?
            (
               <h2 className='m-subsectioncontainer__info-container-titleContainer title-secondary'> 
                  {props.tituloGeneral} 
               </h2>
            ) : null
         }
         <div className='m-subsectioncontainer__info-container-featureContainer'>
            {
               props.referenciaPlantillaContenido.map((elem, i) => (props.referenciaPlantillaContenido[0].tituloBanner == "¿Cómo acceder?" && i == 0) ?
                  null
                  :
                  (<SubsectionInfo key={`elem${i}`}
                     title={elem.tituloBanner}
                     description={elem.descripcion.json}
                     image={elem.imagenPrincipal && elem.imagenPrincipal[0]}
                  />)
            )}
         </div>
      </div>

   )
}


export default SubsectionContainer
