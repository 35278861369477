import React from "react";
import './sedeInfo.scss';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';

type SedeInfoProps = {
    address: string;
    phone: any;
    hours: any;
    services: any;
    spaces: any;
}

export default function SedeInfo({address, phone, hours, services, spaces}: SedeInfoProps) {

  const Bold = (children:any) => (
    <h6 className="__text--bold">{children}</h6>
  );
 
  const Text = (children:any) => (
    <p className="__text">{children}</p>
  )

  const List = (children:any) => (
    <li className="__list">{children}</li>
  )

  const options = {
      renderMark: {
        [MARKS.BOLD]: (text:any) => Bold(text),
      },
      renderNode: {
        [BLOCKS.LIST_ITEM]: (_node:any, children:any) => List(children),
        [BLOCKS.PARAGRAPH]: (_node:any, children:any) => Text(children)
      },
  };

  return (
    <div className="m-sede__info">
      <div className="m-sede__info__address">
        <h4>Direccion:</h4>
        <p>{address}</p>
      </div>
      <div className="m-sede__info__phone">
        <h4>Telefono:</h4>
        <div className="m-sede__info__phone__phones">
          {phone ? documentToReactComponents(phone, options) : <a href={`tel:+57-034-${phone}`}>{phone}</a>}
        </div>
      </div>
      <div className="m-sede__info__hours">
        <h4>Horario:</h4>
        {hours && documentToReactComponents(hours, options)}
      </div>
      <div className="m-sede__info__spaces">
        <h4>Tipo de espacios</h4>
        <div className="m-sede__info__spaces__info">
          {spaces.map((item, index) => (
            <span key={index}>
              {index === spaces.length - 1 ? item : item + " / "}
            </span>
          ))}
        </div>
      </div>
      <div className="m-sede__info__services">
        <h4>Servicios</h4>
        <div className="m-sede__info__services__info">
          {services.map((item, index) => (
            <span key={index}>
              {index === services.length - 1 ? item : item + " / "}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
}
