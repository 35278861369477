import React from "react";
import concatCx from "../../../utils/concatCx";
import "./label.scss"

type LabelProps = React.ComponentProps<"label"> & {
  children: React.ReactNode;
};

const Label = React.forwardRef<HTMLLabelElement, LabelProps>(
  (props: LabelProps, ref) => {
    const { children, ...rest } = props;
    const compClass = "cfmui-label";
    let attributes = {
      ...rest,
      className: concatCx(compClass, props.className)
    };
    return (
      <label ref={ref} {...attributes}>
        {children}
      </label>
    );
  }
);

export default Label;
