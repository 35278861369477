import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { IWarningProps } from '../warning';

function warningAdapter(data: any): IWarningProps {
    return {
        text: documentToReactComponents(data.descripcion.json),
    };
}

export default warningAdapter;
