import React from 'react'
import "./columnItems.scss"

export interface IColumnItemsProps {
	title: string;
	items: [
		{
			image: string;
			title: string;
			description: React.ReactNode;
		}
	]
}

const ColumnItems = (props: IColumnItemsProps) => {
	
	const { title, items } = props;

	return (
	<div className='o-column-items'>
		<h2 className='o-column-items__title'>{title}</h2>
		<div className='o-column-items-container'>
			{
				items.map((item) => (
					<div className='o-column-items-container__card'>
						<img className='o-column-items-container__card__image' src={item.image} alt={item.title} />
						<div className='o-column-items-container__card__text'>
							<h3 className='o-column-items-container__card__text__title'>{item.title}</h3>
							{item.description && <span className='o-column-items-container__card__text__description'>{item.description}</span>}
						</div>
					</div>
				))
			}
		</div>
	</div>
  )
}

export default ColumnItems