import React from 'react'
import { ReferenciaContenido } from "../../../interfaces/ReferenciaContenido";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import "./simpleBanner.scss";

const SimpleBanner = (props: ReferenciaContenido) => {

    const Bold = (children:any) => (
        <p className="a-simple-banner__caption__description--bold">{children}</p>
    );
        
    const Text = (children:any) => (
        <p className="a-simple-banner__caption__description">{children}</p>
    )

    const List = (children:any) => (
        <li className="a-simple-banner__caption__description__list">{children}</li>
    )
        
    const options = {
        renderMark: {
            [MARKS.BOLD]: (text:any) => Bold(text)
        },
        renderNode: {
            [BLOCKS.LIST_ITEM]: (_node:any, children:any) => List(children),
            [BLOCKS.PARAGRAPH]: (_node:any, children:any) => Text(children)
        },
    };

    return (
        <div className='a-simple-banner'>
            <div className="a-simple-banner__cover" style={{ backgroundImage: `url(${props.imagenPrincipal.file.url})` }}></div>
            <div className="a-simple-banner__caption">
                <h2 className="a-simple-banner__caption__title">
                    {props.tituloGeneral}
                </h2>
                {props.descripcion.json.content && documentToReactComponents(props.descripcion.json, options)}
            </div>
        </div>
    )
}

export default SimpleBanner
