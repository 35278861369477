import React from "react"
import { normalizeTwoWords } from "../../../shared/utils/stringUtilities"
import { ReferenciaContenido } from '../../../interfaces/ReferenciaContenido';
import CardIcon from '../../atoms/card-icon/cardIcon'
import Slider from "react-slick"
import './cardColumns.scss'

const CardColumns = (props: ReferenciaContenido) => {

	const { 
		tituloGeneral,
		referenciaPlantillaContenido,
		nombreCategoria
	} = props;

	const sliderSettings = {
		arrows: false,
		dots: true,
		infinite: false,
		speed: 200,
		slidesToShow: 2,
		slidesToScroll: 1
	};

	const halfLength = Math.ceil(referenciaPlantillaContenido.length / 2); 
	const firstColumnCards = referenciaPlantillaContenido.splice(0, halfLength);
   const secondColumnCards = referenciaPlantillaContenido.splice(-halfLength);
   
   const getColumnOne = () => {
      return (
         <div className='m-card-cols__col'>
            { firstColumnCards && firstColumnCards.map((card, i)=>(
               <CardIcon
                  key = {`card${i}`}
                  iconSrc = {card.imagenPrincipal && card.imagenPrincipal[0].file.url}
                  iconAlt = {card.imagenPrincipal && card.imagenPrincipal[0].title }
                  text = { card.tituloBanner }
                  link = { card.redireccionBoton }
                  titleBtn = { card.tituloBoton }
                  gtmMark= { `gtm${normalizeTwoWords(nombreCategoria)}${normalizeTwoWords(tituloGeneral)}${normalizeTwoWords(card.tituloBanner)}Col1Item${i}` }
               />
            ))}
         </div>
      )
   }
   
   const getColumnTwo = () => {
      return (
         <div className='m-card-cols__col'>
            { secondColumnCards && secondColumnCards.map((card, i)=>(
               <CardIcon
                  key = {`card${i}`}
                  iconSrc = {card.imagenPrincipal &&  card.imagenPrincipal[0].file.url  }
                  iconAlt = {card.imagenPrincipal &&  card.imagenPrincipal[0].title }
                  text = { card.tituloBanner }
                  link = { card.redireccionBoton }
                  titleBtn = { card.tituloBoton }
                  gtmMark= { `gtm${normalizeTwoWords(nombreCategoria)}${normalizeTwoWords(tituloGeneral)}${normalizeTwoWords(card.tituloBanner)}Col2Item${i}` }
               />
            ))}
         </div>
      )
   }


	return (
		<div className='m-card-cols'>
			<h2 className='m-card-cols__title title-secondary'> 
				{ tituloGeneral } 
			</h2>
			<div className='m-card-cols__cont hide-on-mobile'>  
            { getColumnOne() }
            { getColumnTwo() }
			</div>
			<div className='show-on-mobile'>
				<Slider {...sliderSettings} className='m-card-cols__slider' >
               { getColumnOne() }
               { getColumnTwo() }
					<div></div>
				</Slider>
			</div>

		</div>
	)
}

export default CardColumns
