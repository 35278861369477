import React, { useEffect, useState } from 'react'
import useBrowserMode from '../../../shared/hooks/useBrowserMode';
import LayoutSlider from '../../templates/layout-slider/layoutSlider';
import { OptimizeImage } from "../../../shared/utils/stringUtilities";
import Modal from '../../atoms/modal/modal';
import './galleryCard.scss'

interface GalleryCardInterface {
    gallery: any;
    key: number;
}

const GalleryCard = (props: GalleryCardInterface) => {

    const { gallery, key } = props;
    const { isBrowser } = useBrowserMode();
    const [isModal, setIsModal] = useState(false);
    const [selected, setSelected] = useState(0);
    const [translateTrack, setTranslateTrack] = useState(0);

    const showModal = () => {
       if(isBrowser) {
          const querySelector = document.querySelector('body');
          querySelector?.classList.add('freeze-page')
       }
       setIsModal(true);
    }
 
    const hideModal = () => {
       if(isBrowser) {
          const querySelector = document.querySelector('body');
          querySelector?.classList.remove('freeze-page')
       }
       setIsModal(false);
    }

    if (isModal){
        setTimeout(() => {
            let galleryElem = document.querySelectorAll('.m-gallery-card__modal')[0]
            if ( galleryElem && ( document.body.clientWidth > 768 ) ) {
                let imgTrack = galleryElem.querySelectorAll<HTMLElement>('.slick-track')[0]
                imgTrack.style['transition'] = `0.5s`
                imgTrack.style['transform'] = `translate3d(${translateTrack}px, 0px, 0px)`
                if (imgTrack) {
                    let leftArrows = galleryElem.querySelectorAll('.slick-arrow.slick-prev')
                    Array.from(leftArrows).forEach(leftArrow => {
                        leftArrow.addEventListener('click', function() {
                            if (selected > 0) {
                                setSelected(selected - 1)
                            } else {
                                setSelected(gallery.imagenPrincipal.length - 1)
                            }
                        })
                    })
                    let rightArrows = galleryElem.querySelectorAll('.slick-arrow.slick-next')
                    Array.from(rightArrows).forEach(rightArrow => {
                        rightArrow.addEventListener('click', function() {
                            if (selected < gallery.imagenPrincipal.length - 1) {
                                setSelected(selected + 1)
                            } else {
                                setSelected(0)
                            }
                        })
                    })
                }
            }
        }, 500);
    }

    useEffect(() => {
        if (document.body.clientWidth > 768) {
            if ( selected === 0 || selected === 1 || selected === 2 ) {
                setTranslateTrack(0)
            } else if ( selected === gallery.imagenPrincipal.length - 1 || selected === gallery.imagenPrincipal.length - 2 || selected === gallery.imagenPrincipal.length - 3 ) {
                setTranslateTrack(-156 * (gallery.imagenPrincipal.length - 5) )
            } else {
                setTranslateTrack(-156 * (selected - 2) )
            }
        }
    }, [selected])

    return (
    <div key={key} className="m-gallery-card">
        <div className="m-gallery-card__cover" onClick={() => showModal()}>
            <div className="m-gallery-card__cover__shadow">
                <img src="https://images.ctfassets.net/gkhyeghj07ak/1m9v16G49CUTvSWaLaTuRf/930810ce390880ef42ab88e7cab44a63/images-regular__1_.svg" alt="galeria" />
                <span>Album <b>({gallery.imagenPrincipal.length} fotos)</b></span>
            </div>
            <img src={gallery.imagenPrincipal[0].file.url} alt={gallery.tituloBanner} />
        </div>
        <div className="m-gallery-card__title">
            <h3><b>{gallery.tituloBanner}</b></h3>
        </div>
        <Modal open={isModal} onClose={() => setIsModal(!isModal)} className='m-gallery-card__modal'>
            <div className='m-gallery-card__modal__container'>
                <div className="m-gallery-card__modal__container__upper">
                    <h3 className="m-gallery-card__modal__container__upper__title">
                        { gallery.imagenPrincipal[selected].title }
                    </h3>
                    <a className="m-gallery-card__modal__container__upper__download" download href={gallery.imagenPrincipal[selected].file.url} rel="noreferrer" target="_blank">
                        DESCARGAR <i className="icon-download"></i>
                    </a>
                    <i className="icon-close gallery" id="icon-close__modal" onClick={hideModal} />
                </div>
                <div className='m-gallery-card__modal__container__cover'>
                    <img  className="m-gallery-card__modal__container__cover__img"
                        loading="lazy"
                        src={OptimizeImage(gallery.imagenPrincipal[selected].file.url, gallery.imagenPrincipal[selected].file.contentType, 1000)}
                        alt={ gallery.imagenPrincipal[selected].title || "imagen galeria" }
                    />
                </div>
                <LayoutSlider widthRelative={true} customClass='m-gallery-card__modal__container__layout-slider'>
                    {
                        gallery.imagenPrincipal.map( (img, index) => (
                            < >
                                <img className={`m-gallery-card__modal__container__layout-slider__img ${selected === index ? "selected-gc" : ""}`} key={index}
                                    loading="lazy"
                                    src={OptimizeImage(img.file.url, img.file.contentType, 230)}
                                    alt={ img.title || "imagen galeria"}
                                    onClick={ () => setSelected(index) }
                                />
                            </>
                        ))
                    }
                </LayoutSlider>
            </div>
        </Modal>
    </div>
    )
}

export default GalleryCard
