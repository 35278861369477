import React, { useState, useEffect } from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import CardResult from "../../molecules/card-result/cardResult";
import SearchSlider from "../../molecules/search-slider/searchSlider";
import { ReferenciaContenido } from "../../../interfaces/ReferenciaContenido";
import { normalizeTwoWords } from "../../../shared/utils/stringUtilities";

import "./resultsCardList.scss";
import useBrowserMode from "../../../shared/hooks/useBrowserMode";

const ResultsCardList = (props: ReferenciaContenido) => {
   const { referenciaPlantillaContenido, nombreCategoria } = props;
   const [currentPage, setCurrentPage] = useState(0);
   const [cardsPerPage, setCardsPerPage] = useState(10);
   const { isBrowser, window } = useBrowserMode();


   useEffect(() => {
      if(isBrowser && window.screen.width <= 960) setCardsPerPage(4) 
   }, [])

   return (
      <div className="o-results-card-list__cont">
         <div className="o-results-card-list__cont-head">
            {referenciaPlantillaContenido
               .slice(
                  currentPage * cardsPerPage,
                  currentPage * cardsPerPage + cardsPerPage
               )
               .map((value, i) => (
                  <CardResult
                     key={`item${i}`}
                     infoType={value.tipoTitulo}
                     title={value.tituloBanner}
                     description={
                        (value.descripcion && value.descripcion.json.content) &&
                        documentToReactComponents(value.descripcion.json)
                     }
                     imageSrc={value.imagenPrincipal && value.imagenPrincipal[0].file.url}
                     imageType={value.imagenPrincipal && value.imagenPrincipal[0].file.contentType}
                     imageAlt={value.imagenPrincipal && value.imagenPrincipal[0].title}
                     gtmMark={`gtm${normalizeTwoWords(nombreCategoria)}${normalizeTwoWords(value.tituloBanner)}`}
                     linkTo={value.redireccionBoton}
                  />
               ))}
         </div>
         {referenciaPlantillaContenido.length > 0 && (
            <SearchSlider
               totalPages={Math.ceil(
                  referenciaPlantillaContenido.length / cardsPerPage
               )}
               goToPage={(currentPage) => setCurrentPage(currentPage)}
               goNext={() => setCurrentPage(currentPage + 1)}
               goBack={() => setCurrentPage(currentPage - 1)}
               paginator={currentPage}
            />
         )}
      </div>
   );
};

export default ResultsCardList;
