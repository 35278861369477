import React, { useState, useEffect, useMemo } from "react"
import { ReferenciaContenido } from "../../../interfaces/ReferenciaContenido";
import Description from "../../atoms/description/description";
import { ReferenciaPlantillaContenido } from '../../../interfaces/ReferenciaPlantillaContenido';
import { normalizeTwoWords } from "../../../shared/utils/stringUtilities";
import useBrowserMode from '../../../shared/hooks/useBrowserMode';
import YoutubePlayer from "../youtube-player/youtubePlayer";
import "./videosDownloader.scss"
import ReviewSlider from "../review-slider/reviewSlider";

const VideosDownloader = (props: ReferenciaContenido) => {

   const videos = props.referenciaPlantillaContenido;

   const { isBrowser, window } = useBrowserMode();
   const [content, setContent] = useState<ReferenciaPlantillaContenido[]>([]);
   const [pager, setPager] = useState<number>(0);
   const [limit, setLimit] = useState<number>(6);
   const isMoreSix = props.referenciaPlantillaContenido.length > limit;
   const [isModal, setIsModal] = useState(false);
   const [shownVideo, setShownVideo] = useState<ReferenciaPlantillaContenido| undefined>();

   const showModal = (index:number) => {
      if(isBrowser) {
         const querySelector = document.querySelector('body');
         querySelector?.classList.add('freeze-page')
      }
      setShownVideo(videos[index]);
      setIsModal(true);
   }

   const hideModal = () => {
      if(isBrowser) {
         const querySelector = document.querySelector('body');
         querySelector?.classList.remove('freeze-page')
      }
      setShownVideo(undefined);
      setIsModal(false);
   }
   
   useEffect( () => { 
      change()
      isBrowser && window.addEventListener('resize', change)
      return () => window.removeEventListener('resize', change)
   }, [] ) 

   useMemo( () => reset(), [limit] );

   function change() { 
      if(isBrowser) { 
         setLimit(3)
      } 
   } 

   function reset() { 
      setContent( isMoreSix ? props.referenciaPlantillaContenido.slice(0, limit) : props.referenciaPlantillaContenido )
      setPager(limit)
   }
   
   const getMore = () => { 
      setContent(content.concat(props.referenciaPlantillaContenido.slice(pager, pager+limit)))
      setPager(pager+limit)
   } 

   return (
      <div className="m-video-downloader"> 
         <h2 className="m-video-downloader__upper__title">{props.tituloGeneral}</h2> 
         { props.descripcion && 
            <div className="m-video-downloader__upper__description"> 
               <Description description={JSON.stringify(props.descripcion.json)} />
            </div> 
         } 
         <div className="m-video-downloader__list hide-on-mobile">
            {content.map((video, key) => (
               <div key={key} className="m-video-downloader__list__item">
                  <div className="m-video-downloader__list__item__video">
                     <div className="m-video-downloader__list__item__video__shadow" onClick={() => showModal(key)}></div>
                     <YoutubePlayer
                        youtubeId={video.youtubeId}
                        titulo={video.tituloBanner || "Video Comfama"}
                     />
                  </div>
                  <div className="m-video-downloader__list__item__title">
                     <h3><b>{video.tituloBanner}</b></h3>
                  </div>
               </div>
            ))}
         </div>
         { isModal &&
            <div className='m-video-downloader__modal'  >
               <div className='m-video-downloader__modal__background' onClick={hideModal} />
               <div className='m-video-downloader__modal__container' >
                  <i className="icon-close" id="icon-close__modal" onClick={hideModal} />
                  {shownVideo?.redireccionBoton &&
                     <div className="m-video-downloader__modal__container__download">
                        <a href={shownVideo.redireccionBoton} target="_blank" rel="noreferrer">
                           DESCARGAR <i className="icon-download"></i>
                        </a>
                     </div>
                  }
                  <div className='m-video-downloader__modal__container__cover' >
                     {shownVideo &&
                        <YoutubePlayer
                           youtubeId={shownVideo.youtubeId}
                           titulo={shownVideo.tituloBanner || "Video Comfama"}
                        />
                     }
                  </div>
               </div>
            </div>
         }
         { props.referenciaPlantillaContenido.length > pager &&
            <button className='m-video-downloader__button hide-on-mobile' 
               onClick={getMore}
            >Ver más</button>
         }
         <div className="o-multi-gallery__slider show-on-mobile">
            <ReviewSlider 
               reviews={content}
               type="downloadVideo"
               gtmClass={`${normalizeTwoWords(props.nombreCategoria)}${normalizeTwoWords(props.tituloGeneral)}`}
            />
        </div>
      </div>
   )
}

export default VideosDownloader