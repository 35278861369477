import React, { useState } from 'react'
import { ReferenciaContenido } from "../../../interfaces/ReferenciaContenido";
import Button from '../../atoms/button/button';
import Description from '../../atoms/description/description';
import Modal from '../../atoms/modal/modal';
import RichtextDescription from '../../atoms/richtext-description/richtextDescription';
import "./infographic.scss";

const Infographic = (props: ReferenciaContenido) => {

    const [popup, setPopup] = useState(false)
    const [screen, setScreen] = useState(true)
    const [openMenu, setOpenMenu] = useState(false)
    const [move, setMove] = useState("Adelante")
    const [selected, setSelected]: any = useState()

    const handleSelected = (card) => {
        setSelected(card)
        setPopup(true)
    }
    
    const handleOut = () => {
        document.getElementsByClassName("a-modal")[0].classList.add("--opacity")
        setTimeout(function(){ setPopup(false); }, 700);
    }

    const handleMenuOut = () => {
        document.getElementsByClassName("o-infographic__container__menu-options")[0].classList.add("--opacity")
        setTimeout(function(){ setOpenMenu(false); }, 700);
    }

    const handleMove = () => {
        if (move === "Adelante") {
            setMove("Atrás")
            document.getElementsByClassName("o-infographic__container__cover")[0].scrollTo({
                left: 1000,
                behavior: 'smooth'
            });
        } else {
            setMove("Adelante")
            document.getElementsByClassName("o-infographic__container__cover")[0].scrollTo({
                left: 0,
                behavior: 'smooth'
            });
        }
    }
    
    return (
        <div className="o-infographic">
            <div className="o-infographic__container">
                <h2 className="o-infographic__container__title">{props.tituloGeneral}</h2>
                {props.descripcion &&
                    <div className="o-infographic__container__description">
                        <Description description={JSON.stringify(props.descripcion.json)} />
                    </div>
                }
                { !screen &&
                    <>
                        <div className="o-infographic__container__menu" onClick={() => setOpenMenu(true)}>
                            <img className="o-infographic__container__menu__icon"
                                src="https://images.ctfassets.net/gkhyeghj07ak/7cyYEe7KkUwxy0C929cBtH/1e49cf21fd5f5ca678f1ad2db432f5d0/menu.svg"
                                alt="" />
                            <span className="o-infographic__container__menu__caption">Menú</span>
                        </div>
                        <div className="o-infographic__container__move" onClick={() => handleMove()}>
                            <div className={`o-infographic__container__move__icons --${move}`}>
                                <span className="o-infographic__container__move__icons__num">{move === "Adelante" ? 1 : 2}</span>
                                <i className={`${move === "Adelante" ? "icon-chevron-right1" : "icon-chevron-left1"} o-infographic__container__move__icons__icon`} />
                            </div>
                            <span className="o-infographic__container__move__caption">{move}</span>
                        </div>
                    </>
                }
                { openMenu &&
                    <div className="o-infographic__container__menu-options">
                        <i className="icon-close" id="icon-close__menu" onClick={() => handleMenuOut()} />
                        {
                            props.referenciaPlantillaContenido.map((card, index) => (
                                <div key={index} onClick={() => handleSelected(card)}>
                                    <div className={`o-infographic__container__menu-options__option --option${index}`}>
                                        <i className="icon-chevron-right1" />
                                        <span className="o-infographic__container__menu-options__option__caption">
                                            {card.tituloDescripcion}
                                        </span>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                }
                <div className={`o-infographic__container__cover ${screen ? "--noScroll" : ""}`}>
                    { screen &&
                        <div className="o-infographic__container__cover__screen" onClick={() => setScreen(false)} >
                            <img className="o-infographic__container__cover__screen__img"
                                src="https://images.ctfassets.net/gkhyeghj07ak/5onLuNBkwBCc0JZv4gncAP/11d9d1c867d9cfa5a64e848ae4160426/cursos.svg"
                                alt="" />
                            <span className="o-infographic__container__cover__screen__caption">{props.tituloBoton}</span>
                        </div>
                    }
                    <img className="o-infographic__container__cover__img" src={props.imagenPrincipal.file.url} alt="" />
                    {
                        props.referenciaPlantillaContenido.map((card, index) => (
                            <div key={index} onClick={() => handleSelected(card)}>
                                <div className={`pulse pulse__${index}`}>
                                    +
                                    <span className='pulse__title'>{card.tituloDescripcion}</span>
                                </div>
                                <div className={`ringbase ring1 ringbase__${index}`}></div>
                                <div className={`ringbase ring2 ringbase__${index}`}></div>
                            </div>
                        ))
                    }
                </div>
                <Modal open={popup} onClose={() => handleOut()} className="o-infographic__container__modal">
                    <div className="o-infographic__container__modal__body">
                        <i className="icon-close" id="icon-close__modal__ig" onClick={() => handleOut()} />
                        {selected &&
                            <div className="o-infographic__container__modal__body__img">
                                <img src={selected.imagenPrincipal[0].file.url} alt="" />
                            </div>
                        }
                        {selected &&
                            <div className="o-infographic__container__modal__body__text">
                                <h3 className="o-infographic__container__modal__body__text__title">{selected.tituloDescripcion}</h3>
                                <div className="o-infographic__container__modal__body__text__description">
                                    <RichtextDescription descripcion={selected.descripcion} />
                                    {
                                        selected.tituloBoton && 
                                        <div className="o-infographic__container__modal__body__text__description__button">
                                            <Button
                                                id='buttonDescription'
                                                type='link'
                                                href={selected.redireccionBoton ? selected.redireccionBoton : '#'}
                                                classname={'tertiary '}
                                                text={selected.tituloBoton}
                                                isDisabled={false}
                                                />
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </Modal>
            </div>
        </div>
    )
}

export default Infographic
