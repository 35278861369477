import React, { useState } from 'react'
import { ReferenciaContenido } from "../../../interfaces/ReferenciaContenido";
import Button from '../../atoms/button/button';
import RichtextDescription from '../../atoms/richtext-description/richtextDescription';
import './descriptionInforme.scss'

const DescriptionInforme = (props: ReferenciaContenido) => {

  const [seeMore, setSeeMore] = useState('Leer más')

  return (
    <div className="o-description-informe">
      <div className="o-description-informe__body">
        {props.imagenPrincipal &&
            <div className="o-description-informe__body__img">
                <img src={props.imagenPrincipal.file.url} alt="" />
            </div>
        }
        <div className="o-description-informe__body__text">
            <h3 className="o-description-informe__body__text__title">{props.tituloGeneral}</h3>
            <div className={`o-description-informe__body__text__description ${seeMore === 'Leer menos' ? 'see-more--open' : ''}`}>
                <RichtextDescription descripcion={props.descripcion} />
                {
                    props.tituloBoton && 
                    <div className="o-description-informe__body__text__description__button">
                        <Button
                            id='buttonDescription'
                            type='link'
                            href={props.redireccionBoton ? props.redireccionBoton : '#'}
                            classname={'tertiary '}
                            text={props.tituloBoton}
                            isDisabled={false}
                            />
                    </div>
                }
            </div>
            <div className="o-description-informe__body__text__see-more" onClick={() => setSeeMore(seeMore === 'Leer más' ? 'Leer menos' : 'Leer más')}>
              <span>{seeMore}</span>
            </div>
        </div>
      </div>
  </div>
  )
}

export default DescriptionInforme
