import React, { useState, useRef, useEffect } from 'react';
import ChapterParallax from "../chapter-parallax/chapterParallax";
import useBrowserMode from '../../../shared/hooks/useBrowserMode';
import { ReferenciaContenido } from "../../../interfaces/ReferenciaContenido";
import useDirectionScroll from '../../../shared/hooks/useDirectionScroll';
import { normalizeTwoWords } from "../../../shared/utils/stringUtilities";
import "./scrollParallax.scss"

const ScrollParallax = (props: ReferenciaContenido) => {

	const [indexTag, setIndexTag] = useState(0);
	const { isBrowser, window, document } = useBrowserMode();
	const [isScrollActive, setIsScrollActive] = useState<boolean>(false);
	const [upScroll, setUpScroll] = useState(false);
	const [tagHover, setTagHover] = useState(false);
	const refComponent = useRef(null);
	const { lastDirection } = useDirectionScroll();
	const gtmTagsDinamic = `gtm${normalizeTwoWords(props.nombreCategoria)}${normalizeTwoWords(props.tituloGeneral)}`;
	const tags = props.referenciaPlantillaContenido.filter(
		value => value.tituloBoton
	).map( value => ({ tag: value.tituloBoton, title: value.tituloBoton }) );
	
	let timerScroll:any;

	const onScrollAction = () => {
		window.clearTimeout( timerScroll );
		timerScroll = setTimeout(() => {
			if( upScroll ) {
				window.scroll(0, window.pageYOffset - 180 );
				setUpScroll(false);
			}
			const el: HTMLElement = refComponent.current || new HTMLElement();
			const boundElement = el.querySelector('.o-scroll-parallax__zone')?.getBoundingClientRect();
			let tagSelect = 0;
			const items = el.querySelectorAll('.o-scroll-parallax__content__item--principal');
			for( let i = 0; i < items.length; i++ ) {
				if ( items.item(i).getBoundingClientRect().top <= 190 ) {
					tagSelect = i;
				} else {
					setIndexTag(tagSelect);
					setIsScrollActive( (boundElement || {top}).top < 160 );
					return;
				}
			}
			setIndexTag(tagSelect);
			setIsScrollActive( (boundElement || {top}).top < 160 );
		}, 66);
	}

	useEffect(() => {
		isBrowser && document.addEventListener('scroll', onScrollAction);
		return () => {
			isBrowser && document.removeEventListener('scroll', onScrollAction);
		}
	}, [upScroll] );

	const goToChapter = (value:string) => {
		const el: HTMLElement = refComponent.current || new HTMLElement();
		const tag = el.querySelector(`.D-${value}`);
		setUpScroll(true);
		tag?.scrollIntoView();
	}

	const [visibility, setVisibility] = useState("");
	const toggleVisibility = () => {
		if (window.pageYOffset > document.body.offsetHeight - (document.body.offsetHeight * 0.078)) {
			setVisibility("no-visible");
		} else {
			setVisibility("");
		}
	}
  
	document.addEventListener('scroll', toggleVisibility);
  
	useEffect(() => {
		toggleVisibility();
	}, [window.pageYOffset])

	return (
		<div className='o-scroll-parallax' ref={refComponent}>
			<div className='o-scroll-parallax__background'>
				<div className='o-scroll-parallax__background__solid'></div>
				<div className='o-scroll-parallax__background__img'>
					<img loading="lazy" src={props.imagenPrincipal.file.url} />
				</div>
			</div>
			<div className='o-scroll-parallax__zone' >
				<ul 
					className={`o-scroll-parallax__zone__tags${isScrollActive ?' o-scroll-parallax__zone__tags--freezer' : ''} --${visibility}`}
					onMouseOver={() => setTagHover(true)}
					onMouseOut={() => setTagHover(false)}
				>
					{tags.map( (tag, key) => (
						<li 
							key={key} 
							className={`${gtmTagsDinamic}${normalizeTwoWords(tag.tag)} o-scroll-parallax__zone__tags__tag${key === indexTag ? ' o-scroll-parallax__zone__tags__tag--active' : ''}`} 
							onClick={() => goToChapter(normalizeTwoWords(tag.tag)) }

						>{tagHover ? tag.tag : ""}</li>
					))}
				</ul>
				<div
                  className={`o-scroll-parallax__zone__box${
                     isScrollActive
                        ? " o-scroll-parallax__zone__box--freezer"
                        : ""
                  }${
                     lastDirection == 1
                        ? " o-scroll-parallax__zone__box--move"
                        : ""
                  }`}
               >
                  <button
                     disabled={indexTag <= 0}
                     className="o-scroll-parallax__zone__box__circle"
                     onClick={() => goToChapter(normalizeTwoWords(tags[indexTag - 1].tag).replace(/\s+/g, ''))}
                  />
                  {tags.map((tag, key) => (
                     <span
                        key={key}
                        className={`o-scroll-parallax__zone__box__item${
                           key === indexTag
                              ? " o-scroll-parallax__zone__box__item--active"
                              : ""
                        }`}
                     >
                        {tag.tag}
                     </span>
                  ))}
                  <button
                     disabled={indexTag + 1 >= tags.length}
                     className="o-scroll-parallax__zone__box__circle"
                     onClick={() => goToChapter(normalizeTwoWords(tags[indexTag + 1].tag).replace(/\s+/g, ''))}
                  />
               </div>
			</div>
			<div className='o-scroll-parallax__content'>
				{
					props.referenciaPlantillaContenido.map((chapter, i) => (
						<div 
							key={i}
							className={`o-scroll-parallax__content__item${chapter.tituloBoton ? ` o-scroll-parallax__content__item--principal D-${normalizeTwoWords(chapter.tituloBoton)}`: ''}`}  	
						>
							<ChapterParallax chapter={chapter}/>
						</div>
					))
				}
			</div>
		</div>
)}

export default ScrollParallax
