import React, { useEffect, useState } from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { ReferenciaContenido } from "../../../interfaces/ReferenciaContenido"
import Modal from '../../atoms/modal/modal'
import SubsectionInfo from '../../molecules/subsection-info/subsection-info'
import './journey.scss'

const InicioJourney = (props: ReferenciaContenido) => {

    const [popup, setPopup] = useState(false)
    const [stage, setStage] = useState(0)
    const [showMatrix, setShowMatrix] = useState(false)
    const [matrixBtn, setMatrixBtn] = useState(0)
    const [key, setKey] = useState(0)

    const handleStageChange = (n, key?) => {
        if (n === undefined || n === null || n === false || n === 'matrix') {
            const elem = document.querySelectorAll('.o-inicio-journey__up__desc')[0]
            elem?.scrollIntoView(true)
            setShowMatrix(true)
            setKey(key)
        } else if (n.startsWith('http')) {
            window.open(n, '_blank')
        } else if (parseInt(n) === 0) {
            scrollTo(0, 0)
            setShowMatrix(false)
            setStage(parseInt(n))
            setMatrixBtn(0)
        } else {
            const elem = document.querySelectorAll('.o-inicio-journey__up__desc')[0]
            elem?.scrollIntoView(true)
            setShowMatrix(false)
            setStage(parseInt(n))
        }
    }

    useEffect(() => {
        if (stage === 0 ) {
            scrollTo(0, 0)
        } else {
            const elem = document.querySelectorAll('.o-inicio-journey__up__desc')[0]
            elem?.scrollIntoView(true)
        }
    }, [stage])

    const handleGoBack = () => {
        if (showMatrix) {
            setShowMatrix(false)
        } else {
            setStage(stage - 1)
        }
        setMatrixBtn(0)
    }

    const returnStageButtons = (stage, card, key) => {
        return  <>
            { stage === 0 &&
                <button className={`o-inicio-journey__down__cards__card ${stage !== 0 ? '--hide-j' : ''}`} key={key} onClick={() => handleStageChange(card.redireccionTab)}>
                    { card.imagenLibro &&
                        <img src={card.imagenLibro.file.url} alt={card.imagenLibro.file.title} />
                    }
                    <p>{card.nombreTab}</p>
                    <i className="icon-check-circle1" />
                </button>
            }
            { ( card.imagenLibro && stage !== 0 ) &&
                <button className={`o-inicio-journey__down__cards__btn ${stage === 0 ? '--hide-j' : ''}`} key={key} onClick={() => handleStageChange(card.redireccionTab, key)}>
                    <img src={card.imagenLibro.file.url} alt={card.imagenLibro.file.title} />
                    <p>{card.nombreTab}</p>
                </button>
            }
            { ( !card.imagenLibro && stage !== 0 ) &&
                <button className={`o-inicio-journey__down__cards__btn2 ${stage === 0 ? '--hide-j' : ''}`} key={key} onClick={() => handleStageChange(card.redireccionTab, key)}>
                    <p>{card.nombreTab}</p>
                </button>
            }
        </>
    }

    const renderDesc = (stage) => {
        return <>
            <span className='o-inicio-journey__down__matrix__title'>{ props.referenciaPlantillaContenido[stage].referenciaContenido[key].tituloBanner }</span>
            <span className='o-inicio-journey__down__matrix__subtitle'>{ props.referenciaPlantillaContenido[stage].referenciaContenido[key].tituloDescripcion }</span>
            <p className='o-inicio-journey__down__matrix__desc'>
                { props.referenciaPlantillaContenido[stage].referenciaContenido[key].descripcion && documentToReactComponents(props.referenciaPlantillaContenido[stage].referenciaContenido[key].descripcion.json) }
            </p>
        </>
    }

    const handleMatrix = (key, move) => {
        if (move === 'prev') {
            if (key - 1 >= 0) {
                setMatrixBtn(key - 1)
            }
        } else {
            if (key + 1 <= 3) {
                setMatrixBtn(key + 1)
            }
        }
    }

    return (
        <div className='o-inicio-journey'>
            <div className='o-inicio-journey__up'>
                <img className='o-inicio-journey__up__img' src={props.imagenPrincipal.file.url} alt="icon" />
                <span>
                    {props.tituloBoton}<br/>
                    <b>{props.redireccionBoton}</b>
                </span>
                <p className='o-inicio-journey__up__desc'>
                    { props.descripcion.json.content && documentToReactComponents(props.descripcion.json) }
                </p>
            </div>
            <div className='o-inicio-journey__middle'>
                <img src={props.referenciaPlantillaContenido[!showMatrix ? stage : 2].imagenPrincipal[0].file.url} alt="icon" />
                { stage > 3 &&
                    <div className='o-inicio-journey__down__matrix__canva__btn-sup'>
                        <span className='o-inicio-journey__down__matrix__canva__btn-sup__up'>
                            { props.referenciaPlantillaContenido[stage].youtubeId }
                        </span>
                    </div>
                }
                { ( stage > 0 && stage < 4) &&
                    <div className="o-inicio-journey__middle__opt">
                        <button className="o-inicio-journey__middle__opt__back" onClick={() => handleGoBack()}>VOLVER</button>
                        <button className='o-inicio-journey__middle__btn' onClick={() => setPopup(true)}>{props.referenciaPlantillaContenido[0].tituloBoton}</button>
                    </div>
                }
                { ( props.referenciaPlantillaContenido[stage].tituloBanner && !showMatrix) &&
                    <span>{ props.referenciaPlantillaContenido[stage].tituloBanner }</span>
                }
                { ( props.referenciaPlantillaContenido[stage].descripcion && !showMatrix ) &&
                    <p className='o-inicio-journey__middle__desc'>{ documentToReactComponents(props.referenciaPlantillaContenido[stage].descripcion.json) }</p>
                }
                { stage > 3 &&
                    <img src={props.referenciaPlantillaContenido[stage].imagenPrincipal[1].file.url} alt="icon" />
                }
                { stage === 0 &&
                    <>
                        <button className='o-inicio-journey__middle__btn' onClick={() => setPopup(true)}>{props.referenciaPlantillaContenido[0].tituloBoton}</button>
                        <div className='o-inicio-journey__middle__line'>.</div>
                    </>
                }
            </div>
            <div className='o-inicio-journey__down'>
                {
                    ( props.referenciaPlantillaContenido[stage].tituloDescripcion && !showMatrix ) &&
                    <span className='o-inicio-journey__down__title'>{props.referenciaPlantillaContenido[stage].tituloDescripcion}</span>
                }
                { stage > 3 &&
                    <>
                        <span>{ props.referenciaPlantillaContenido[stage].tipoTitulo }</span>
                        <a href={ props.referenciaPlantillaContenido[stage].redireccionBoton } rel="noreferrer" target='_blank'>{ props.referenciaPlantillaContenido[stage].tituloBoton }</a>
                    </>
                }
                {
                    ( stage === 1 && !showMatrix ) &&
                    <div className='o-inicio-journey__down__line'>.</div>
                }
                {
                    showMatrix &&
                    renderDesc(stage)
                }
                {
                    ( props.referenciaPlantillaContenido[stage].redireccionTabs && !showMatrix ) &&
                    <div className='o-inicio-journey__down__cards'>
                        { props.referenciaPlantillaContenido[stage].redireccionTabs.map((card, key) => (
                            returnStageButtons(stage, card, key)
                            ))}
                    </div>
                }
                {   showMatrix &&
                    <div className='o-inicio-journey__down__matrix'>
                        <div className='o-inicio-journey__down__matrix__btns'>
                            { props.referenciaPlantillaContenido[props.referenciaPlantillaContenido.length - 2].referenciaContenido.map((btn, key) => (
                                <button className={`o-inicio-journey__down__matrix__btns__btn ${matrixBtn === key ? '--active-j' : ''} ${( key === 1 && matrixBtn === key && stage > 2 ) ? '--active2-j' : ''}`} key={key} onClick={() => setMatrixBtn(key)}>
                                    <span>{btn.youtubeId}</span>
                                </button>
                            ))}
                        </div>
                        <div className='o-inicio-journey__down__matrix__canva'>
                            { ((props.referenciaPlantillaContenido[props.referenciaPlantillaContenido.length - 2].referenciaContenido[matrixBtn].tituloDescripcion || props.referenciaPlantillaContenido[props.referenciaPlantillaContenido.length - 2].referenciaContenido[matrixBtn].tipoTitulo) && stage <= 2 ) &&
                                <div className='o-inicio-journey__down__matrix__canva__btn-sup'>
                                    { props.referenciaPlantillaContenido[props.referenciaPlantillaContenido.length - 2].referenciaContenido[matrixBtn].tituloDescripcion &&
                                        <span className='o-inicio-journey__down__matrix__canva__btn-sup__up'>
                                            { props.referenciaPlantillaContenido[props.referenciaPlantillaContenido.length - 2].referenciaContenido[matrixBtn].tituloDescripcion }
                                        </span>
                                    }
                                    { props.referenciaPlantillaContenido[props.referenciaPlantillaContenido.length - 2].referenciaContenido[matrixBtn].tipoTitulo &&
                                        <span className='o-inicio-journey__down__matrix__canva__btn-sup__middle'>
                                            { props.referenciaPlantillaContenido[props.referenciaPlantillaContenido.length - 2].referenciaContenido[matrixBtn].tipoTitulo }
                                        </span>
                                    }
                                </div>
                            }
                            { ( props.referenciaPlantillaContenido[props.referenciaPlantillaContenido.length - 2].referenciaContenido[matrixBtn].redireccionBoton && stage > 2 ) &&
                                <div className='o-inicio-journey__down__matrix__canva__btn-sup--disabled'>
                                    { props.referenciaPlantillaContenido[props.referenciaPlantillaContenido.length - 2].referenciaContenido[matrixBtn].tipoTitulo &&
                                        <span>
                                            { props.referenciaPlantillaContenido[props.referenciaPlantillaContenido.length - 2].referenciaContenido[matrixBtn].redireccionBoton }
                                        </span>
                                    }
                                </div>
                            }
                            { props.referenciaPlantillaContenido[props.referenciaPlantillaContenido.length - 2].referenciaContenido[matrixBtn].tituloBanner &&
                                <span className='o-inicio-journey__down__matrix__canva__subtitle'>
                                    { props.referenciaPlantillaContenido[props.referenciaPlantillaContenido.length - 2].referenciaContenido[matrixBtn].tituloBanner }
                                </span>
                            }
                            { props.referenciaPlantillaContenido[props.referenciaPlantillaContenido.length - 2].referenciaContenido[matrixBtn].redireccionTabs &&
                                <div className='o-inicio-journey__down__matrix__canva__cards'>
                                    { props.referenciaPlantillaContenido[props.referenciaPlantillaContenido.length - 2].referenciaContenido[matrixBtn].redireccionTabs.map((card, key) => (
                                        <button key={key} className={`o-inicio-journey__down__matrix__canva__cards__card ${( stage > 2 && matrixBtn === 1 ) ? '--disabled-card-j' : ''}`} onClick={() => handleStageChange(card.redireccionTab)}>
                                            <img src={card.imagenLibro.file.url} alt={card.nombreTab}></img>
                                        </button>
                                    ))}
                                </div>
                            }
                            { props.referenciaPlantillaContenido[props.referenciaPlantillaContenido.length - 2].referenciaContenido[matrixBtn].descripcion.json.content &&
                                <p className='o-inicio-journey__down__matrix__canva__desc'>
                                    { documentToReactComponents(props.referenciaPlantillaContenido[props.referenciaPlantillaContenido.length - 2].referenciaContenido[matrixBtn].descripcion.json) }
                                </p>
                            }                            
                            { props.referenciaPlantillaContenido[props.referenciaPlantillaContenido.length - 2].referenciaContenido[matrixBtn].tituloBoton &&
                                <div className='o-inicio-journey__down__matrix__canva__btn-inf'>
                                    <button className='o-inicio-journey__middle__btn' onClick={() => handleStageChange('4')}>
                                        {props.referenciaPlantillaContenido[props.referenciaPlantillaContenido.length - 2].referenciaContenido[matrixBtn].tituloBoton}
                                    </button>
                                </div>
                            }
                            <div className='o-inicio-journey__down__matrix__canva__btns'>
                                <button
                                    onClick={() => handleMatrix(matrixBtn, 'prev')}
                                    className={`o-inicio-journey__down__matrix__canva__btns__prev${matrixBtn === 0 ? '--disabled' : ''}`}>
                                        <i className='icon-chevron-left1' /> Atrás
                                </button>
                                <button
                                    onClick={() => handleMatrix(matrixBtn, 'next')}
                                    className={`o-inicio-journey__down__matrix__canva__btns__next${matrixBtn === 3 ? '--disabled' : ''}`}>
                                        Adelante <i className='icon-chevron-right1' />
                                </button>
                            </div>
                        </div>
                    </div>
                }
                {
                    ( !showMatrix && stage > 3 ) &&
                    <div className='o-inicio-journey__down__benefits'>
                        <span>{props.referenciaPlantillaContenido[stage + 1].tituloBanner}</span>
                        <div className='o-inicio-journey__down__benefits__cards'>
                            {
                                props.referenciaPlantillaContenido[stage + 1].referenciaContenido.map((card, key) => (
                                    <SubsectionInfo key={`card${key}`}
                                        title={card.tituloBanner}
                                        description={card.descripcion.json}
                                        image={card.imagenPrincipal && card.imagenPrincipal[0]}
                                    />)
                                )
                            }
                        </div>
                        <button onClick={() => handleStageChange('0')}>
                            {props.referenciaPlantillaContenido[stage + 1].tituloBoton}
                        </button>
                    </div>
                }
            </div>
            <Modal open={popup} className='o-inicio-journey__modal' onClose={() => setPopup(false)}>
                <div className='o-inicio-journey__modal__body'>
                    <i className="icon-close" id="icon-close__modal" onClick={() => setPopup(false)} />
                    <h2>{props.referenciaPlantillaContenido[props.referenciaPlantillaContenido.length - 1].tituloBanner}</h2>
                    <p>
                        { props.referenciaPlantillaContenido[props.referenciaPlantillaContenido.length - 1].descripcion.json.content && documentToReactComponents(props.referenciaPlantillaContenido[props.referenciaPlantillaContenido.length - 1].descripcion.json) }
                    </p>
                </div>
            </Modal>
        </div>
    )
}

export default InicioJourney