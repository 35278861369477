import React from "react"

// Components //
import TitleSection from "../../molecules/title-section"
import AmountCircle from "../../molecules/amount-circle"

// Styles //
import "./our-connections-home.scss"
import { ReferenciaContenido } from "../../../interfaces/ReferenciaContenido"

const OurConnectionsHome: React.FC<ReferenciaContenido> = (props) => {

    const {
        tituloGeneral,
        descripcion,
        referenciaPlantillaContenido: items,
    } = props;

    return (
        <div className="container">
            <div className="o-connections">
                <div className="o-connections__container">
                    <TitleSection
                        title={tituloGeneral}
                        introText={descripcion.json.content[0].content[0].value}
                    />
                    <div className="o-connections__circles">
                        {items.map((item, index) => (
                            <AmountCircle
                                amount={
                                    item.descripcion.json.content[0].content[0]
                                        .value
                                }
                                title={item.tituloBanner}
                                key={index}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OurConnectionsHome
