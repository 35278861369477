import React from "react";
import Slider from "react-slick";
import './categoriesMenu.scss';

export interface ICategoriesMenuProps {
	title: string;
	cards: [
		{
			redirection: string;
			image: string;
			title: string;
		}
	]
}

const CategoriesMenu = (props: ICategoriesMenuProps) => {

	const { title, cards } = props;

	const settings = {
		infinite: false,
		speed: 300,
		slidesToShow: 5,
		slidesToScroll: 1,
		arrows: true,
		responsive: [
			{
				breakpoint: 1360, // x-large
				settings: {
					slidesToShow: 3.5,
					slidesToScroll: 1,
					arrows: true,
				},
			},
			{
				breakpoint: 768, // medium
				settings: {
					slidesToShow: 2.5,
					slidesToScroll: 1,
					arrows: false,
				},
			},
			{
				breakpoint: 570, // s-medium
				settings: {
					slidesToShow: 1.5,
					slidesToScroll: 1,
					arrows: false,
				},
			},
		],
	}

	return (
		<div className="m-categories-menu">
			{title && <h2 className="m-categories-menu__title">{title}</h2>}
			<div className="m-categories-menu-slider">
				<Slider  {...settings} className="m-categories-menu-slider__slider">
					{
						cards.map((cardContent) => (
							<a href={cardContent.redirection} className="m-categories-menu-slider__item">
								<img src={cardContent.image} width={40} height={40}></img>
								<p className={"m-categories-menu-slider__item__text"}>
									{cardContent.title}
								</p>
							</a>
						))
					}
				</Slider>
			</div>


		</div>
	)

}
export default CategoriesMenu

