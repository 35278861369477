import React, { useState, useEffect } from "react";
import "./accordion.scss";
import Button from "../../atoms/button/button";
import { normalizeTwoWords } from "../../../shared/utils/stringUtilities";
import Modal from "../../atoms/modal/modal";
import InputFiles from "../../atoms/input-files/inputFiles";
import axios from "axios";
import Info from "../../../assets/images/icons/informacion60.svg";
import Alerta from "../../../assets/images/icons/alertaroja.svg";
import Check from "../../../assets/images/icons/check.svg";
import { CircularProgress } from "@material-ui/core";
import Tooltip from "../../atoms/tooltip/tooltip";

interface delegatedPerson {
   tipo_doc: string;
   doc_identidad: string | number;
   nombre: string;
   correo: string;
   num_contacto: string | number;
}

export const Accordion = (props: any) => {
   const {
      naturalezaEmp,
      otherDocTypes,
      setVerifyDatosEmpresa,
      setVerifyDatosContactoEmpreas,
      setVerifyDatosRepresentanteLegal,
      setVerifyDatosConvenio,
      setVerifyDatosAprobacionLibranza,
      setVerifyDatosPagoLibranza,
      setVerifyAttachmentsLibranzas,
      setVerifyAttachmentsComercio,
   } = props;

   const [isActive, setIsActive] = useState(false);
   const [section5, setSection5] = useState<any[]>([]);
   const [section6, setSection6] = useState<any[]>([]);
   const [condEspecial, setCondEspecial] = useState(false);
   const [statePopUp2, setStatePopUp2] = useState(false);
   const [checkAcuerdos, setCheckAcuerdos] = useState(true);
   const [acuerdoPopUp, setAcuerdoPopUp] = useState(false);
   const [textAcuerdos, setTextAcuerdos] = useState("servicio");
   const [isDisabled, setIsDisable] = useState(false);
   const [loading, setLoading] = useState(false);
   const [disable, setDisable] = useState(false);
   const [messageRazonSocial, setMessageRazonSocial] = useState("");
   const [popUpRazonSocial, setPopUpRazonSocial] = useState(false);

   const updateFormValues = (value: any, name?: any) => {
      name &&
         props.setFormValues((prev) => {
            return { ...prev, [name]: value };
         });
   };

   const changeValuesForm = (
      list: string,
      index: string,
      objField: string,
      value: string | number
   ) => {
      let newIndependentFields = { ...props.formValues };
      newIndependentFields[list][index][objField] = value;
      props.setFormValues(newIndependentFields);
   };

   const addNewPerson = (list: string) => {
      let newIndependentFields = { ...props.formValues };
      newIndependentFields[list].push({
         tipo_doc: "Cédula de ciudadanía",
         doc_identidad: "",
         nombre: "",
         correo: "",
         num_contacto: "",
      });
      props.setFormValues(newIndependentFields);
   };

   const deleteNewPerson = (list: string) => {
      let newIndependentFields = { ...props.formValues };
      newIndependentFields[list].pop();
      props.setFormValues(newIndependentFields);
   };

   const changeValuesConvenio = (
      subField: string,
      index: string,
      value: boolean
   ) => {
      let newIndependentFields = { ...props.formValues };
      newIndependentFields.info_convenio[subField][index] = value;
      props.setFormValues(newIndependentFields);
   };

   const formatDownload = () => {
      window.open(
         process.env.LIBRANZAS_SERVICES_URL +
            "descargar-formato?id_empresa=" +
            props.formValues.numero_doc_emp +
            props.formValues.digito_verificacion,
         "_blank"
      );
   };

   const deleteItemSection5 = () => {
      let vec = [...section5];
      vec.pop();

      deleteNewPerson("info_personas_aprob");
      setSection5(vec);
   };

   const deleteItemSection6 = () => {
      let vec = [...section6];
      vec.pop();

      deleteNewPerson("info_personas_pago");
      setSection6(vec);
   };

   const addSection5 = (obj, index) => {
      return (
         <div key={"section5-" + (index + 1)}>
            <div className="separator">
               <span>Persona autorizada {index + 1}</span>
            </div>
            <div className="row">
               <div className="column">
                  <div className="m-accordion__body__content">
                     <label className="m-accordion__body__content__label">
                        Tipo de identificación:{" "}
                        <span className="required_field">*</span>
                     </label>{" "}
                     <br />
                     <select
                        className="m-accordion__body__content__input"
                        required
                        id={
                           "tipo_identificacion_persona_autorizada_aprobacion" +
                           (index + 1)
                        }
                        value={
                           props.formValues.info_personas_aprob[index].tipo_doc
                        }
                        onChange={(e) =>
                           changeValuesForm(
                              "info_personas_aprob",
                              String(index),
                              "tipo_doc",
                              e.target.value
                           )
                        }
                     >
                        <option>Cédula de ciudadanía</option>
                        <option>Cédula extranjería</option>
                        <option>Pasaporte</option>
                        <option>PEP</option>
                        <option>PPT</option>
                     </select>
                  </div>
                  <div className="m-accordion__body__content">
                     <label className="m-accordion__body__content__label">
                        Nombre: <span className="required_field">*</span>
                     </label>{" "}
                     <br />
                     <input
                        className="m-accordion__body__content__input"
                        type="text"
                        maxLength={150}
                        value={obj.nombre}
                        onChange={(e) =>
                           changeValuesForm(
                              "info_personas_aprob",
                              String(index),
                              "nombre",
                              e.target.value
                           )
                        }
                        placeholder="Nombre"
                        id={
                           "nombre_persona_autorizada_aprobacion" + (index + 1)
                        }
                     />
                  </div>
                  <div className="m-accordion__body__content">
                     <label className="m-accordion__body__content__label">
                        Número de contacto:{" "}
                        <span className="required_field">*</span>
                     </label>{" "}
                     <br />
                     <input
                        className="m-accordion__body__content__input"
                        type="text"
                        value={obj.num_contacto}
                        onChange={(e) =>
                           changeValuesForm(
                              "info_personas_aprob",
                              String(index),
                              "num_contacto",
                              e.target.value
                           )
                        }
                        pattern="[0-9]+"
                        maxLength={10}
                        placeholder="Número de contacto "
                        id={
                           "numero_contacto_persona_autorizada_aprobacion" +
                           (index + 1)
                        }
                     />
                  </div>
               </div>
               <div className="column">
                  <div className="m-accordion__body__content">
                     <label className="m-accordion__body__content__label">
                        Número de documento de identidad:{" "}
                        <span className="required_field">*</span>
                     </label>{" "}
                     <br />
                     <input
                        className="m-accordion__body__content__input"
                        type="text"
                        value={obj.doc_identidad}
                        onChange={(e) =>
                           changeValuesForm(
                              "info_personas_aprob",
                              String(index),
                              "doc_identidad",
                              e.target.value
                           )
                        }
                        maxLength={15}
                        placeholder="Número de documento de identidad"
                        id={
                           "num_doc_persona_autorizada_aprobacion" + (index + 1)
                        }
                     />
                  </div>
                  <div className="m-accordion__body__content">
                     <label className="m-accordion__body__content__label">
                        Correo electrónico:{" "}
                        <span className="required_field">*</span>
                     </label>{" "}
                     <br />
                     <input
                        className="m-accordion__body__content__input"
                        maxLength={100}
                        value={obj.correo}
                        onChange={(e) =>
                           changeValuesForm(
                              "info_personas_aprob",
                              String(index),
                              "correo",
                              e.target.value
                           )
                        }
                        type="email"
                        placeholder="Correo electrónico"
                        id={
                           "correo_electronico_persona_autorizada_aprobacion" +
                           (index + 1)
                        }
                     />
                  </div>
               </div>
            </div>
         </div>
      );
   };

   const addSection6 = (obj, index) => {
      return (
         <div key={"section5-" + (index + 1)}>
            <div className="separator">
               <span>Persona autorizada {index + 1}</span>
            </div>
            <div className="row">
               <div className="column">
                  <div className="m-accordion__body__content">
                     <label className="m-accordion__body__content__label">
                        Tipo de identificación:{" "}
                        <span className="required_field">*</span>
                     </label>{" "}
                     <br />
                     <select
                        className="m-accordion__body__content__input"
                        required
                        id={
                           "tipo_identificacion_persona_autorizada_aprobacion" +
                           (index + 1)
                        }
                        value={
                           props.formValues.info_personas_pago[index].tipo_doc
                        }
                        onChange={(e) =>
                           changeValuesForm(
                              "info_personas_pago",
                              String(index),
                              "tipo_doc",
                              e.target.value
                           )
                        }
                     >
                        <option>Cédula de ciudadanía</option>
                        <option>Cédula extranjería</option>
                        <option>Pasaporte</option>
                        <option>PEP</option>
                        <option>PPT</option>
                     </select>
                  </div>
                  <div className="m-accordion__body__content">
                     <label className="m-accordion__body__content__label">
                        Nombre: <span className="required_field">*</span>
                     </label>{" "}
                     <br />
                     <input
                        className="m-accordion__body__content__input"
                        type="text"
                        maxLength={150}
                        value={obj.nombre}
                        onChange={(e) =>
                           changeValuesForm(
                              "info_personas_pago",
                              String(index),
                              "nombre",
                              e.target.value
                           )
                        }
                        placeholder="Nombre"
                        id={
                           "nombre_persona_autorizada_aprobacion" + (index + 1)
                        }
                     />
                  </div>
                  <div className="m-accordion__body__content">
                     <label className="m-accordion__body__content__label">
                        Número de contacto:{" "}
                        <span className="required_field">*</span>
                     </label>{" "}
                     <br />
                     <input
                        className="m-accordion__body__content__input"
                        type="text"
                        value={obj.num_contacto}
                        onChange={(e) =>
                           changeValuesForm(
                              "info_personas_pago",
                              String(index),
                              "num_contacto",
                              e.target.value
                           )
                        }
                        pattern="[0-9]+"
                        maxLength={10}
                        placeholder="Número de contacto "
                        id={
                           "numero_contacto_persona_autorizada_aprobacion" +
                           (index + 1)
                        }
                     />
                  </div>
               </div>
               <div className="column">
                  <div className="m-accordion__body__content">
                     <label className="m-accordion__body__content__label">
                        Número de documento de identidad:{" "}
                        <span className="required_field">*</span>
                     </label>{" "}
                     <br />
                     <input
                        className="m-accordion__body__content__input"
                        type="text"
                        value={obj.doc_identidad}
                        onChange={(e) =>
                           changeValuesForm(
                              "info_personas_pago",
                              String(index),
                              "doc_identidad",
                              e.target.value
                           )
                        }
                        maxLength={15}
                        placeholder="Número de documento de identidad"
                        id={
                           "num_doc_persona_autorizada_aprobacion" + (index + 1)
                        }
                     />
                  </div>
                  <div className="m-accordion__body__content">
                     <label className="m-accordion__body__content__label">
                        Correo electrónico:{" "}
                        <span className="required_field">*</span>
                     </label>{" "}
                     <br />
                     <input
                        className="m-accordion__body__content__input"
                        maxLength={100}
                        value={obj.correo}
                        onChange={(e) =>
                           changeValuesForm(
                              "info_personas_pago",
                              String(index),
                              "correo",
                              e.target.value
                           )
                        }
                        type="email"
                        placeholder="Correo electrónico"
                        id={
                           "correo_electronico_persona_autorizada_aprobacion" +
                           (index + 1)
                        }
                     />
                  </div>
               </div>
            </div>
         </div>
      );
   };

   const Acuerdos = (props) => {
      return (
         <>
            {props.text === "servicio" ? (
               <div className="m-accordion__modal__acuerdos">
                  <div className="m-accordion__modal__acuerdos__text">
                     <p>
                        Este acuerdo se realiza teniendo en cuenta lo
                        establecido en la Ley 1527 de 2012 y las normas que la
                        complementen, modifiquen, adicionen, deroguen o
                        sustituyan.
                     </p>
                     <br />
                     <p>
                        Para las solicitudes aprobadas con forma de pago
                        libranza o descuento de nómina, COMFAMA desembolsará los
                        créditos sólo cuando el trabajador autorice por medio de
                        su firma digital la deducción mediante el formato
                        autorización deducción nómina establecido por COMFAMA.
                        Formato que será dirigido además a LA EMPRESA para que
                        sea firmado de forma digital por una de las personas
                        autorizadas en el convenio para deducción de nómina por
                        el Representante Legal.{" "}
                        <strong>
                           LA EMPRESA, al firmar el formato Autorización
                           deducción nómina, no se constituye en codeudora ni
                           avalista de las obligaciones crediticias contraídas
                           por sus trabajadores con COMFAMA
                        </strong>
                        , salvo en los casos previstos en el ordenamiento
                        jurídico. No obstante, acuerda con COMFAMA cumplir los
                        siguientes compromisos:{" "}
                     </p>

                     <br />
                     <ul className="m-accordion__modal__acuerdos__text__decimal">
                        <li>
                           Deducir, retener y girar de las sumas de dinero que
                           haya de pagar a los beneficiarios, los valores que
                           estos adeuden a COMFAMA por concepto de productos o
                           servicios adquiridos bajo la modalidad de libranza,
                           para ser depositados a órdenes de esta, de
                           conformidad con lo establecido en el presente Acuerdo
                           y en la autorización conferida por el beneficiario.
                        </li>
                        <br />
                        <li>
                           La EMPRESA deberá efectuar las libranzas o descuentos
                           autorizados de la nómina, pagos u honorarios, aportes
                           o pensión de los beneficiarios de los créditos y
                           trasladar dichas cuotas a las entidades operadoras
                           correspondientes, dentro de los tres días hábiles
                           siguientes de haber efectuado el pago al asalariado,
                           contratista, afiliado, asociado o pensionado en el
                           mismo orden cronológico en que haya recibido la
                           libranza o autorización de descuento directo. Estos
                           podrán ser cancelados en la página web
                           www.comfama.com portal servicios para Empresas, o en
                           las Sedes COMFAMA.{" "}
                        </li>
                        <br />
                        <li>
                           LA EMPRESA deberá realizar las deducciones cada mes
                           hasta finalizar el crédito. En periodos de
                           vacaciones, LA EMPRESA deberá descontar la cuota del
                           trabajador en forma anticipada por un valor
                           proporcional al tiempo que el trabajador disfruta de
                           este periodo; esto con el fin de cubrir el periodo de
                           cesación de ejecución material de las actividades.{" "}
                        </li>
                        <br />
                        <li>
                           En caso de desvinculación de un trabajador que tenga
                           deuda con COMFAMA soportado con autorización de
                           deducción por nómina, LA EMPRESA deberá descontar de
                           la liquidación lo siguiente: - El 50% del salario y
                           de todos los emolumentos que lo constituyan como el
                           salario ordinario, trabajo suplementario, vacaciones
                           y primas de servicio - El 100% de la indemnización de
                           despido sin justa causa y – En el caso de la
                           Cooperativas el 100% de la compensación. Se debe
                           descontar el valor total adeudado a COMFAMA que podrá
                           consultarse en cualquiera de las sedes de COMFAMA o
                           en la página web www.comfama.com, en los términos
                           permitidos en el Código Sustantivo del Trabajo o en
                           la Legislación Laboral vigente. No se debe hacer
                           descuentos sobre las cesantías para el pago de
                           créditos.
                        </li>
                        <br />
                        <li>
                           LA EMPRESA será solidariamente responsable de todos
                           los intereses causados por la mora que se haya
                           generado por causas que le sean imputables.
                        </li>
                        <br />
                        <li>
                           LA EMPRESA debe contar siempre con el consentimiento
                           previo y escrito de COMFAMA para utilizar en
                           anuncios, publicidad, etc., el nombre, símbolo,
                           marca, enseña o cualquier abreviatura perteneciente a
                           COMFAMA.
                        </li>
                        <br />
                        <li>
                           LA EMPRESA declara que no se encuentra incursa en
                           causales de inhabilidad e incompatibilidad contenidas
                           en el Decreto 2463 de 1981, ni tiene conflictos de
                           interés, según lo previsto en la Ley 789 de 2002.
                        </li>
                        <br />
                        <li>
                           LA EMPRESA declara que sus recursos no provienen de
                           actividades ilícitas, como el lavado de activos, sus
                           delitos fuente o la financiación del terrorismo y
                           cumple, en caso de que aplique, con la regulación
                           pertinente en la prevención y control de los riesgos
                           de LA/FT, corrupción y soborno. Así mismo, LA EMPRESA
                           se obliga con COMFAMA a tomar las medidas apropiadas
                           para evitar que sus operaciones o actividades sean
                           empleadas para la canalización de recursos cuyo
                           origen sea ilícito, para financiar actos terroristas,
                           grupos terroristas o terroristas, para la
                           proliferación de armas de destrucción masiva o para
                           materializar hechos de fraude y corrupción.
                           Igualmente se obliga a no realizar, prometer, ofrecer
                           directa o indirectamente sumas de dinero, dádivas o
                           regalos a personas naturales o jurídicas, que puedan
                           ser catalogados como actos de corrupción o soborno.
                        </li>
                     </ul>
                     <br />
                     <p>
                        Será considerada información confidencial toda aquella
                        propiedad de COMFAMA y que no sea de carácter público,
                        que LA EMPRESA conozca producto o con ocasión del
                        presente Acuerdo. Bajo cualquier circunstancia, la
                        información será confidencial con independencia al hecho
                        de haber sido designada de tal manera, antes, durante o
                        después de ser revelada. El acceso a la información
                        confidencial no le otorga a LA EMPRESA ningún derecho de
                        propiedad, uso, explotación económica, o de cualquier
                        otra naturaleza sobre la misma. Así, ésta solo podrá ser
                        utilizada en ejecución del objeto de este Acuerdo. A la
                        información confidencial solo podrán acceder los
                        representantes, empleados o contratistas de LA EMPRESA,
                        si resultare exclusivamente necesario, en cumplimiento
                        del objeto de este acuerdo. En adición a este principio
                        de necesidad, LA EMPRESA deberá garantizar que previo al
                        acceso concedido a sus representantes, empleados o
                        contratistas, estos: (i) estén al tanto de la calidad de
                        la información a que tendrán acceso; (ii) hayan suscrito
                        con LA EMPRESA acuerdos de confidencialidad en virtud de
                        los cuales, se ofrezcan mínimo, las garantías de
                        protección contenidas en este acto. Una vez terminado el
                        presente Acuerdo, LA EMPRESA deberá devolver de manera
                        inmediata a COMFAMA toda la información suministrada
                        (originales y copias, independientemente del soporte) y
                        eliminar de sus servidores y sistemas dicha información
                        cuando así lo haya solicitado COMFAMA. LA EMPRESA deberá
                        informar a COMFAMA, dentro de los diez (10) días hábiles
                        siguientes a la terminación del presente Acuerdo, la
                        eliminación de la información mediante documento
                        suscrito por el representante legal y/o quien este
                        designe para el efecto. Este Acuerdo tendrá una vigencia
                        de una vigencia de doce (12) meses contados a partir de
                        su suscripción, prorrogables automáticamente por
                        periodos iguales. Sin embargo, cualquiera de las partes
                        podrá darlo por terminado con previo aviso siempre y
                        cuando no existan pagos pendientes por conceptos de
                        créditos de los trabajadores.
                     </p>
                     <div className="m-accordion__modal__acuerdos__text__button">
                        <Button
                           id="buttonVideoLink"
                           type="button"
                           href="#"
                           classname={"secondary "}
                           text="OK"
                           isDisabled={false}
                           gtmMark={`gtm${normalizeTwoWords(
                              "Acuerdos"
                           )}${normalizeTwoWords("OK")}Btn`}
                           onClickAction={() => setAcuerdoPopUp(!acuerdoPopUp)}
                        />
                     </div>
                  </div>
               </div>
            ) : props.text === "privacidad" ? (
               <div className="m-accordion__modal__acuerdos">
                  <div className="m-accordion__modal__acuerdos__text">
                     <p>
                        COMFAMA te comunica que los datos personales, incluyendo
                        datos biométricos, que suministras por cualquier medio o
                        canal de contacto propio o de terceros serán tratados de
                        manera segura, confidencial y en cumplimiento de la ley
                        de protección de datos para:
                     </p>
                     <br />
                     <ul className="m-accordion__modal__acuerdos__text__disc">
                        <li>
                           El buen desarrollo del servicio, programa o evento.
                        </li>
                        <br />
                        <li>Suministrar información.</li>
                        <br />
                        <li>
                           Dar respuesta a las peticiones, quejas o reclamos.
                        </li>
                        <br />
                        <li>Remitir publicidad de servicios y productos.</li>
                        <br />
                        <li>Realizar encuestas de satisfacción.</li>
                        <br />
                        <li>
                           Validar, consultar y analizar en listas de riesgos.
                        </li>
                        <br />
                        <li>
                           Analizar tus datos para conocerte mejor y ofrecerte
                           servicios y productos especialmente diseñados para
                           ti.
                        </li>
                        <br />
                        <li>
                           Otras actividades de mercadeo y fidelización y todas
                           aquellas en cumplimiento de la regulación aplicable a
                           cajas de compensación familiar.
                        </li>
                        <br />
                     </ul>
                     <p>Ten en cuenta que:</p>
                     <ul className="m-accordion__modal__acuerdos__text__disc">
                        <li>
                           COMFAMA te podrá contactar de manera verbal o escrita
                           a través de: correo electrónico, dirección física,
                           teléfonos fijos, celulares o aplicaciones móviles,
                           redes sociales, entre otros.
                        </li>
                        <br />
                        <li>
                           Tus datos también podrán ser tratados por terceros
                           encargados, dentro o fuera de Colombia; se podrán
                           entregar a las autoridades u otras entidades en los
                           términos legales.
                        </li>
                        <br />
                        <li>
                           Comfama eventualmente realiza registro de
                           participación en sus programas, sedes y eventos por
                           medio de fotografías y video al público en general,
                           los cuales podrán ser utilizados con fines
                           comerciales o publicitarios.{" "}
                        </li>
                        <br />
                     </ul>
                     <p>
                        Te invitamos a consultar nuestra política de protección
                        de datos en el portal web
                        <a
                           href="https://www.comfama.com/"
                           className="magenta-text"
                           target="_blank"
                        >
                           {" "}
                           www.comfama.com
                        </a>
                        .
                     </p>
                     <p>
                        Recuerda que no estás obligado a suministrarnos datos
                        personales sensibles. Y para conocer, actualizar,
                        rectificar, suprimir u oponerte a un tratamiento cuando
                        aplique puedes escribir al correo electrónico{" "}
                        <span className="magenta-text">
                           protecciondatospersonales@comfama.com.co
                        </span>
                        .
                     </p>
                     <br />
                     <p>
                        Declaras y garantizas que estás legitimado para
                        entregarnos toda la información personal de terceras
                        personas contenida en este instrumento, la cual será
                        utilizada para los mismos tratamientos y finalidades
                        aquí indicados.
                     </p>
                     <div className="m-accordion__modal__acuerdos__text__button">
                        <Button
                           id="buttonVideoLink"
                           type="button"
                           href="#"
                           classname={"secondary "}
                           text="OK"
                           isDisabled={false}
                           gtmMark={`gtm${normalizeTwoWords(
                              "Acuerdos"
                           )}${normalizeTwoWords("OK")}Btn`}
                           onClickAction={() => setAcuerdoPopUp(!acuerdoPopUp)}
                        />
                     </div>
                  </div>
               </div>
            ) : (
               ""
            )}
         </>
      );
   };

   const textAcuerdosPopUp = (text) => {
      setAcuerdoPopUp(true);
      setTextAcuerdos(text);
   };

   const sendFile = async (typeRequest, event) => {
      if (event && event[0] && event[0][0]) {
         setLoading(true);
         var formData = new FormData();
         const file = event[0][0];
         // const blobFile = new Blob([event[0][0]], {
         //     type: event[0][0].type
         // })
         formData.append("file", file);

         const json = JSON.stringify({
            doc:
               props.formValues.numero_doc_emp +
               props.formValues.digito_verificacion,
            type: event[0][0].type,
         });
         const blobJSON = new Blob([json], {
            type: "application/json",
         });

         formData.append("json", blobJSON);

         const response = await axios.post(
            process.env.LIBRANZAS_SERVICES_URL +
               "upload_files?type=" +
               typeRequest,
            formData,
            {
               headers: {
                  "Content-Type": "binary/octet-stream",
               },
            }
         );

         const url = response.data.mensaje;

         if (response.status === 200) {
            try {
               const resp = await axios.put(url, file, {
                  headers: {
                     "Content-Type": event[0][0].type,
                  },
               });
            } catch (e) {
               console.log(e);
            }
         }

         if (typeRequest === "document") {
            updateFormValues(event[0][0].type, "typeFileFormat");
            setVerifyAttachmentsLibranzas(true);
         } else if (typeRequest === "comercio") {
            updateFormValues(event[0][0].type, "typeFileCamara");
            setVerifyAttachmentsComercio(true);
         }
         setLoading(false);
      }
   };

   const twoCalls = (e) => {
      if (e.target.value === "Persona Natural") {
         updateFormValues(1, "naturalezaEmp");
         updateFormValues(1, "tipo_doc_emp");
         updateFormValues("", "digito_verificacion");
      } else {
         updateFormValues(2, "naturalezaEmp");
         updateFormValues(3, "tipo_doc_emp");
      }
   };

   useEffect(() => {
      switch (props.title) {
         case "Datos del empleador o entidad pagadora":
            if (
               props.formValues.naturalezaEmp !== "" &&
               props.formValues.tipo_doc_emp !== "" &&
               props.formValues.numero_doc_emp !== "" &&
               (props.formValues.naturalezaEmp === "Persona Natural" ||
                  props.formValues.naturalezaEmp === 1 ||
                  props.formValues.digito_verificacion !== "") &&
               props.formValues.razon_social
            )
               setVerifyDatosEmpresa(true);
            else setVerifyDatosEmpresa(false);
         case "Información para contacto de libranza":
            if (
               props.formValues.nombre_contacto_empresa !== "" &&
               props.formValues.correo_empresa !== "" &&
               /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
                  props.formValues.correo_empresa
               ) &&
               (props.formValues.correo_empresa_opc === "" ||
                  /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
                     props.formValues.correo_empresa_opc
                  )) &&
               props.formValues.contacto_empresa !== ""
            )
               setVerifyDatosContactoEmpreas(true);
            else setVerifyDatosContactoEmpreas(false);
         case "Información de representante legal":
            const validationList = [null, undefined, ""];
            if (
               validationList.every(
                  (value) => props.formValues.tipo_doc_rep_legal != value
               ) &&
               validationList.every(
                  (value) => props.formValues.doc_rep_legal != value
               ) &&
               validationList.every(
                  (value) => props.formValues.nombre_rep_legal != value
               ) &&
               /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
                  props.formValues.correo_rep_legal
               ) &&
               /^[0-9]{10}$/.test(props.formValues.contacto_rep_legal)
            )
               setVerifyDatosRepresentanteLegal(true);
            else setVerifyDatosRepresentanteLegal(false);

         case "Información de personas autorizadas para aprobación de libranza":
            if (
               props.formValues.info_personas_aprob[0].tipo_doc !== "" &&
               props.formValues.info_personas_aprob[0].doc_identidad !== "" &&
               props.formValues.info_personas_aprob[0].nombre !== "" &&
               props.formValues.info_personas_aprob[0].correo !== "" &&
               /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
                  props.formValues.info_personas_aprob[0].correo
               ) &&
               props.formValues.info_personas_aprob[0].num_contacto !== ""
            )
               setVerifyDatosAprobacionLibranza(true);
            else setVerifyDatosAprobacionLibranza(false);
         case "Información de personas autorizadas para girar el valor de la libranza":
            if (
               props.formValues.info_personas_pago[0].tipo_doc !== "" &&
               props.formValues.info_personas_pago[0].doc_identidad !== "" &&
               props.formValues.info_personas_pago[0].nombre !== "" &&
               props.formValues.info_personas_pago[0].correo !== "" &&
               /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
                  props.formValues.info_personas_pago[0].correo
               ) &&
               props.formValues.info_personas_pago[0].num_contacto !== ""
            )
               setVerifyDatosPagoLibranza(true);
            else setVerifyDatosPagoLibranza(false);
      }
   }, [props.formValues]);

   const handleCondition = () => {
      setCondEspecial(false);
      props.setFormValues({
         ...props.formValues,
         ["info_convenio"]: {
            ...props.formValues.info_convenio,
            ["condicion_especial"]: "",
         },
      });
   };

   useEffect(() => {
      if (props.formValues.info_convenio.condicion_especial !== "") {
         setCondEspecial(true);
      } else {
         setCondEspecial(false);
      }
   }, []);

   const checkConvenio = async () => {
      setLoading(true);
      const payload = JSON.stringify({
         data: {
            id_empresa:
               props.formValues.numero_doc_emp +
               props.formValues.digito_verificacion,
            tipo_doc_emp_id: props.formValues.tipo_doc_emp,
         },
      });

      var url = process.env.LIBRANZAS_SERVICES_URL + "aportantes/get_data";

      try {
         const response = await axios.post(url, payload, {
            headers: {
               "Content-Type": "application/json",
            },
         });

         if (response.data.status === "error") {
            setMessageRazonSocial(
               "En este momento, el servicio está caído. Estamos trabajando para solucionarlo, inténtalo de nuevo más tarde o escríbenos a conveniodxn@comfama.com.co"
            );
            setPopUpRazonSocial(true);
         } else {
            const decodedResponse = atob(response.data);
            const responseObject = JSON.parse(decodedResponse);

            if (
               responseObject &&
               responseObject["soap:Envelope"] &&
               responseObject["soap:Envelope"]["soap:Body"]
            ) {
               const razonSocial =
                  responseObject["soap:Envelope"]["soap:Body"][
                     "ns2:SUBSIDIO_PSCD_Servicio_Aportante_Resp_MT"
                  ]["RAZON_SOCIAL"];
               if (razonSocial === null) {
                  updateFormValues("", "razon_social");
                  setDisable(false);
                  setMessageRazonSocial(
                     "No podemos crear un convenio para este NIT, ya que no está afiliado a Comfama. Verifícalo e intenta de nuevo."
                  );
                  setPopUpRazonSocial(true);
               } else {
                  updateFormValues(razonSocial, "razon_social");
                  setDisable(true);
               }
            } else {
               setMessageRazonSocial(
                  "Ocurrio un error al consultar la información. Por favor, inténtalo mas tarde o escríbenos a conveniodxn@comfama.com.co."
               );
               setPopUpRazonSocial(true);
            }
         }
      } catch (error) {
         setMessageRazonSocial(
            "Ocurrio un error al consultar la información. Por favor, inténtalo mas tarde o escríbenos a conveniodxn@comfama.com.co."
         );
         setPopUpRazonSocial(true);
      } finally {
         setLoading(false);
      }
   };

   useEffect(() => {
      if (
         props.title === "Datos del empleador o entidad pagadora" &&
         props.showCheck
      ) {
         checkConvenio();
      }
   }, [props.showCheck]);

   return (
      <div className="m-accordion">
         {loading === true ? (
            <div className={`o-form-libranzas__loadingMag`}>
               <CircularProgress size={64} thickness={6} color="secondary" />
            </div>
         ) : null}

         <Modal
            open={acuerdoPopUp}
            onClose={() => setPopUpRazonSocial(!acuerdoPopUp)}
            className="m-share-modal"
         >
            <Acuerdos text={textAcuerdos} />
         </Modal>

         <Modal
            open={popUpRazonSocial}
            onClose={() => setPopUpRazonSocial(!popUpRazonSocial)}
            className="m-share-modal"
         >
            <div className="m-accordion__modal__body">
               <div className="m-accordion__modal__body__close">
                  <button
                     className="icon-close"
                     onClick={() => setPopUpRazonSocial(!popUpRazonSocial)}
                  />
               </div>
               <div className="m-accordion__modal__body__header">
                  <div className="m-accordion__modal__body__header__image">
                     <img src={Alerta} alt="Logo alerta" />
                  </div>
                  <span>¡Algo salió mal!</span>
               </div>
               <div className="m-accordion__modal__body__help">
                  <p>{messageRazonSocial}</p>
               </div>
               <div className="m-accordion__modal__body__otherButton">
                  <Button
                     id="buttonVideoLink"
                     type="button"
                     href="#"
                     classname={"secondary"}
                     text="ACEPTAR"
                     isDisabled={false}
                     gtmMark={`gtm${normalizeTwoWords(
                        "Libranzas"
                     )}${normalizeTwoWords("ACEPTAR")}Btn`}
                     onClickAction={() => {
                        setPopUpRazonSocial(!popUpRazonSocial);
                     }}
                  />
               </div>
            </div>
         </Modal>

         <Modal
            open={statePopUp2}
            onClose={() => setStatePopUp2(!statePopUp2)}
            className="m-share-modal"
         >
            <div className="m-accordion__modal__body">
               <div className="m-accordion__modal__body__close">
                  <button
                     className="icon-close"
                     onClick={() => setStatePopUp2(!statePopUp2)}
                  />
               </div>
               <div className="m-accordion__modal__body__header">
                  <div className="m-accordion__modal__body__header__image">
                     <img src={Info} alt="Logo like" />
                  </div>
                  <span>Sigue estos pasos:</span>
               </div>
               <div className="m-accordion__modal__body__help">
                  <p>
                     <strong>1.</strong> Selecciona abrir con Google Chrome.
                     <br />
                     <strong>2.</strong> Haz clic en imprimir. <br />
                     <strong>3.</strong> En destino selecciona guardar como PDF.
                     <br />
                  </p>
               </div>
               <div className="m-accordion__modal__body__otherButton">
                  <Button
                     id="buttonVideoLink"
                     type="button"
                     href="#"
                     classname={"secondary"}
                     text=" Ahora te debe abrir"
                     isDisabled={false}
                     gtmMark={`gtm${normalizeTwoWords(
                        "Libranzas"
                     )}${normalizeTwoWords("OK")}Btn`}
                     onClickAction={() => {
                        setStatePopUp2(!statePopUp2);
                     }}
                  />
               </div>
            </div>
         </Modal>

         <div
            className={`m-accordion__header ${
               props.showCheck && "stepper--active"
            }`}
            onClick={() => {
               setIsActive(!isActive); // muestra el contenido
            }}
         >
            {props.showCheck ? (
               <div className="m-accordion__header__success-icon">
                  <img src={Check} alt="Logo Check" />
               </div>
            ) : (
               <div className="circle">{props.step}</div>
            )}
            <h3 className="m-accordion__header__tooltip">
               {props.title}
               {props.title ===
               "Información de personas autorizadas para aprobación de libranza" ? (
                  <Tooltip
                     title="Importante"
                     textString="A estos contactos les notificaremos cuando una libranza esté en trámite, ya que son las personas autorizadas para aprobar o rechazar una libranza. Por eso confimar que los datos estén correctos."
                     id="unique-tooltip-id"
                  />
               ) : (
                  ""
               )}
               {props.title ===
               "Información de personas autorizadas para girar el valor de la libranza" ? (
                  <Tooltip
                     title="Importante"
                     textString="Estas personas son las autorizadas para pagar cada mes los valores de las libranza de sus colaboradores. Revisa que la información este correcta para que puedan ingresar al canal de pago digital habilitado por Comfama."
                     id="unique-tooltip-id"
                  />
               ) : (
                  ""
               )}
               {props.title === "Información para contacto de libranza" ? (
                  <Tooltip
                     title="Importante"
                     textString="Esta información debe estar diligenciada de forma correcta, ya que Comfama enviará a estos contactos toda la información relevante de este acuerdo."
                     id="unique-tooltip-id"
                  />
               ) : (
                  ""
               )}
               {props.title === "Información de representante legal" ? (
                  <Tooltip
                     title="Importante"
                     textString="Este acuerdo debe ser firmado por el representante legal de tu empresa, asegúrate que esta información esté correcta para que pueda firmar de forma digital."
                     id="unique-tooltip-id"
                  />
               ) : (
                  ""
               )}
            </h3>

            <span
               className="open_button"
               onClick={() => setIsActive(!isActive)}
            >
               {isActive ? "-" : "+"}
            </span>
         </div>
         <div className={`m-accordion__body ${isActive && "show-body"}`}>
            {props.title ===
               "Acuerdo de servicio y Aviso de Privacidad General" && (
               <div>
                  <div className="m-accordion__body__content">
                     <p>
                        <span className="required_field">*</span> Consulta el
                        Acuerdo de servicio de crédito con libranza{" "}
                        <span
                           className="required_letter"
                           onClick={() => textAcuerdosPopUp("servicio")}
                        >
                           Aquí
                        </span>
                     </p>
                     <p>
                        <span className="required_field">*</span> Consulta el
                        Aviso de Privacidad General{" "}
                        <span
                           className="required_letter"
                           onClick={() => textAcuerdosPopUp("privacidad")}
                        >
                           Aquí
                        </span>
                     </p>
                  </div>
               </div>
            )}
            {props.title === "Datos del empleador o entidad pagadora" && (
               <div className="row">
                  <div className="column">
                     <div className="m-accordion__body__content">
                        <label className="m-accordion__body__content__label">
                           Naturaleza de la empresa:{" "}
                           <span className="required_field">*</span>
                        </label>{" "}
                        <br />
                        <select
                           className="m-accordion__body__content__input"
                           required
                           id="naturaleza_empresa_datos_empresa"
                           value={
                              props.formValues.naturalezaEmp === 1
                                 ? "Persona Natural"
                                 : "Persona Jurídica"
                           }
                           onChange={(e) => {
                              twoCalls(e);
                           }}
                        >
                           {naturalezaEmp.map((key, val) => (
                              <option key={val}>{key.name}</option>
                           ))}
                        </select>
                     </div>
                     <div className="m-accordion__body__content">
                        <div className="m-accordion__body__content__tooltip">
                           <label className="m-accordion__body__content__label">
                              Número de identificación:{" "}
                              <span className="required_field">*</span>
                           </label>{" "}
                           {props.formValues.naturalezaEmp === 2 && (
                              <Tooltip
                                 title="Importante"
                                 textString="En este campo el NIT debe ir sin dígito de verificación."
                                 id="unique-tooltip-id"
                              />
                           )}
                        </div>
                        <input
                           className="m-accordion__body__content__input"
                           type="text"
                           value={props.formValues.numero_doc_emp}
                           onChange={(e) =>
                              updateFormValues(e.target.value, "numero_doc_emp")
                           }
                           maxLength={
                              props.formValues.naturalezaEmp === 1 ? 13 : 9
                           }
                           pattern="[0-9]+"
                           placeholder="Número de identificación"
                           id="numero_identificacion_datos_empresa"
                        />
                     </div>
                     <div className="m-accordion__body__content">
                        <div className="m-accordion__body__content__tooltip">
                           <label className="m-accordion__body__content__label">
                              Nombre comercial:{" "}
                           </label>
                           <Tooltip
                              title="Importante"
                              textString="El nombre comercial es la forma como el público general llama a la empresa, mientras que la razón social se utiliza para identificarla de forma legal y oficial."
                              id="unique-tooltip-id"
                           />
                        </div>
                        <input
                           className="m-accordion__body__content__input"
                           type="text"
                           value={props.formValues.nombre_comercial}
                           onChange={(e) =>
                              updateFormValues(
                                 e.target.value,
                                 "nombre_comercial"
                              )
                           }
                           placeholder="Nombre comercial"
                           id="nombre_comercial"
                        />
                     </div>
                  </div>
                  <div className="column">
                     <div className="m-accordion__body__content">
                        <label className="m-accordion__body__content__label">
                           Tipo de identificación:{" "}
                           <span className="required_field">*</span>
                        </label>{" "}
                        <br />
                        {props.formValues.naturalezaEmp === 2 ? (
                           <select
                              className="m-accordion__body__content__input"
                              required
                              id="tipo_identificacion_datos_empleador"
                              value={"NIT"}
                           >
                              {<option>{"NIT"}</option>}
                           </select>
                        ) : (
                           <select
                              className="m-accordion__body__content__input"
                              required
                              id="tipo_identificacion_datos_empleador"
                              value={
                                 props.formValues.tipo_doc_emp === 1
                                    ? "Cédula de ciudadanía"
                                    : props.formValues.tipo_doc_emp === 2
                                    ? "Cédula de extranjería"
                                    : props.formValues.tipo_doc_emp === 4
                                    ? "Pasaporte"
                                    : props.formValues.tipo_doc_emp === 5
                                    ? "PEP"
                                    : "PPT"
                              }
                              onChange={(e) => {
                                 e.target.value === "Cédula de ciudadanía"
                                    ? updateFormValues(1, "tipo_doc_emp")
                                    : e.target.value === "Cédula de extranjería"
                                    ? updateFormValues(2, "tipo_doc_emp")
                                    : e.target.value === "Pasaporte"
                                    ? updateFormValues(4, "tipo_doc_emp")
                                    : e.target.value === "PEP"
                                    ? updateFormValues(5, "tipo_doc_emp")
                                    : updateFormValues(6, "tipo_doc_emp");
                              }}
                           >
                              {otherDocTypes.map((key, val) => (
                                 <option key={val}>{key.name}</option>
                              ))}
                           </select>
                        )}
                     </div>
                     {props.formValues.naturalezaEmp === 2 && (
                        <div className="m-accordion__body__content">
                           <label className="m-accordion__body__content__label">
                              Dígito de verificación:{" "}
                              <span className="required_field">*</span>
                           </label>{" "}
                           <br />
                           <input
                              className="m-accordion__body__content__input"
                              type="text"
                              value={props.formValues.digito_verificacion}
                              onChange={(e) =>
                                 updateFormValues(
                                    e.target.value,
                                    "digito_verificacion"
                                 )
                              }
                              pattern="[0-9]+"
                              maxLength={1}
                              placeholder="Dígito de verificación"
                              id="digito_verificacion_datos_empresa"
                           />
                        </div>
                     )}
                     <div className="m-accordion__body__content">
                        <label className="m-accordion__body__content__label">
                           Razón social:{" "}
                           <span className="required_field">*</span>
                        </label>
                        <br />
                        <input
                           className="m-accordion__body__content__input"
                           type="text"
                           value={props.formValues.razon_social}
                           onChange={(e) =>
                              updateFormValues(e.target.value, "razon_social")
                           }
                           placeholder="Razón social"
                           id="razon_social"
                           disabled={disable}
                        />
                     </div>
                  </div>
               </div>
            )}
            {props.title === "Información para contacto de libranza" && (
               <>
                  <div className="separator">
                     <span>Contacto de libranza 1</span>
                  </div>
                  <div className="row">
                     <div className="column">
                        <div className="m-accordion__body__content">
                           <label className="m-accordion__body__content__label">
                              Nombre contacto:{" "}
                              <span className="required_field">*</span>
                           </label>{" "}
                           <br />
                           <input
                              className="m-accordion__body__content__input"
                              type="text"
                              maxLength={150}
                              value={props.formValues.nombre_contacto_empresa}
                              onChange={(e) =>
                                 updateFormValues(
                                    e.target.value,
                                    "nombre_contacto_empresa"
                                 )
                              }
                              placeholder="Nombre contacto"
                              id="nombre_contacto_libranza"
                           />
                        </div>
                        <div className="m-accordion__body__content">
                           <label className="m-accordion__body__content__label">
                              Número de contacto:{" "}
                              <span className="required_field">*</span>
                           </label>{" "}
                           <br />
                           <input
                              className="m-accordion__body__content__input"
                              type="text"
                              maxLength={10}
                              value={props.formValues.contacto_empresa}
                              onChange={(e) =>
                                 updateFormValues(
                                    e.target.value,
                                    "contacto_empresa"
                                 )
                              }
                              pattern="[0-9]+"
                              placeholder="Número de contacto"
                              id="celular_contacto_libranza"
                           />
                        </div>
                     </div>
                     <div className="column">
                        <div className="m-accordion__body__content">
                           <label className="m-accordion__body__content__label">
                              Correo electrónico:{" "}
                              <span className="required_field">*</span>
                           </label>{" "}
                           <br />
                           <input
                              className="m-accordion__body__content__input"
                              type="email"
                              maxLength={100}
                              value={props.formValues.correo_empresa}
                              onChange={(e) =>
                                 updateFormValues(
                                    e.target.value,
                                    "correo_empresa"
                                 )
                              }
                              placeholder="Correo electrónico"
                              id="correo_electronico_contacto_libranza"
                           />
                        </div>
                     </div>
                  </div>
                  <div className="separator">
                     <span>Contacto de libranza 2 (Opcional)</span>
                  </div>
                  <div className="row">
                     <div className="column">
                        <div className="m-accordion__body__content">
                           <label className="m-accordion__body__content__label">
                              Nombre contacto:{" "}
                           </label>{" "}
                           <br />
                           <input
                              className="m-accordion__body__content__input"
                              type="text"
                              maxLength={150}
                              value={
                                 props.formValues.nombre_contacto_empresa_opc
                              }
                              onChange={(e) =>
                                 updateFormValues(
                                    e.target.value,
                                    "nombre_contacto_empresa_opc"
                                 )
                              }
                              placeholder="Nombre contacto"
                              id="nombre_contacto_libranza2"
                           />
                        </div>
                        <div className="m-accordion__body__content">
                           <label className="m-accordion__body__content__label">
                              Número de contacto:{" "}
                           </label>{" "}
                           <br />
                           <input
                              className="m-accordion__body__content__input"
                              type="text"
                              maxLength={10}
                              value={props.formValues.contacto_empresa_opc}
                              onChange={(e) =>
                                 updateFormValues(
                                    e.target.value,
                                    "contacto_empresa_opc"
                                 )
                              }
                              pattern="[0-9]+"
                              placeholder="Número de contacto"
                              id="celular_contacto_libranza2"
                           />
                        </div>
                     </div>
                     <div className="column">
                        <div className="m-accordion__body__content">
                           <label className="m-accordion__body__content__label">
                              Correo electrónico:{" "}
                           </label>{" "}
                           <br />
                           <input
                              className="m-accordion__body__content__input"
                              type="email"
                              maxLength={100}
                              value={props.formValues.correo_empresa_opc}
                              onChange={(e) =>
                                 updateFormValues(
                                    e.target.value,
                                    "correo_empresa_opc"
                                 )
                              }
                              placeholder="Correo electrónico"
                              id="correo_electronico_contacto_libranza2"
                           />
                        </div>
                     </div>
                  </div>
               </>
            )}
            {props.title === "Información de representante legal" && (
               <div className="row">
                  <div className="column">
                     <div className="m-accordion__body__content">
                        <label className="m-accordion__body__content__label">
                           Tipo de identificación:{" "}
                           <span className="required_field">*</span>
                        </label>{" "}
                        <br />
                        <select
                           className="m-accordion__body__content__input"
                           required
                           id="tipo_identificacion_representante_legal"
                           value={
                              props.formValues.tipo_doc_rep_legal === 1
                                 ? "Cédula de ciudadanía"
                                 : props.formValues.tipo_doc_rep_legal === 2
                                 ? "Cédula de extranjería"
                                 : props.formValues.tipo_doc_rep_legal === 4
                                 ? "Pasaporte"
                                 : props.formValues.tipo_doc_rep_legal === 5
                                 ? "PEP"
                                 : "PPT"
                           }
                           onChange={(e) => {
                              e.target.value === "Cédula de ciudadanía"
                                 ? updateFormValues(1, "tipo_doc_rep_legal")
                                 : e.target.value === "Cédula de extranjería"
                                 ? updateFormValues(2, "tipo_doc_rep_legal")
                                 : e.target.value === "Pasaporte"
                                 ? updateFormValues(4, "tipo_doc_rep_legal")
                                 : e.target.value === "PEP"
                                 ? updateFormValues(5, "tipo_doc_rep_legal")
                                 : updateFormValues(6, "tipo_doc_rep_legal");
                           }}
                        >
                           {otherDocTypes.map((key, val) => (
                              <option key={val}>{key.name}</option>
                           ))}
                        </select>
                     </div>
                     <div className="m-accordion__body__content">
                        <label className="m-accordion__body__content__label">
                           Nombre: <span className="required_field">*</span>
                        </label>{" "}
                        <br />
                        <input
                           className="m-accordion__body__content__input"
                           type="text"
                           maxLength={150}
                           value={props.formValues.nombre_rep_legal}
                           onChange={(e) =>
                              updateFormValues(
                                 e.target.value,
                                 "nombre_rep_legal"
                              )
                           }
                           placeholder="Nombre"
                           id="nombre_representante_legal"
                        />
                     </div>
                     <div className="m-accordion__body__content">
                        <label className="m-accordion__body__content__label">
                           Número de contacto:{" "}
                           <span className="required_field">*</span>
                        </label>{" "}
                        <br />
                        <input
                           className="m-accordion__body__content__input"
                           type="text"
                           value={props.formValues.contacto_rep_legal}
                           onChange={(e) =>
                              updateFormValues(
                                 e.target.value,
                                 "contacto_rep_legal"
                              )
                           }
                           pattern="[0-9]+"
                           maxLength={10}
                           minLength={10}
                           placeholder="Número de contacto "
                           id="numero_contacto_representante_legal"
                        />
                     </div>
                  </div>
                  <div className="column">
                     <div className="m-accordion__body__content">
                        <label className="m-accordion__body__content__label">
                           Número de documento de identidad:{" "}
                           <span className="required_field">*</span>
                        </label>{" "}
                        <br />
                        <input
                           className="m-accordion__body__content__input"
                           type="text"
                           value={props.formValues.doc_rep_legal}
                           onChange={(e) =>
                              updateFormValues(e.target.value, "doc_rep_legal")
                           }
                           maxLength={15}
                           placeholder="Número de documento de identidad"
                           id="num_doc_representante_legal"
                        />
                     </div>
                     <div className="m-accordion__body__content">
                        <label className="m-accordion__body__content__label">
                           Correo electrónico:{" "}
                           <span className="required_field">*</span>
                        </label>{" "}
                        <br />
                        <input
                           className="m-accordion__body__content__input"
                           type="email"
                           maxLength={100}
                           value={props.formValues.correo_rep_legal}
                           onChange={(e) =>
                              updateFormValues(
                                 e.target.value,
                                 "correo_rep_legal"
                              )
                           }
                           placeholder="Correo electrónico"
                           id="correo_electronico_representante_legal"
                        />
                     </div>
                  </div>
               </div>
            )}
            {props.title === "Condiciones para descuento de nómina" && (
               <div>
                  <table className="m-accordion__body__content__table">
                     <thead>
                        <tr>
                           <th className="m-accordion__body__content__table__th">
                              Productos
                           </th>
                           <th className="m-accordion__body__content__table__th">
                              ¿Autoriza descuento de nómina?
                           </th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr>
                           <th className="m-accordion__body__content__table__th_title">
                              <Tooltip
                                 title="Importante"
                                 textString="Créditos de libre destinación entre ellos compra de cartera, Libre inversión, avance cuota monetaria, entre otros."
                                 id="unique-tooltip-id"
                              />
                              <p> Créditos de consumo responsable</p>
                           </th>
                           <td>
                              <input
                                 type="radio"
                                 name="consumo_dx"
                                 id="consumo_dx_true"
                                 checked={
                                    props.formValues.info_convenio.consumo[0]
                                 }
                                 onChange={() =>
                                    changeValuesConvenio("consumo", "0", true)
                                 }
                                 disabled={isDisabled}
                              />{" "}
                              Sí{" "}
                              <input
                                 type="radio"
                                 name="consumo_dx"
                                 id="consumo_dx_false"
                                 checked={
                                    !props.formValues.info_convenio.consumo[0]
                                 }
                                 onChange={() =>
                                    changeValuesConvenio("consumo", "0", false)
                                 }
                                 disabled={isDisabled}
                              />{" "}
                              No
                           </td>
                        </tr>
                        <tr>
                           <th className="m-accordion__body__content__table__th_title">
                              <Tooltip
                                 title="Importante"
                                 textString="Créditos con un propósito especifico como: estudiar, viajar, cuidar la salud, mejorar la vivienda, adquirir productos de movilidad, de cómputo, entre otros."
                                 id="unique-tooltip-id"
                              />
                              <p> Créditos con propósito</p>
                           </th>
                           <td>
                              <input
                                 type="radio"
                                 name="proposito_dx"
                                 id="proposito_dx_true"
                                 checked={
                                    props.formValues.info_convenio.creditos[0]
                                 }
                                 onChange={() =>
                                    changeValuesConvenio("creditos", "0", true)
                                 }
                                 disabled={isDisabled}
                              />{" "}
                              Sí{" "}
                              <input
                                 type="radio"
                                 name="proposito_dx"
                                 id="propositoo_dx_false"
                                 checked={
                                    !props.formValues.info_convenio.creditos[0]
                                 }
                                 onChange={() =>
                                    changeValuesConvenio("creditos", "0", false)
                                 }
                                 disabled={isDisabled}
                              />{" "}
                              No
                           </td>
                        </tr>
                        <tr>
                           <th className="m-accordion__body__content__table__th_title">
                              Crédito de vivienda hipotecario
                           </th>
                           <td>
                              <input
                                 type="radio"
                                 name="vivienda_dx"
                                 id="vivienda_dx_true"
                                 checked={
                                    props.formValues.info_convenio.vivienda[0]
                                 }
                                 onChange={() =>
                                    changeValuesConvenio("vivienda", "0", true)
                                 }
                                 disabled={isDisabled}
                              />{" "}
                              Sí{" "}
                              <input
                                 type="radio"
                                 name="vivienda_dx"
                                 id="vivienda_dx_false"
                                 checked={
                                    !props.formValues.info_convenio.vivienda[0]
                                 }
                                 onChange={() =>
                                    changeValuesConvenio("vivienda", "0", false)
                                 }
                                 disabled={isDisabled}
                              />{" "}
                              No
                           </td>
                        </tr>
                        <tr>
                           <th className="m-accordion__body__content__table__th_title">
                              Seguros
                           </th>
                           <td>
                              <input
                                 type="radio"
                                 name="seguros_dx"
                                 id="seguros_dx_true"
                                 checked={
                                    props.formValues.info_convenio.seguros[0]
                                 }
                                 onChange={() =>
                                    changeValuesConvenio("seguros", "0", true)
                                 }
                                 disabled={isDisabled}
                              />{" "}
                              Sí{" "}
                              <input
                                 type="radio"
                                 name="seguros_dx"
                                 id="seguros_dx_false"
                                 checked={
                                    !props.formValues.info_convenio.seguros[0]
                                 }
                                 onChange={() =>
                                    changeValuesConvenio("seguros", "0", false)
                                 }
                                 disabled={isDisabled}
                              />{" "}
                              No
                           </td>
                        </tr>
                     </tbody>
                  </table>
                  <div className="m-accordion__body__content item-form--width-full">
                     <label className="m-accordion__body__content__label">
                        Porcentaje máximo autorizado de retenciones de nómina (
                        <span className="required_field">%</span>):
                     </label>
                     <br />
                     <input
                        className="m-accordion__body__content__input"
                        type="number"
                        value={props.formValues.info_convenio.porcentaje}
                        onChange={(e) =>
                           props.setFormValues({
                              ...props.formValues,
                              ["info_convenio"]: {
                                 ...props.formValues.info_convenio,
                                 ["porcentaje"]: e.target.value,
                              },
                           })
                        }
                        placeholder="Porcentaje máximo autorizado de retenciones de nómina"
                        id="procentaje_maximo_retencion_nomina_informacion_convenio"
                        defaultValue="50"
                        disabled={isDisabled}
                     />
                  </div>
                  {condEspecial === false ? (
                     <div className="m-accordion__body__content item-form--width-full">
                        {" "}
                        <div className="m-accordion__body__content__tooltip">
                           <label className="m-accordion__body__content__label">
                              {" "}
                              ¿Desea agregar una condición especial?{" "}
                           </label>{" "}
                           <Tooltip
                              title="Importante"
                              textString="Las condiciones especiales son características personalizadas para la deducción, retención y giro de los valores objeto de libranza."
                              id="unique-tooltip-id"
                           />
                        </div>
                        <br />
                        <input
                           type="radio"
                           name="condicion_especial"
                           id="condicion_especial_true"
                           onChange={() => setCondEspecial(true)}
                           disabled={isDisabled}
                        />{" "}
                        Sí{" "}
                        <input
                           type="radio"
                           name="condicion_especial"
                           id="condicion_especial_false"
                           checked={true}
                           onChange={() => setCondEspecial(false)}
                           disabled={isDisabled}
                        />{" "}
                        No{" "}
                     </div>
                  ) : (
                     <div className="m-accordion__body__content item-form--width-full">
                        {" "}
                        <div className="m-accordion__body__content__tooltip">
                           <label className="m-accordion__body__content__label">
                              {" "}
                              Condición especial:
                           </label>{" "}
                           <Tooltip
                              title="Importante"
                              textString="Las condiciones especiales son características personalizadas para la deducción, retención y giro de los valores objeto de libranza."
                              id="unique-tooltip-id"
                           />
                        </div>
                        <br />
                        <input
                           type="radio"
                           name="condicion_especial"
                           id="condicion_especial_true"
                           checked={true}
                           onChange={() => setCondEspecial(true)}
                           disabled={isDisabled}
                        />{" "}
                        Sí{" "}
                        <input
                           type="radio"
                           name="condicion_especial"
                           id="condicion_especial_false"
                           onClick={() => handleCondition()}
                           disabled={isDisabled}
                        />{" "}
                        No <br />{" "}
                        <input
                           className="m-accordion__body__content__input"
                           type="text"
                           value={
                              props.formValues.info_convenio.condicion_especial
                           }
                           onChange={(e) =>
                              props.setFormValues({
                                 ...props.formValues,
                                 ["info_convenio"]: {
                                    ...props.formValues.info_convenio,
                                    ["condicion_especial"]: e.target.value,
                                 },
                              })
                           }
                           placeholder="Condición especial"
                           id="condicion_especial_informacion_convenio"
                           disabled={isDisabled}
                        />{" "}
                     </div>
                  )}

                  {/* <div className="m-accordion__body__content item-form--width-full">
                        <label className="m-accordion__body__content__label">Condición especial:</label> <br />
                        <input className="m-accordion__body__content__input" type="text" value={props.formValues.info_convenio.condicion_especial} onChange={(e) => props.setFormValues({ ...props.formValues, ['info_convenio']: { ...props.formValues.info_convenio, ['condicion_especial']: e.target.value } })}
                            placeholder="Condición especial" id="condicion_especial_informacion_convenio" />
                    </div> */}

                  <div className="section-verify">
                     {" "}
                     {isDisabled && (
                        <Button
                           id="ButtonLibranzasEditar"
                           type="button"
                           href="#"
                           classname={"primary more-space"}
                           text={"Editar"}
                           isDisabled={!isDisabled}
                           onClickAction={() => {
                              setVerifyDatosConvenio(false);
                              setCheckAcuerdos(false);
                              setIsDisable(false);
                           }}
                        />
                     )}
                     <Button
                        id="ButtonLibranzasVerificar"
                        type="button"
                        href="#"
                        classname={"secondary "}
                        text={
                           props.showCheck && checkAcuerdos
                              ? "Verificado"
                              : "Verificar"
                        }
                        isDisabled={isDisabled}
                        onClickAction={() => {
                           setVerifyDatosConvenio(true);
                           setCheckAcuerdos(true);
                           setIsDisable(true);
                        }}
                     />
                  </div>
               </div>
            )}
            {props.title ===
               "Información de personas autorizadas para aprobación de libranza" &&
               props.formValues.info_personas_aprob.map((item, index) =>
                  addSection5(item, index)
               )}
            {props.title ===
               "Información de personas autorizadas para aprobación de libranza" &&
               props.formValues.info_personas_aprob.length < 6 && (
                  <div>
                     <div className="section-add">
                        <span
                           onClick={() => addNewPerson("info_personas_aprob")}
                        >
                           Agregar otra persona
                        </span>
                     </div>
                     <div className="section-verify"></div>
                  </div>
               )}
            {props.formValues.info_personas_aprob.length > 1 &&
               props.title ===
                  "Información de personas autorizadas para aprobación de libranza" && (
                  <div className="section-add">
                     <span onClick={() => deleteItemSection5()}>
                        Eliminar última persona
                     </span>
                  </div>
               )}
            {props.title ===
               "Información de personas autorizadas para girar el valor de la libranza" &&
               props.formValues.info_personas_pago.map((item, index) =>
                  addSection6(item, index)
               )}
            {props.title ===
               "Información de personas autorizadas para girar el valor de la libranza" &&
               props.formValues.info_personas_pago.length < 2 && (
                  <div>
                     <div className="section-add">
                        <span
                           onClick={() => addNewPerson("info_personas_pago")}
                        >
                           Agregar otra persona
                        </span>
                     </div>
                     <div className="section-verify"></div>
                  </div>
               )}
            {props.formValues.info_personas_pago.length > 1 &&
               props.title ===
                  "Información de personas autorizadas para girar el valor de la libranza" && (
                  <div className="section-add">
                     <span onClick={() => deleteItemSection6()}>
                        Eliminar última persona
                     </span>
                  </div>
               )}
            {props.title === "Descargar formato" && (
               <div className="infoText">
                  Verifica que todo este correcto en el pdf, si debes realizar
                  alguna corrección la debes hacer en el formulario dando clic
                  en Anterior
                  <div className="m-accordion__body__content__button">
                     <Button
                        id="ButtonLibranzasDescargar"
                        type="button"
                        href="#"
                        classname={"secondary "}
                        text="Descargar"
                        isDisabled={false}
                        gtmMark={`gtm${normalizeTwoWords(
                           "Libranzas"
                        )}${normalizeTwoWords("Descargar")}Btn`}
                        onClickAction={() => {
                           formatDownload();
                        }}
                     />
                  </div>
                  <div>
                     Aquí te explicamos como guardar el pdf si no te abre en la
                     aplicación de Adobe.
                     <i
                        className="icon-question-circle1"
                        onClick={() => setStatePopUp2(true)}
                     ></i>
                  </div>
               </div>
            )}
            {props.title === "Cargar documentos" && (
               <div>
                  <div className="container-button">
                     <div className="m-accordion__body__content__tooltip">
                        <label
                           className="m-accordion__body__content__label"
                           htmlFor="format_upload"
                        >
                           Certificación de representación legal{" "}
                           <span className="required_field">*</span>
                        </label>
                        <Tooltip
                           title="¿Qué es la certificación de representación legal?"
                           textString="La certificación de representación legal es el documento oficial que certifica la existencia de una empresa o establecimiento, y que contiene el nombre del representante legal. Por ejemplo, cámara de comercio, personería jurídica, RUT entre otros."
                           id="unique-tooltip-id"
                        />
                     </div>
                     <InputFiles
                        nameId={"Comercio"}
                        value={props.formValues["comercio_file"]}
                        handleChange={(e) => {
                           setVerifyAttachmentsComercio(false);
                           sendFile("comercio", e);
                        }}
                        maxFiles={1}
                        validTypes={["png", "jpg", "pdf", "doc"]}
                     />
                  </div>
                  <div className="container-button">
                     <label
                        className="m-accordion__body__content__label"
                        htmlFor="format_upload"
                     >
                        Documento de identidad del representante legal{" "}
                        <span className="required_field">*</span>
                     </label>
                     <InputFiles
                        nameId={"Document"}
                        value={props.formValues["libranzas_file"]}
                        handleChange={(e) => {
                           setVerifyAttachmentsLibranzas(false);
                           sendFile("document", e);
                        }}
                        maxFiles={1}
                        validTypes={["png", "jpg", "pdf", "doc"]}
                     />
                  </div>
               </div>
            )}
         </div>
      </div>
   );
};

export default Accordion;
