import React, {ChangeEvent} from "react";
import FieldGroup from "../field-group/FieldGroup";
import Input from "../input/Input";
import Label from "../label/Label";
import Select from "../select/Select";
import SelectSearch from 'react-select'
import Radio from "../radio/Radio";
import AddressSap from "../address-sap/AddressSap";
import Text from "../../text/Text";
import File from "../file/File";

const FieldGenerator = (props: any) => {
  const getInput = (field: any) => {
    switch (field.type) {
      case "select":
        return (
          <Select {...props.register(field.name, { required: field.required })} 
            disabled={field.disabled ? true : false}
          >
            {field.options?.map((option: any, index: any) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
        );

        case "select-search":
        return (
          <SelectSearch   options={field.options}
          {...props.register(field.name, { required: field.required })} 
          className="basic-single"
          isSearchable={true}
          name={field.name}
          onChange={(e) => {  
            props.onChange(field.name, e.value);
          }}
          defaultValue={props.searchedValues[field.name]}
          />
        );

        case "radio":
            return(
              <Radio fieldOptions={field}  type="radio" id={field.name} name={field.name} {...props.register(field.name, { required: field.required })} />
              );
        case "address-sap":
            return(
              <AddressSap register={props.register} actualValue={props.getValues(field.name)} errors={props.errors} field={field} setValue={props.setValue} />
              );
        case "file" :
             return(
              <File accept={field.acceptedFileTypes} nameFile={props.nameFile} name={field.name} id={field.name} 
              {...props.register(field.name, { validate: props.validateFile,
                onChange: (e: ChangeEvent<HTMLInputElement>) => {
                  props.register(field.name, { required: props.required });
                  props.onChange(field.name, e);
                    }
                  })}
               />
             )
      default:
        return (  <Input
          type={field.type || "text"}
          onWheel={field.type === "number" ? (e: any) =>  e.currentTarget.blur() : null}
          {...props.register(field.name, { required: field.required, ...field.validations })}
          disabled={field.disabled ? true : false}
        /> )
      }
  };
  return props.fields.map((field: any, index: any) => {
    if(field.hidden) return '';
    return <FieldGroup fieldType={field.type}  key={index}>
    {field.type !== "address-sap" && <Label>{field.label}{field.required && <span>*</span>}</Label>}
    {getInput(field)}
    {field.helperText && (
      <Text >{field.helperText}{field.helperTextUriText && <a href={field.helperTextUri} target="_blank" rel="noreferrer">{field.helperTextUriText}</a>}</Text>
    )}
    {props.errors[field.name] && (
        <small style={{ color: "red" }}>
          {props.errors[field.name].message || "Campo requerido"}
        </small>
    )}
  </FieldGroup>} );
  
};

export default FieldGenerator;
