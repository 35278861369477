import React from "react"
import Description from "../../atoms/description/description";
import ReviewSlider from "../review-slider/reviewSlider";
import { ReferenciaContenido } from "../../../interfaces/ReferenciaContenido";
import { normalizeTwoWords } from "../../../shared/utils/stringUtilities";
import "./videosPlayer.scss"

const VideosPlayer = (props: ReferenciaContenido) => {

   return (
      <div className="m-video-player">
         <div className="m-video-player__upper">
            <h2 className="m-video-player__upper__title">{props.tituloGeneral}</h2>
            {
               props.descripcion &&
                  <div className="m-video-player__upper__subtitle">
                     <Description
                        description={JSON.stringify(props.descripcion.json)}
                     />
                  </div>
            }
         </div>
         <div className="m-video-player__list">
            {
               props.referenciaPlantillaContenido &&
                  <ReviewSlider 
                     reviews={props.referenciaPlantillaContenido} 
                     gtmClass={`${normalizeTwoWords(props.nombreCategoria)}${normalizeTwoWords(props.tituloGeneral)}`}
                     slides={{ show: 2, rows: 1, s1560: 2 }}
                  />
            }
         </div>
      </div>
   )
}

export default VideosPlayer