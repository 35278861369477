import React from 'react'
import { ReferenciaContenido } from "../../../interfaces/ReferenciaContenido"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import './hoverImages.scss'

const HoverImages = (props:ReferenciaContenido) => {
    return (
        <div className='o-hover-images'>
            <h2>{props.tituloGeneral}</h2>
            <div className="o-hover-images__cards">
                {
                    props.referenciaPlantillaContenido.map((card, key) => (
                        <div className={`o-hover-images__cards__card ${key === 3 && props.referenciaPlantillaContenido.length === 5 ? 'fourth' : ''}`} key={key}>
                            <img src={card.imagenPrincipal[0].file.url} alt="" />
                            <div className="o-hover-images__cards__card__first">
                                <h3>{card.tituloBanner}</h3>
                                {card.tituloDescripcion &&
                                    <p>{card.tituloDescripcion}</p>
                                }
                            </div>
                            <p>{documentToReactComponents(card.descripcion.json)}</p>
                            {
                                card.redireccionBoton &&
                                <a href={card.redireccionBoton} target="_blank" rel="noreferrer">
                                    <span>{card.tituloBoton}</span>
                                    <span id="conoce-mas">Conoce más</span>
                                    <i className='icon-arrow-right'></i>
                                </a>
                            }
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default HoverImages
