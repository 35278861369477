import React, { useState, useEffect, useRef } from "react";
import { ReferenciaContenido } from "../../../interfaces/ReferenciaContenido";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import useBrowserMode from "../../../shared/hooks/useBrowserMode";
import useDirectionScroll from "../../../shared/hooks/useDirectionScroll";
import { normalizeTwoWords } from "../../../shared/utils/stringUtilities";
import "./historyLinkClaustro.scss";

const HistoryLinkClaustro = (props: ReferenciaContenido) => {

   const [indexTag, setIndexTag] = useState(0);
   const { isBrowser, window, document } = useBrowserMode();
   const [isScrollActive, setIsScrollActive] = useState<boolean>(false);
   const [upScroll, setUpScroll] = useState(false);
   const [hideTags, setHideTags] = useState("");
   const refComponent = useRef(null);
   const { lastDirection } = useDirectionScroll();
   const gtmTagsDinamic = `gtm${normalizeTwoWords(props.nombreCategoria)}${normalizeTwoWords(props.tituloGeneral)}`;
   const tags = props.referenciaPlantillaContenido.filter(
      value => value.tituloBoton
   ).map( value => ({ tag: value.tituloBoton, subtitle: value.tituloBanner }));

   let timerScroll: any;

   const onScrollAction = () => {
      window.clearTimeout(timerScroll);
      timerScroll = setTimeout(() => {
         if (upScroll) {
            window.scroll(0, window.pageYOffset - 180);
            setUpScroll(false);
         }
         const el: HTMLElement = refComponent.current || new HTMLElement();
         const boundElement = el.querySelector(".o-timeline-claustro__container__zone")?.getBoundingClientRect();
         let tagSelect = 0;
         const items = el.querySelectorAll(".o-timeline-claustro__container__content__card");
         for (let i = 0; i < items.length; i++) {
            if (items.item(i).getBoundingClientRect().top <= 190) {
               tagSelect = Math.floor(i);
            } else {
               setIndexTag(Math.floor(tagSelect/2));
               setIsScrollActive((boundElement || { top }).top < 160);
               return;
            }
         }
         setIndexTag(Math.floor(tagSelect/2));
         setIsScrollActive((boundElement || { top }).top < 160);
      }, 66);
   };

   useEffect(() => {
      isBrowser && document.addEventListener("scroll", onScrollAction);
      return () => {
         isBrowser && document.removeEventListener("scroll", onScrollAction);
      };
   }, [upScroll]);

   const goToDecade = (value: string) => {
      const el: HTMLElement = refComponent.current || new HTMLElement();
      const tag = el.querySelector(`.D-${value}`);
      setUpScroll(true);
      tag?.scrollIntoView();
   };

   function getOffset(el) {
      const rect = el.getBoundingClientRect();
      return {
        bottom: rect.bottom + window.scrollY
      };
    }

   const toggleVisibility = () => {
      let hisLink = document.getElementById("tags_zone-link")
      if (window.pageYOffset >= getOffset(hisLink).bottom - 500) {
         setHideTags("hide-tags-link")
      } else {
         setHideTags("")
      }
    }

   document.addEventListener('scroll', toggleVisibility);

   useEffect(() => {
      toggleVisibility();
   }, [window.pageYOffset])
   
   const Bold = (children:any) => (
      <p className="o-timeline-claustro__container__content__card__description--bold">{children}</p>
   );
   
   const Text = (children:any) => (
      <p className="o-timeline-claustro__container__content__card__description">{children}</p>
   )

   const List = (children:any) => (
      <li className="o-timeline-claustro__container__content__card__description__list">{children}</li>
   )

   const Image = (node: any) => {
      if (node.data.target.fields) {
         return (
            <img
               src={node.data.target.fields.file["en-US"] ?
                  node.data.target.fields.file["en-US"].url :
                  node.data.target.fields.file.url
               }
               alt={node.data.target.fields.file["en-US"] ?
                  node.data.target.fields.file["en-US"].description :
                  node.data.target.fields.file.description
               }
            />
         );
      } else {
         return <></>;
      }
   };

   const Code = (text: any) => {
      let embeddedScript: string = "";
      if (text.indexOf("<script") > -1 && text.indexOf("/script>") > -1) {
         embeddedScript = text.slice(
            text.indexOf("<script"),
            text.indexOf("/script>") + 8
         );
      }
      const createMarkup = () => {
         return {
            __html: text.replace(embeddedScript, ""),
         };
      };
      return (
         <object
            className="a-richtext-description a-richtext-description__content a-richtext-description__embed"
            dangerouslySetInnerHTML={createMarkup()}
         />
      );
   };

   const textOptions = {
      renderMark: {
         [MARKS.CODE]: Code,
         [MARKS.BOLD]: (text:any) => Bold(text)
      },
      renderNode: {
         [BLOCKS.LIST_ITEM]: (_node:any, children:any) => List(children),
         [BLOCKS.PARAGRAPH]: (_node:any, children:any) => Text(children),
         [BLOCKS.EMBEDDED_ASSET]: Image
      },
   };

   return (
      <div className="o-timeline-claustro" ref={refComponent}>
         <div className={`${hideTags} o-timeline-claustro__header ${isScrollActive ? 'header-fixed' : ''}`}>
            <h2 className="o-timeline-claustro__header__h2"><span>{props.tituloGeneral.split(';')[0].split(' ')[0]}</span><br/>{props.tituloGeneral.split(';')[0].split(' ')[1]}</h2>
            <h2 className="o-timeline-claustro__header__h2"><span>{props.tituloGeneral.split(';')[1].split(' ')[0]}</span><br/>{props.tituloGeneral.split(';')[1].split(' ')[1]}</h2>
         </div>
         { isScrollActive &&
            <div className="o-timeline-claustro__top"></div>
         }
         <div id="tags_zone-link" className="o-timeline-claustro__container">
            <div className="o-timeline-claustro__container__zone">
               <ul
                  className={`${hideTags} o-timeline-claustro__container__zone__tags${isScrollActive ? " o-timeline-claustro__container__zone__tags--freezer" : ""}`} >
                  {tags.map((tag, key) => (
                     <div key={key}>
                        <li
                           key={key}
                           className={`${gtmTagsDinamic}${normalizeTwoWords(tag.tag)} o-timeline-claustro__container__zone__tags__tag${key === indexTag ? " o-timeline-claustro__container__zone__tags__tag--active" : ""}`}
                           onClick={() => goToDecade((tag.tag).replace(/\s+/g, ''))}
                        >
                           {tag.tag}
                        </li>
                     </div>
                  ))}
               </ul>
               <div
                  className={`${hideTags} o-timeline-claustro__container__zone__box${
                     isScrollActive
                        ? " o-timeline-claustro__container__zone__box--freezer"
                        : ""
                  }${
                     lastDirection == 1
                        ? " o-timeline-claustro__container__zone__box--move"
                        : ""
                  }`}
               >
                  <button
                     disabled={indexTag <= 0}
                     className="o-timeline-claustro__container__zone__box__circle"
                     onClick={() => goToDecade((tags[indexTag - 1].tag).replace(/\s+/g, ''))}
                  />
                  {tags.map((tag, key) => (
                     <span
                        key={key}
                        className={`o-timeline-claustro__container__zone__box__item${
                           key === indexTag
                              ? " o-timeline-claustro__container__zone__box__item--active"
                              : ""
                        }`}
                     >
                        DESDE <b>{tag.tag}</b> - HASTA <b>{tag.tag === "2020" ? "HOY" : parseInt(tag.tag)+10}</b>
                     </span>
                  ))}
                  <button
                     disabled={indexTag + 1 >= tags.length}
                     className="o-timeline-claustro__container__zone__box__circle"
                     onClick={() => goToDecade((tags[indexTag + 1].tag).replace(/\s+/g, ''))}
                  />
               </div>
            </div>
            <div className="o-timeline-claustro__container__content">
               <div className="o-timeline-claustro__container__content__line"></div>
               {
                  props.referenciaPlantillaContenido.map((card, key) => (
                     <div key={key} className={`o-timeline-claustro__container__content__card ${card.tituloBoton ? `D-${card.tituloBoton}` : ''}`}>
                        { card.tituloBoton ?
                           <h4><span>{card.tituloBanner.split(' ')[0]}</span><br/>{card.tituloBanner.split(' ')[1]}</h4>
                           : <div className='o-timeline-claustro--space'></div>
                        }
                        <div className='o-timeline-claustro__container__content__card__title'>
                           {
                              card.tituloDescripcion === "claustro" ?
                              <span><span>{props.tituloGeneral.split(';')[0].split(' ')[0]}</span><br/>{props.tituloGeneral.split(';')[0].split(' ')[1]}</span>
                              : <span><span>{props.tituloGeneral.split(';')[1].split(' ')[0]}</span><br/>{props.tituloGeneral.split(';')[1].split(' ')[1]}</span>
                           }
                        </div>
                        {card.descripcion && 
                           (card.descripcion.json.content && documentToReactComponents(card.descripcion.json, textOptions))
                        }
                     </div>
                  ))
               }
            </div>
         </div>
      </div>
   );
};

export default HistoryLinkClaustro;
