import React, { useEffect, useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import FieldGenerator from "../../../shared/comfama-ui/components/form/field-generator/FieldGenerator";
import Layout from "../../../shared/comfama-ui/components/layout/Layout";
import Stepper from "../../../shared/comfama-ui/components/stepper";
import Text from "../../../shared/comfama-ui/components/text";
import { useSteps } from "../../../shared/comfama-ui/hooks";
import { verifyDocument } from "./services/verificador.service";
import genderSwitch from "./utils/genderSwitch";
import { numDate2String } from "./utils/numDate2String";
import Button from "../../atoms/button/button";
import "./FormAbrazos.scss";
import Loading from "../../atoms/loading/loading";
import Modal from "../../atoms/modal/modal";
import { verifyUserRegister } from "./services/verificadorregistro.service";

// definition of environment variables
const apiCommon = process.env.GATSBY_TRANSVERSAL_COMMON;
const SaveForm = process.env.GATSBY_TRANSVERSAL_FORMULARIOS;
const formName = process.env.GATSBY_FORMNAME_ABRAZOS;

type Fields = {
   idNumType: string;
   idNum: string;
   firstName: string;
   secondName: string;
   lastName: string;
   secondLastName: string;
   dateOfBirth: string;
   gender: string;
   institucion_educativa: string;
   departamento_institucion_id: string;
   municipio_id_institucion: string;
   otra_institucion_educativa: string;
   grado: string;
   email: string;
   address: string;
   departamento: string;
   city: string;
   barrio: string;
   cellphone: string;
   phone: string;
   acceptTerms: string;
};

const FormAbrazos = (props: any) => {
   const ALERTS_MESSAGES = {
      success: (id) => ({
         title: "¡Hecho!",
         text: "Tu solicitud ha sido creada con el id: " + id,
         icon: "icon-check",
      }),
      errorSave: {
         title: "¡Ups!",
         text: "Error al crear la solicitud, por favor intenta de nuevo más tarde",
         icon: "icon-exclamation-circle"
      },
      errorVerification: {
         title: "¡Ups!",
         text: "Error al verificar al afiliado, por favor revisa los datos",
         icon: "icon-exclamation-circle",
      },
      errorConditions: {
         title: "¡Ups!",
         text: "Lo sentimos, este beneficio no aplica para tu edad o rango salarial",
         icon: "icon-exclamation-circle",
      },
      errorUserExist: {
         title: "¡Ups!",
         text: "El usuario ya realizó el registro",
         icon: "icon-exclamation-circle",
      },
   };
   const [loading, setLoading] = useState(true);
   const [isSubmitting, setIsSubmitting] = useState(false);

   // obtain options from service
   let initialFields = useMemo<any>(() => {
      let process = [...props.fields];
      (async () => {
         let responseDocTypes = await fetch(
            `${apiCommon}/documenttype`
         );
         let docTypes = await responseDocTypes.json();
         let restrictedDocTypeIds = [2, 5, 8, 10];
         let docTypesFiltered = docTypes.data.filter(docType => !restrictedDocTypeIds.includes(docType.id))
         let docTypesDdl = docTypesFiltered.map((docType: any) => {
            return {
               id: docType.id,
               label: docType.description,
               value: docType.codeLegdos,
            };
         });

         let responseDepartments = await fetch(
            `${apiCommon}/departments`
         );
         let departments = await responseDepartments.json();
         let departmentsDdl = departments.data.map((department: any) => {
            return {
               id: department.id,
               label: department.name,
               value: department.id,
            };
         });

         let responseMunicipalities = await fetch(
            `${apiCommon}/municipalities/1`
         );
         let municipalities = await responseMunicipalities.json();
         let municipalitiesDdl = municipalities.data.map((municipality: any) => {
            return {
               id: municipality.id,
               label: municipality.name,
               value: municipality.id,
            };
         });

         process.forEach((section) => {
            section.fields?.forEach((field: any) => {
               if (field.name === "idNumType") {
                  field.options = [
                     {
                        id: "undefined",
                        label: "Seleccionar tipo de documento",
                        value: "",
                     },
                     ...docTypesDdl,
                  ];
               }
               if (field.name === "departamento_institucion_id") {
                  field.options = [
                     {
                        id: "undefined",
                        label: "Seleccionar departamento",
                        value: "",
                     },
                     ...departmentsDdl,
                  ];
               }
               if (
                  field.name === "municipio_id_institucion" ||
                  field.name === "institucion_educativa" ||
                  field.name === "otra_institucion_educativa"
               ) {
                  field.hidden = true;
               }
               if (field.name === "city") {
                  field.options = [
                     {
                        id: "undefined",
                        label: "Seleccionar municipio",
                        value: "",
                     },
                     ...municipalitiesDdl,
                  ];
               }
            });
         });
         setLoading(false);
      })();
      return process;
   }, []);
   const [fields, setFields] = useState<any>([]);
   const [showModal, setShowModal] = useState(false);
   const [modalMessage, setModalMessage] = useState(
      {} as { title: string; text: string; icon: string }
   );
   const { currentStep, nextStep, prevStep } = useSteps({
      stepsNumber: 5,
      // initialStep: 1
   });
   const {
      register,
      handleSubmit,
      watch,
      trigger,
      setValue,
      getValues,
      formState: { isValid, errors },
   } = useForm<Fields>();

   const watchInstitucion = watch("institucion_educativa");
   const watchDepartamentoInstitucion = watch("departamento_institucion_id");
   const watchMunicipioInstitucion = watch("municipio_id_institucion");

   const getInstituteName = (code) => {
      return code
         ? fields[2].fields
              .filter((field) => field.name === "institucion_educativa")[0]
              .options.find((inst) => inst.value == code)?.label
         : undefined;
   };

   const searchedValues = useMemo(() => {
      return {
         institucion_educativa: watchInstitucion
            ? {
                 id: watchInstitucion,
                 label: getInstituteName(watchInstitucion),
                 value: watchInstitucion,
              }
            : null,
      };
   }, [watchInstitucion])

   const onSubmit: SubmitHandler<Fields> = (data) => {
      if (currentStep !== 4) return; // cancel submit if isn't last step
      setIsSubmitting(true);
      let institutionName = getInstituteName(watchInstitucion);
      let payload = {
         formName: formName,
         requireValidation: true,
         responses: [
            {
               answer: data.city,
               idQuestion: "city",
            },
            {
               answer: data.barrio,
               idQuestion: "barrio",
            },
            {
               answer: data.phone,
               idQuestion: "telefono_ws",
            },
            {
               answer: data.grado,
               idQuestion: "grado",
            },
            {
               answer: institutionName === "Otra (Si no encontraste tu institución)" ? data.otra_institucion_educativa : institutionName,
               idQuestion: "institucion_educativa",
            },
            {
               answer: data.otra_institucion_educativa
                  ? ""
                  : data.institucion_educativa,
               idQuestion: "codigo_DANE",
            },
            {
               answer: data.departamento_institucion_id,
               idQuestion: "departamento_institucion",
            },
            {
               answer: data.municipio_id_institucion,
               idQuestion: "municipio_institucion",
            },
         ],
         user: {
            address: data.address,
            city: data.city,
            idNum: data.idNum,
            idNumType: data.idNumType,
            email: data.email,
            firstName: (data.firstName + " " + (data.secondName || "")).trim(),
            lastName: (
               data.lastName +
               " " +
               (data.secondLastName || "")
            ).trim(),
            cellphone: data.cellphone,
            phone: data.phone,
            gender: data.gender,
            dateOfBirth: data.dateOfBirth,
         },
      };

      fetch(
         `${SaveForm}/save`,
         {
            method: "POST",
            headers: {
               "content-type": "application/json",
            },
            body: JSON.stringify(payload),
         }
      )
         .then((res) => res.json())
         .then((data) => {
            if (data.status === 200) {
               setModalMessage(ALERTS_MESSAGES.success(data.data));
               setShowModal(true);
               return;
            }
            setModalMessage(ALERTS_MESSAGES.errorSave);
            setShowModal(true);
         })
         .catch((err: any) => {
            setIsSubmitting(false);
            setModalMessage(ALERTS_MESSAGES.errorSave);
            setShowModal(true);
         })
   };

   const validNextStep = (fields: any[]) => {
      trigger(fields).then((isValid: boolean) => {
         if (isValid) nextStep();
      });
   };

   const verifyUser = async (fields: any) => {
      setLoading(true);
      // check fields
      let isValid = await trigger(fields);
      if (!isValid) {
         setLoading(false);
         return;
      }

      // get user data
      let userData = await verifyDocument({
         num: getValues("idNum").trim(),
         type: getValues("idNumType").trim(),
      });

      // validation if the user has already completed the form
      let verifyRegister = await verifyUserRegister({
         document: userData.idNum,
         documentType: userData.idNumType,
         formName: "form_abrazos_v2",
      });
      
      if(verifyRegister.data) {
         setModalMessage(ALERTS_MESSAGES.errorUserExist);
         setShowModal(true);
         setLoading(false);
         return;
      }

      // finish if user doesn't exists
      if (!userData) {
         setModalMessage(ALERTS_MESSAGES.errorVerification);
         setShowModal(true);
         setLoading(false);
         return;
      }
      // // check user conditions
      if (
         userData.age >= 6 &&
         userData.age <= 12 &&
         (userData.tarifa === "A" || userData.tarifa === "B")
      ) {
         setValue("firstName", userData.firstName);
         setValue("secondName", userData.secondName);
         setValue("lastName", userData.lastName);
         setValue("secondLastName", userData.secondLastName);
         setValue("gender", genderSwitch(userData.gender));
         setValue("dateOfBirth", numDate2String(userData.dateOfBirth));
         nextStep(); // continue if the user meets the conditions
      } else {
         setModalMessage(ALERTS_MESSAGES.errorConditions);
         setShowModal(true);
      }
      setLoading(false);
   };

   const getCodeSap = async (departmentId, municipalityId) => {
      let responseMunicipalities = await fetch(
         `${apiCommon}/municipalities/${departmentId}`
      );
      let municipalities = await responseMunicipalities.json();
      const municipalityCodeSap = municipalities.data.filter(municipality => municipality.id == municipalityId)[0]?.codeSap;
      return municipalityCodeSap;
   }

   const getInstitutionsDdlByMunicipality = async (municipalityCodeSap) => {
      if(!municipalityCodeSap) return [];
      let responseInstitutions = await fetch(
         `${apiCommon}/educational-institution?municipalityId=${municipalityCodeSap}`
      );
      const institutionsList = await responseInstitutions.json();
      const institutionsDdl = [...institutionsList.data].map((institution: any) => {
         return {
            id: institution.id,
            label: institution.name,
            value: institution.code,
         };
      })
      return institutionsDdl;
   }

   useEffect(() => {
      setFields(initialFields);
   }, [initialFields]);

   useEffect(() => {
      if(!fields.length) return;
      setValue("municipio_id_institucion", "");
      // if the user doesn't selected a department
      if(watchDepartamentoInstitucion === "") {
         let process = [...fields];
         process.forEach((section) => {
            if (!section.fields) return section;
            let fields = [...section?.fields];
            fields.forEach((field: any) => {
               if(field.name === "municipio_id_institucion") {
                  field.hidden = false;
                  field.options = [];
               }
            })
            section.fields = fields;
         });
         setFields(process);      
         return;
      }
      // if the user selected a department
      (async () => {
         setLoading(true);
         let responseMunicipalities = await fetch(
            `${apiCommon}/municipalities/${watchDepartamentoInstitucion}`
         );
         let municipalities = await responseMunicipalities.json();
         let municipalitiesDdl = municipalities.data.map((municipality: any) => {
            return {
               id: municipality.id,
               label: municipality.name,
               value: municipality.id,
            };
         });

         let process = [...fields];
         process.forEach((section) => {
            if (!section.fields) return section;
            let fields = [...section?.fields];
            fields.forEach((field: any) => {
               if(field.name === "municipio_id_institucion") {
                  field.hidden = false;
                  field.options = [
                     {
                        id: "undefined",
                        label: "Seleccionar municipio",
                        value: "",
                     },
                     ...municipalitiesDdl
                  ]
               }
            })
            section.fields = fields;
         });
         setFields(process);
         setLoading(false);
      })()
   }, [watchDepartamentoInstitucion]);

   useEffect(() => {
      if(!fields.length) return;
      setValue("institucion_educativa", "");
      // if the user doesn't selected a municipality
      if(watchMunicipioInstitucion === "") {
         let process = [...fields];
         process.forEach((section) => {
            if (!section.fields) return section;
            let fields = [...section?.fields];
            fields.forEach((field: any) => {
               if(field.name === "institucion_educativa") {
                  field.hidden = true;
                  field.options = []
               }
            })
            section.fields = fields;
         });
         setFields(process);      
         return;
      }
      // if the user selected a municipality
      (async () => {
         setLoading(true);
         const municipalityCodeSap = await getCodeSap(watchDepartamentoInstitucion, watchMunicipioInstitucion);
         const institutionsDdl = await getInstitutionsDdlByMunicipality(municipalityCodeSap);
         let process = [...fields];
         process.forEach((section) => {
            if (!section.fields) return section;
            let fields = [...section?.fields];
            fields.forEach((field: any) => {
               if (field.name === "institucion_educativa") {
                  field.hidden = false;
                  field.options = [
                     ...institutionsDdl,
                     {
                        id: 0,
                        label: "Otra (Si no encontraste tu institución)",
                        value: "other"
                      }
                  ];
               }
            })
            section.fields = fields;
         });
         
         if(institutionsDdl.length) {
            setValue("otra_institucion_educativa", "");
         } else {
            setValue("institucion_educativa", "other");
         }
         setFields(process);
         setLoading(false);
      })()
   }, [watchMunicipioInstitucion]);

   useEffect(() => {
      if(!fields.length) return;
      setValue("otra_institucion_educativa", "");
       // if the user doesn't selected a institution
       if(watchInstitucion === "") {
         let process = [...fields];
         process.forEach((section) => {
            if (!section.fields) return section;
            let fields = [...section?.fields];
            fields.forEach((field: any) => {
               if(field.name === "otra_institucion_educativa") {
                  field.hidden = true;
               }
            })
            section.fields = fields;
         });
         setFields(process);      
         return;
      }
      // if the user selected a institution
      let process = [...fields];
      process.forEach((section) => {
         if (!section.fields) return section;
         let fields = [...section?.fields];
         fields.forEach((field: any) => {
            if(field.name === "otra_institucion_educativa"){
               if (watchInstitucion !== "other") {
                 field.hidden = true;
               } else {
                  field.hidden = false;
               }
            }
         })
        
         section.fields = fields;
      });
      setFields(process);
      // setValue("otra_institucion_educativa", "");
   }, [watchInstitucion]);

   if (loading)
      return (
         <React.Fragment>
            <Loading />
         </React.Fragment>
      );

   if (!fields.length) return <React.Fragment>Sin campos</React.Fragment>;

   return (
      <>
         <Modal
            open={showModal}
            className="m-share-modal"
         >
            <div className="o-form-abrazos-success-zip">
               <div className="o-form-abrazos-success-zip__content">
                  <i className={`icon__check ${modalMessage.icon}`} />
                  <h3 className="o-form-abrazos-success-zip__content__message">
                     {modalMessage.title}
                  </h3>
                  <p className="o-form-abrazos-success-zip__content__info">
                     {modalMessage.text}
                  </p>
                  <Button
                     type="button"
                     isDisabled={false}
                     classname={"secondary "}
                     text="Aceptar"
                     onClickAction={() => {
                        setShowModal(false);
                        if (isSubmitting) {
                           window.location.reload();
                        } 
                     }}
                  />
               </div>
            </div>
         </Modal>
      <div className="o-form-abrazos">
         <form onSubmit={handleSubmit(onSubmit)} className="o-form-abrazos__form">
            <Stepper current={currentStep}>
               {fields.slice(0, 1)?.map((group: any) => {
                  return (
                     <Stepper.Step key={group.id}>
                        <Text as="h3">{group.title}</Text>
                        <article className="o-form-abrazos__form__fieldset">
                           <FieldGenerator
                              fields={group.fields}
                              register={register}
                              errors={errors}
                              />
                        </article>
                        <div
                           style={{
                              display: "flex",
                              justifyContent: "end",
                           }}
                           >
                           <Button
                           type="button"
                           isDisabled={false}
                           classname={"secondary "}
                           text="Verificar"
                           onClickAction={() => verifyUser(
                              group.fields?.map(
                                 (field: any) => field.name
                                 )
                           )}
                           />
                        </div>
                     </Stepper.Step>
                  );
               })}
               {fields.slice(1, 4)?.map((group: any) => {
                  return (
                     <Stepper.Step key={group.id}>
                        <Text as="h3">{group.title}</Text>
                        <article className="o-form-abrazos__form__fieldset">
                           <FieldGenerator
                              onChange={(name, value) => {
                                 setValue(name, value);
                              }}
                              fields={group.fields}
                              register={register}
                              errors={errors}
                              searchedValues={searchedValues}
                           />
                        </article>
                        <div
                           style={{
                              display: "flex",
                              justifyContent: "space-between",
                           }}
                        >
                           <button type="button" onClick={prevStep}>
                              Anterior
                           </button>
                           <Button
                              type="button"
                              isDisabled={false}
                              classname={"secondary "}
                              text="Siguiente"
                              onClickAction={() =>
                                 validNextStep(
                                    group.fields?.map(
                                       (field: any) => field.name
                                    )
                                 )
                              }
                           />
                        </div>
                     </Stepper.Step>
                  );
               })}
               <Stepper.Step>
                  <Text as="h3" className="o-form-abrazos__stepTitle">Términos y condiciones</Text>
                  <Layout columns="1fr" gap="1rem">
                        {props.conditionsComponent()}
                     <label className="o-form-abrazos__form__terms">
                        Aceptar términos y condiciones   
                        <input type="checkbox" name="accept" required />
                     </label>
                  </Layout>
                  <div
                     style={{
                        display: "flex",
                        justifyContent: "space-between",
                     }}
                     >
                     <button type="button" onClick={prevStep}>
                        Anterior
                     </button>
                     <button type="submit">Enviar</button>
                  </div>
               </Stepper.Step>
            </Stepper>
         </form>
      </div>
   </>
   );
};

export default FormAbrazos;