import React from "react"
import './subsection-info.scss'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'


const SubsectionInfo = (props: SubsectionInfoInterface) => {
   const {
      title,
      description,
      image,
   } = props;

   return (
      <div className='m-subsectionInfo__details-info'>
         { image &&
            <div className='m-subsectionInfo__details-img'>
               <img loading="lazy" src={image.file.url} alt={image.title} />
            </div>
         }
         <h3 className='m-subsectionInfo__details-title'>
            {title}
         </h3>
         <div className='m-subsectionInfo__details-desc'>
            {description.content && documentToReactComponents(description)}
         </div>
      </div>
   )
}
interface SubsectionInfoInterface {
   title: string,
   description: any,
   image: ImageInterface,
}
interface ImageInterface {
   title: string;
   file: {
      url: string
   };
}

export default SubsectionInfo
