import React, {useState} from "react";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { normalizeTwoWords, OptimizeImage } from "../../../shared/utils/stringUtilities";
import { ReferenciaContenido } from '../../../interfaces/ReferenciaContenido';
import Img from "gatsby-image";
import './tabsSection.scss'

const TabsSection = (props: ReferenciaContenido) => {
   
	const {
		tituloGeneral,
		referenciaTabsContenido,
		nombreCategoria
	} = props;

	const isHttp = (url?:string ) => {
		return url?.startsWith('http');
	}

	const openLink = (url) => {
		if (isHttp(url)) {
			window.open(url, '_blank') 
		} else {
			window.open(url, '_self') 
		}
	}

	const [activeItem, setActiveItem] = useState(0);

	return (
		<div className='m-tabs-sec'>
			<h2 className='m-tabs-sec__title title-secondary'> 
				{ tituloGeneral } 
			</h2>
			<div className='m-tabs-sec__cont'>
				<div className='m-tabs-sec__tabs'>
					<ul>
						{	referenciaTabsContenido && referenciaTabsContenido.map((tab, i)=>(
							<li key={`tab${i}`} className={`gtm${normalizeTwoWords(nombreCategoria)}${normalizeTwoWords(tituloGeneral)}${normalizeTwoWords(tab.nombreTab)}`}> 
								<button className= {`m-tabs-sec__tabs-btn ${activeItem===i?'m-tabs-sec__tabs-btn--active':''} gtm${normalizeTwoWords(nombreCategoria)}${normalizeTwoWords(tituloGeneral)}${normalizeTwoWords(tab.nombreTab)}`}
									onClick={() => {
										setActiveItem(i)
									}}>
									{tab.nombreTab}
								</button> 
							</li>
						))}
					</ul>
				</div>
				<div className='m-tabs-sec__details'>
					<div className='m-tabs-sec__details-info'>
						<p className='m-tabs-sec__details-title'> 
							{ referenciaTabsContenido[activeItem].referenciaCursosDestacados[0].tituloPrincipal } 
						</p>
						<ul className='m-tabs-sec__details-liltags'>
							{ referenciaTabsContenido[activeItem].referenciaCursosDestacados[0].listaTags &&
								referenciaTabsContenido[activeItem].referenciaCursosDestacados[0].listaTags.map((item, i)=>(
									<li key={`etiq${i}`}>
										<p>{item}</p>
									</li>
								))
							}
						</ul>
						<div className='m-tabs-sec__details-desc'>
							{ referenciaTabsContenido[activeItem].referenciaCursosDestacados[0].descripcion.json.content && documentToReactComponents(referenciaTabsContenido[activeItem].referenciaCursosDestacados[0].descripcion.json) }
						</div>
						<button 
							onClick={() => openLink(referenciaTabsContenido[activeItem].referenciaCursosDestacados[0].redireccionBoton) } 
							className={`m-tabs-sec__details-desc__btn gtm${normalizeTwoWords(nombreCategoria)}${normalizeTwoWords(tituloGeneral)}${normalizeTwoWords(referenciaTabsContenido[activeItem].nombreTab)}ConoceMas`}
						>{ referenciaTabsContenido[activeItem].referenciaCursosDestacados[0].textoBoton }</button>
					</div>
					<div className='m-tabs-sec__details-img hide-on-mobile'>
						{referenciaTabsContenido[activeItem].referenciaCursosDestacados[0].imagenPrincipal.fluid ?
							<Img  fluid= {referenciaTabsContenido[activeItem].referenciaCursosDestacados[0].imagenPrincipal && 
									referenciaTabsContenido[activeItem].referenciaCursosDestacados[0].imagenPrincipal.fluid }
								alt={ referenciaTabsContenido[activeItem].referenciaCursosDestacados[0].imagenPrincipal.title || "imagen tab" }
								className='m-tabs-sec__details-img__Img'
							/>
						:
							<img
								loading="lazy"
								src= {referenciaTabsContenido[activeItem].referenciaCursosDestacados[0].imagenPrincipal && 
									OptimizeImage(referenciaTabsContenido[activeItem].referenciaCursosDestacados[0].imagenPrincipal.file.url,
												referenciaTabsContenido[activeItem].referenciaCursosDestacados[0].imagenPrincipal.file.contentType,
												800)
								}
								alt={ referenciaTabsContenido[activeItem].referenciaCursosDestacados[0].imagenPrincipal.title || "imagen tab" }
							/>
						}
					</div>
				</div>
			</div>
		</div>
	)
}

export default TabsSection
