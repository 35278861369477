import React from "react"
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { OptimizeImage } from "../../../shared/utils/stringUtilities";
import "./chapterParallax.scss"


const ChapterParallax = (props: ChapterParallaxInterface) => {

    const Bold = (children:any) => (
		<h4 className="o-chapterParallax__header__text__description__text--bold">{children}</h4>
	);
	
	const Text = (children:any) => (
		<p className="o-chapterParallax__header__text__description__text">{children}</p>
	)

	const List = (children:any) => (
		<li className="o-chapterParallax__header__text__description__text__list">{children}</li>
	)
	
	const textOptions = {
		renderMark: {
			[MARKS.BOLD]: (text:any) => Bold(text)
		},
		renderNode: {
			[BLOCKS.LIST_ITEM]: (_node:any, children:any) => List(children),
			[BLOCKS.PARAGRAPH]: (_node:any, children:any) => Text(children)
		},
	};

	return (
		<div className='o-chapterParallax'>
            <div className='o-chapterParallax__header'>
				<div className='o-chapterParallax__header__text'>
					{
						props.chapter.descripcion && 
							<span className='o-chapterParallax__header__text__span'>
								{props.chapter.tituloBanner}
							</span>
					}
					{
						props.chapter.descripcion && 
							<h1 className='o-chapterParallax__header__text__title'>
								{props.chapter.tituloDescripcion}
							</h1>
					}
                    {
                        props.chapter.descripcion && 
                            <div className='o-chapterParallax__header__text__description'>
                                {documentToReactComponents(props.chapter.descripcion.json, textOptions)}
                            </div>
                    }
				</div>
			</div>
			{
                <div className="o-chapterParallax__figure">
                    <img 
						loading="lazy" 
						className="o-chapterParallax__figure__img--desktop" 
						src={OptimizeImage(props.chapter.imagenPrincipal[0].file.url, props.chapter.imagenPrincipal[0].file.contentType, 990)}
					/>
                    <img 
						loading="lazy" 
						className="o-chapterParallax__figure__img--mobile"
						src={
							props.chapter.imagenPrincipal[1] ?
								OptimizeImage(props.chapter.imagenPrincipal[1].file.url, props.chapter.imagenPrincipal[1].file.contentType, 500)
							:
								OptimizeImage(props.chapter.imagenPrincipal[0].file.url, props.chapter.imagenPrincipal[0].file.contentType, 500)
						}
					/>
                </div>
			}
		</div>
)}

export default ChapterParallax

interface ChapterParallaxInterface {
	chapter: any;
}
