import React from "react";
import ddlAddressSap from "../../utils/ddlAddress.json";


//create a method that return some options to a select in another component


 const getVia = (): any[] => {
    return ddlAddressSap.via
}

const getLetter = (): any[] => {
    return ddlAddressSap.letter;
}

const getCardinalPoint = () : any => {
   return ddlAddressSap.cardinalPoint
};


export default { getVia, getLetter, getCardinalPoint };
