import React, {useEffect, useState} from "react";
import { ReferenciaMapa } from "../../../interfaces/ReferenciaMapa";
import { ReferenciaContenido } from "../../../interfaces/ReferenciaContenido";
import GoUpButton from "../../atoms/go-up-button/goUpButton";
import MapOrListButton from "../../atoms/map-or-list-button/mapOrListButton";
import FilterRegiones from "../../molecules/filter-regiones/filterRegiones";
import MapComponent from "../../molecules/map-component/mapComponent";
import SedeCard from "../../molecules/sede-card/sedeCard";
import './maps.scss';

const Maps = (props: ReferenciaContenido) => {

  if (!props.referenciaMapa) {
    return <div>Cargando sedes...</div>
  }
  
  const [toRender, setToRender] = useState("lista");
  const [region, setRegion] = useState('Valle de Aburrá');
  const [ver, setVer] = useState('todas');

  const averageLat = () => {
    let sumLat: number = 0;
    let counter: number = 0;
    for (let sede of sortedSedes) {
      sumLat += sede.location.lat;
      counter += 1;
    }
    return sumLat / counter;
  };

  const averageLon = () => {
    let sumLon: number = 0;
    let counter: number = 0;
    for (let sede of sortedSedes) {
      sumLon += sede.location.lon;
      counter += 1;
    }
    return sumLon / counter;
  };

  const filterSedes = (sedes: any[]) => {
    let tempSedes: any[] = [...sedes];
    tempSedes = tempSedes.filter((sede: any) => sede.region[0] === region);
    return(tempSedes);
  };
  const [sortedSedes, setSortedSedes] = useState(filterSedes(props.referenciaMapa));
  const [lengthSedes, setLengthSedes] = useState(filterSedes(props.referenciaMapa).length);
  const [limit, setLimit] = useState(6);

  const center: {} = { lat: averageLat(), lng: averageLon() };

  useEffect(() => {
    setSortedSedes(filterSedes(props.referenciaMapa));
    setLengthSedes(filterSedes(props.referenciaMapa).length);
    setVer('todas');
    setLimit(6);
  }, [region])

  const sedes: string[] = ['Valle de Aburrá',  'Oriente', 'Norte', 'Urabá', 'Nordeste', 'Suroeste', 'Occidente', 'Bajo Cauca', 'Magdalena Medio']; //BORRAR

  const handleVerClick = async () => {
    if (ver === "todas") {
      setVer("menos");
      setLimit(lengthSedes);
    } else {
      setLimit(6);
      setVer("todas");
    }
  }
    return (
      <div className="o-main__container">
        <section className="o-main__container__titles">
          <h2 className="o-main__container__titles__subtitle">{props.tituloBoton}</h2>
        </section>
        <section className="o-main__container__filter__and__map">
          <div className="o-main__container__filter__and__map__filter">
            <FilterRegiones sedes={sedes} regionChanger={setRegion}/>
          </div>
          <div className="o-main__container__filter__and__map__map">
            <MapOrListButton toRender={toRender} setToRender={setToRender}/>
          </div>
        </section>
        {toRender === "lista" ? (
          <>
            <div className="o-main__container__sedes">
              {sortedSedes.slice(0, limit).map((value: ReferenciaMapa, index: number) => {
                return (
                  <div className="o-main__container__sedes__card" key={index}>
                    <SedeCard sede={value} />
                  </div>
                );
              })}
            </div>
            {sortedSedes.length > 6 &&
              <div className={`o-main__container__all --${ver}`} onClick={handleVerClick}>
                <p className="o-main__container__all__text">Ver {ver}</p>
                <i className="icon-chevron-down1"></i>
              </div>
            }
          </>
        ) : (
          <div className="o-main__container__map">
            <MapComponent sedes={sortedSedes} center={center} geolocationLat={0} geolocationLon={0} zoom={10}/>
          </div>
        )}
        {ver === "menos" && <GoUpButton/>}
      </div>
     );
};

export default Maps;
