import React, { Fragment } from 'react';
//import { get } from 'lodash';

import './videoLink.scss';
import Button from '../../atoms/button/button';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { normalizeTwoWords, findTarget, findRel, OptimizeImage } from "../../../shared/utils/stringUtilities"
import { ReferenciaContenido } from '../../../interfaces/ReferenciaContenido';
import Img from "gatsby-image";

const VideoLink = (props: ReferenciaContenido) => {

   const { nombreCategoria } = props;

   return (
      <div className='m-video-link'>
         { props.referenciaPlantillaContenido.map( (rpc, key) => (
            <Fragment key={key} >
               <div className='m-video-link__fondo'>
                  {rpc.imagenPrincipal && 
                     rpc.imagenPrincipal[0].fluid ?
                     <Img  className='m-video-link__cover'
                           fluid= {rpc.imagenPrincipal[0].fluid }
                           alt={ rpc.imagenPrincipal[0].title || "imagen video link"}
                     />
                     :
                     <img  className='m-video-link__cover'
                           loading="lazy"
                           alt={ rpc.imagenPrincipal[0].title || "imagen video link"}
                           src= {OptimizeImage(rpc.imagenPrincipal[0].file.url, rpc.imagenPrincipal[0].file.contentType, 800) }
                     />
                  }
               </div>

               <article className='m-video-link__contenido'>
                  <div className='m-video-link__titulo'>
                     <h2 className='m-video-link__titulo__text' >{rpc.tituloBanner}</h2>
                  </div>
                  { rpc.descripcion &&
                     <div className='m-video-link__descripcion'>
                        {rpc.descripcion.json.content && documentToReactComponents(rpc.descripcion.json)}
                     </div>
                  }
                  {rpc.tituloDescripcion && 
                     <div className='m-video-link__link'>
                        <a className={`m-video-link__link__a gtm${normalizeTwoWords(nombreCategoria)}${normalizeTwoWords(rpc.tituloBanner)}MasVideos`} 
                           href={rpc.tipoTitulo}
                           target={ findTarget(rpc.tipoTitulo) }
                           rel={findRel(rpc.tipoTitulo)}
                        >
                           <i className={`icon icon-play-circle1 m-video-link__link__icon gtm${normalizeTwoWords(nombreCategoria)}${normalizeTwoWords(rpc.tituloBanner)}MasVideos`}></i>
                           <span className={`gtm${normalizeTwoWords(nombreCategoria)}${normalizeTwoWords(rpc.tituloBanner)}MasVideos`}>
                              {rpc.tituloDescripcion}
                           </span>
                        </a>
                     </div>
                  }
                  <div className='m-video-link__button'>
                     <Button
                        id='buttonVideoLink'
                        type='link'
                        href={rpc.redireccionBoton}
                        classname={'secondary '}
                        text={rpc.tituloBoton}
                        isDisabled={false}
                        gtmMark= {`gtm${normalizeTwoWords(nombreCategoria)}${normalizeTwoWords(rpc.tituloBanner)}Btn`}
                     />
                  </div>
               </article>
            </Fragment>
         ) )}
      </div>
   );
};

export default VideoLink;
