import React from "react";
import "./documentCard.scss";

const DocumentCard = (props: DocumentCardInterface) => {

  const { document, key } = props;
  const sharedUrl = document.documentos ? `https:${document.documentos.file.url}` : document.redireccionBoton;

  return (
    <div key={key} className="a-document-card">
      <div className="a-document-card__cover">
        <img src={document.imagenPrincipal[0].file.url} alt={document.tituloBanner} />
      </div>
      <div className="a-document-card__share">
        <a className="a-document-card__share__download"
            href={document.documentos.file.url} rel="noreferrer" target="_blank">
            {document.tituloBoton} <i className="icon-download"></i>
        </a>
        <a className="a-document-card__share__mail"
          href={`mailto:?subject=Comfama&body=Este documento puede ser de tu interés: ${sharedUrl}`}
          target="_blank"
          rel="noreferrer">
          <img src="https://images.ctfassets.net/gkhyeghj07ak/2dMvSw6fhnosr94umiAbf/e8c3714680f8d6c63c96c424830b58b1/envelope-solid__1_.svg" alt="mail"/>
        </a>
      </div>
      <div className="a-document-card__title">
        <h3><b>{document.tituloBanner}</b></h3>
      </div>
  </div>
  );
};

interface DocumentCardInterface {
  document: any;
  key: number;
}

export default DocumentCard;
