export const numDate2String = (date: any) => {
  if (!date) return "";
  var dateString = date;
  var year = dateString.substring(0, 4);
  var month = dateString.substring(4, 6);
  var day = dateString.substring(6, 8);
  var newDate = new Date(year, month - 1, day);
  let result = newDate.toISOString().slice(0, 10);
  return result;
};
