import React from "react";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { normalizeTwoWords } from "../../../shared/utils/stringUtilities";
import { ReferenciaContenido } from '../../../interfaces/ReferenciaContenido';
import Button from '../../atoms/button/button';
import CardOffice from "../../atoms/card-office/cardOffice";
import './officesList.scss';

const OfficesList = (props: ReferenciaContenido) => {

	const { 
		tituloGeneral,
		referenciaPlantillaContenido,
      nombreCategoria,
      tituloBoton,
      redireccionBoton
   } = props;

	return (
		<div className='m-offices-list'>
			<h2 className='m-offices-list__title title-secondary'> {tituloGeneral} </h2>
			<div className='m-offices-list__cont'>
				{ referenciaPlantillaContenido && referenciaPlantillaContenido.map((card, i)=>(		
					<CardOffice
						key={ `card${i}` }
						title = { card.tituloBanner }
						info = { card.descripcion.json.content && documentToReactComponents(card.descripcion.json) }
						locationLink = { card.redireccionBoton || '/' }
						gtmMark= {`gtm${normalizeTwoWords(nombreCategoria)}${normalizeTwoWords(tituloGeneral)}${normalizeTwoWords(card.tituloBanner)}`}
					/>		
				))}
			</div>
         <div className='m-offices-list__btn'>
            <Button 
               id='buttonSeeMoreOffices'
               type= {"link"} 
               classname= {`primary`}
               text= { tituloBoton }
               href= { redireccionBoton } 
               isDisabled= {false}
               gtmMark= { `gtm${normalizeTwoWords(nombreCategoria)}${normalizeTwoWords(tituloGeneral)}VerMasSedes` }
            />
         </div>
					
		</div>
	)
}

export default OfficesList
