import React from "react";
import './circularSlider.scss';
import { ReferenciaContenido } from "../../../interfaces/ReferenciaContenido";
import { OptimizeImage } from "../../../shared/utils/stringUtilities";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const CircularSlider = (props: ReferenciaContenido) => {

    if (!props.referenciaAutor) {
      return <div>Cargando Invitados...</div>
    }

    var settings: any = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2500,
        pauseOnHover: true,
        adaptiveHeight: true,
        cssEase: 'linear',
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
                dots: false
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                dots: false
              }
            }
          ]
    };

    return (
        <div className={`o-circular__slider ${props.tituloBoton === 'light' ? '--light' : ''}`}>
            <h2 className="o-circular__slider__title">{props.tituloGeneral}</h2>
            <Slider {...settings}>
                {props.referenciaAutor.map((autor, index) => {
                return (
                    <React.Fragment key={index}>
                        <div className="o-circular__slider__container">
                            <div className="o-circular__slider__container__card" >
                                <div className="o-circular__slider__container__card__imagen">
                                  <img 
                                    loading="lazy"
                                    className="o-circular__slider__container__card__imagen__img"
                                    src={autor.imagen &&
                                      OptimizeImage(autor.imagen.file.url, autor.imagen.file.contentType, 280)
                                    }
                                    alt={autor.nombreAutor}
                                  />
                                </div>
                                <div className="o-circular__slider__container__card__description">
                                  <div className="o-circular__slider__container__card__description__text">
                                    <h3>{autor.nombreAutor}</h3>
                                    <p>{autor.profesion1 ? autor.profesion1 : ""}<b>{autor.profesion2 && ` | ${autor.profesion2}`}</b>{autor.profesion3 && ` | ${autor.profesion3}`}</p>
                                  </div>
                                    <div className="o-circular__slider__container__card__description__social">
                                        {autor.twitter && <a href={`https://www.twitter.com/${autor.twitter}`} target="_blank" rel="noreferrer"><i className="icon-twitter1"></i></a>}
                                        {autor.instagram && <a href={`https://www.instagram.com/${autor.instagram}`} target="_blank" rel="noreferrer"><i className="icon-instagram1"></i></a>}
                                        {autor.facebook && <a href={`https://www.facebook.com/${autor.facebook}`} target="_blank" rel="noreferrer"><i className="icon-facebook1"></i></a>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                );
                })}
            </Slider>
        </div>
    );
}
export default CircularSlider;