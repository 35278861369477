import React from 'react';
import './axis.scss';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { ReferenciaContenido } from '../../../interfaces/ReferenciaContenido';
import Button from '../../atoms/button/button';
import { normalizeTwoWords, OptimizeImage } from "../../../shared/utils/stringUtilities";

const Axis = (props:ReferenciaContenido) => {
   
   const options = {
      renderMark: {
         [MARKS.BOLD]: (text:any) => Bold(text)
      },
      renderNode: {
         [BLOCKS.PARAGRAPH]: (_node:any, children:any) => Text(children)
      },
   };

   const Bold = (children:any) => (
      <b className="o-axis__body__box__text--bold">{children}</b>
   );
   
   const Text = (children:any) => (
      <p className="o-axis__body__box__text">{children}</p>
   )

   const directionClassBody = (dir: string) => {
      const _class = 'o-axis__body--';
      switch (dir) {
         case 'Left':
            return `${_class}left`;
         case 'Right':
            return `${_class}right`;
         default:
            return _class;
      }
   }

   return (
      <section className='o-axis' >
         <h2 className='o-axis__title' >{props.tituloGeneral}</h2>
         {props.referenciaPlantillaContenido.map( (value, key) => (
            <div key={key} className={`o-axis__body ${directionClassBody(value.posicionImagen)}`} >
               <figure className='o-axis__body__cover' >
                  <img
                     loading="lazy"
                     className='o-axis__body__cover__img'
                     src={value.imagenPrincipal && 
                        OptimizeImage(value.imagenPrincipal[0].file.url,value.imagenPrincipal[0].file.contentType, 768)
                     }
                     alt={value.imagenPrincipal[0].title}
                  />
               </figure>
               <article className='o-axis__body__box' >
                  <h3 className='o-axis__body__box__title' >{value.tituloBanner}</h3>
                  {value.descripcion.json.content && documentToReactComponents(value.descripcion.json, options)}
                  { value.tituloBoton &&
                     <Button 
                        type={'link'}
                        isDisabled={false}
                        text={value.tituloBoton}
                        href={value.redireccionBoton}
                        classname={'o-axis__body__box__button'}
                        gtmMark={`gtm${normalizeTwoWords(props.nombreCategoria)}${normalizeTwoWords(props.tituloGeneral)}${normalizeTwoWords(value.tituloBoton)}`}
                        strictCss={true}
                     />
                  }
               </article>
            </div>
         ) ) }
      </section>
   )
}

export default Axis;
