import { IHowItWorksContainerProps } from "../howItWorksContainer";

function howItWorksContainerAdapter(data: any): IHowItWorksContainerProps {
	return ({
		title: data.tituloGeneral,
		image: data.imagenPrincipal.file.url,
		description: data.descripcion.json,
	})
}
  
export default howItWorksContainerAdapter;
