import React from "react"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { normalizeTwoWords } from "../../../shared/utils/stringUtilities"
import { ReferenciaContenido } from '../../../interfaces/ReferenciaContenido'
import CardNews from '../../atoms/card-news/cardNews';
import './galleryCards.scss'
import LayoutSlider from "../../templates/layout-slider/layoutSlider";
import { ReferenciaPlantillaContenido } from "../../../interfaces/ReferenciaPlantillaContenido";

const GalleryCards = (props: ReferenciaContenido) => {

	const { 
		tituloGeneral,
		referenciaPlantillaContenido,
		nombreCategoria
	} = props;

   const getCard = (_card:ReferenciaPlantillaContenido, _i:number) => {
      return(
         <CardNews 
            key= {`card${_i}` }
            imgSrc= { _card.imagenPrincipal && _card.imagenPrincipal[0].file.url }
            imgAlt= { _card.imagenPrincipal && _card.imagenPrincipal[0].title }
            title= { _card.tituloDescripcion ? _card.tituloDescripcion : ( (_card.descripcion && _card.descripcion.json.content) && documentToReactComponents(_card.descripcion.json) ) }
            link= { _card.redireccionBoton }
            label= { _card.tituloBanner }
            gtmMark= { `gtm${normalizeTwoWords(nombreCategoria)}${normalizeTwoWords(tituloGeneral)}${normalizeTwoWords(_card.tituloBanner)}Tarjeta${_i}` }
            modeGallery={true}
            type= { _card.imagenPrincipal && _card.imagenPrincipal[0].file.contentType }
         />
      )
   }

	return (
		<div className='o-gallery-cards'>
			<h2 className='o-gallery-cards__title title-secondary'> { tituloGeneral } </h2>
			<div className='o-gallery-cards__cont'>
            <LayoutSlider nSlides={3} customClass={'o-gallery-cards__slider'} widthRelative={true} >
               { referenciaPlantillaContenido && referenciaPlantillaContenido.map((card, i)=>(
                  getCard(card, i)
               ))}
            </LayoutSlider>
			</div>  
		</div>
	)
}


export default GalleryCards;
