import React from 'react'
import { ReferenciaContenido } from "../../../interfaces/ReferenciaContenido";
import Description from '../../atoms/description/description';
import "./simpleCards.scss";

const SimpleCards = (props: ReferenciaContenido) => {
    return (
        <div className='m-simple-cards'>
            <h2 className="m-simple-cards__title">{props.tituloGeneral}</h2>
            {props.descripcion &&
                <div className="m-simple-cards__description">
                    <Description description={JSON.stringify(props.descripcion.json)} />
                </div>
            }
            <div className="m-simple-cards__cards">
                {
                    props.referenciaPlantillaContenido.map((card, index) => (
                        <div key={index} id="m-simple-cards__card" className={`m-simple-cards__cards__card ${index % 2 === 0 ? 'even' : 'odd'}`}>
                            <div className="m-simple-cards__cards__card__img">
                                <img src={card.imagenPrincipal[0].file.url} alt=""/>
                            </div>
                            <div className="m-simple-cards__cards__card__description">
                                <Description description={JSON.stringify(card.descripcion.json)} noCentered />
                            </div>
                            <div className="m-simple-cards__cards__card__line"></div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default SimpleCards
