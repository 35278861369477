import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import './cardBh.scss';

const CardBh = (props: CardBhProps) => {

  const { card } = props;
  
  return (
    <div className='a-card-bh'>
        <img className="a-card-bh__img1" src={card.imagenPrincipal[0].file.url} alt="" />
        <img className="a-card-bh__img2" src={card.imagenPrincipal[1].file.url} alt="" />
      <div className="a-card-bh__content" id='card-bh'>
        <h3><a href={card.redireccionBoton} rel="follow">{card.tituloBanner}</a></h3>
        <div id="card-bh-tabs">
          {card.redireccionTabs.map((tab, key) => (
            <a key={key} href={tab.redireccionTab} rel="follow"> - {tab.nombreTab}</a>
          ))}
        </div>
        <p id="card-bh-desc">{card.descripcion.json.content && documentToReactComponents(card.descripcion.json)}</p>
      </div>
    </div>
  );
};

interface CardBhProps {
  card: any;
}

export default CardBh;
