import React from "react"
import "./cardIcon.scss"
import { findTarget, findRel } from "../../../shared/utils/stringUtilities";


const CardIcon = (props: CardIconInterface) => {

	const {
		iconSrc,
		iconAlt,
		text,
		link,
		titleBtn,
		gtmMark
	} = props;

	return (  
      <a 
      href={link} 
      target={ findTarget(link) }
      rel={ findRel(link) }
      className={`a-card-icon ${gtmMark}`}>
			<div className='a-card-icon__icon'> 
         {iconSrc &&
            <img 
               className={`${gtmMark}`} 
               src={ iconSrc } 
               alt={ iconAlt }
            />
         }
			</div>
			<h3 className={`a-card-icon__txt ${gtmMark}`}>
				{ text }
			</h3>
			<span className={`a-card-icon__more ${gtmMark}`}> 
				{ titleBtn } 
			</span>
		</a>
)}

interface CardIconInterface {
	iconSrc: string,
	iconAlt: string,
	text: any,
	link: string,
	titleBtn: string,
	gtmMark?:string
}

export default CardIcon
