import React from "react";

const FieldGroup = (props: any) => {
  return (
    <div
      style={props.fieldType == "address-sap" ? { gridColumn: "1 / span 2" } : { display: "flex", flexDirection: "column", marginBottom: "1rem" }}
    >
      {props.children}
    </div>
  );
};

export default FieldGroup;
