import axios from "axios";

export const getRecomendationURL = (access_token, idUser, typeRecomendation) => {
    const data = axios.get(process.env.ALGORITMO_CARRUSEL_RECOMENDACION_URL, {
        params: {
            code: process.env.ALGORITMO_CARRUSEL_RECOMENDACION_CODE,
            userId: idUser,
            tipoRecomendacion: typeRecomendation==''?'modeloCursoHome':typeRecomendation
        },
        headers: {
            'Apikey': process.env.ALGORITMO_CARRUSEL_RECOMENDACION,
            'Authorization': `Bearer ${access_token}`
        }
    })
    return data;
};