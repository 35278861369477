import React from "react";

// Components //
import TitleSection from "../../molecules/title-section";

import { ReferenciaContenido } from "../../../interfaces/ReferenciaContenido";

// Styles //
import "./experiences.scss";

const Experiences = (props: ReferenciaContenido) => {
    const { tituloGeneral, referenciaPlantillaContenido: refPlantilla } = props;
    return (
        <div className="o-experiences">
            <div className="container">
                <TitleSection title={tituloGeneral} />
                <div className="o-experiences__container">
                    {refPlantilla?.map((item, index) => (
                        <div className="o-experiences__item" key={index}>
                            <div className="o-experiences__item__text">
                                <p>{item.tituloBanner}</p>
                            </div>
                            <div className="o-experiences__item__video">
                                <iframe
                                    className="video"
                                    src={item.redireccionBoton}
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                ></iframe>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Experiences;
