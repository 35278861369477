import React from 'react'
import './bubbles.scss'

interface bubblesInterface{
    height?: string;
}

const bubbles = (props: bubblesInterface) => {
    return (
        <div className="a-bubbles" style={props.height ? { height: props.height } : {}}>
            <div className="a-bubbles__bubble a-bubbles__bubble-x1"></div>
            <div className="a-bubbles__bubble a-bubbles__bubble-x2"></div>
            <div className="a-bubbles__bubble a-bubbles__bubble-x3"></div>
            <div className="a-bubbles__bubble a-bubbles__bubble-x4"></div>
            <div className="a-bubbles__bubble a-bubbles__bubble-x5"></div>
            <div className="a-bubbles__bubble a-bubbles__bubble-x6"></div>
            <div className="a-bubbles__bubble a-bubbles__bubble-x7"></div>
            <div className="a-bubbles__bubble a-bubbles__bubble-x8"></div>
            <div className="a-bubbles__bubble a-bubbles__bubble-x9"></div>
            <div className="a-bubbles__bubble a-bubbles__bubble-x10"></div>
            <div className="a-bubbles__bubble a-bubbles__bubble-x11"></div>
            <div className="a-bubbles__bubble a-bubbles__bubble-x12"></div>
            <div className="a-bubbles__bubble a-bubbles__bubble-x13"></div>
            <div className="a-bubbles__bubble a-bubbles__bubble-x14"></div>
            <div className="a-bubbles__bubble a-bubbles__bubble-x15"></div>
            <div className="a-bubbles__bubble a-bubbles__bubble-x16"></div>
            <div className="a-bubbles__bubble a-bubbles__bubble-x17"></div>
            <div className="a-bubbles__bubble a-bubbles__bubble-x18"></div>
            <div className="a-bubbles__bubble a-bubbles__bubble-x19"></div>
            <div className="a-bubbles__bubble a-bubbles__bubble-x20"></div>
            <div className="a-bubbles__bubble a-bubbles__bubble-x21"></div>
            <div className="a-bubbles__bubble a-bubbles__bubble-x22"></div>
            <div className="a-bubbles__bubble a-bubbles__bubble-x23"></div>
            <div className="a-bubbles__bubble a-bubbles__bubble-x24"></div>
            <div className="a-bubbles__bubble a-bubbles__bubble-x25"></div>
            <div className="a-bubbles__bubble a-bubbles__bubble-x26"></div>
            <div className="a-bubbles__bubble a-bubbles__bubble-x27"></div>
            <div className="a-bubbles__bubble a-bubbles__bubble-x28"></div>
            <div className="a-bubbles__bubble a-bubbles__bubble-x29"></div>
            <div className="a-bubbles__bubble a-bubbles__bubble-x30"></div>
            <div className="a-bubbles__bubble a-bubbles__bubble-x31"></div>
            <div className="a-bubbles__bubble a-bubbles__bubble-x32"></div>
            <div className="a-bubbles__bubble a-bubbles__bubble-x33"></div>
            <div className="a-bubbles__bubble a-bubbles__bubble-x34"></div>
            <div className="a-bubbles__bubble a-bubbles__bubble-x35"></div>
            <div className="a-bubbles__bubble a-bubbles__bubble-x36"></div>
            <div className="a-bubbles__bubble a-bubbles__bubble-x37"></div>
            <div className="a-bubbles__bubble a-bubbles__bubble-x38"></div>
            <div className="a-bubbles__bubble a-bubbles__bubble-x39"></div>
            <div className="a-bubbles__bubble a-bubbles__bubble-x40"></div>
            <div className="a-bubbles__bubble a-bubbles__bubble-x41"></div>
            <div className="a-bubbles__bubble a-bubbles__bubble-x42"></div>
            <div className="a-bubbles__bubble a-bubbles__bubble-x43"></div>
            <div className="a-bubbles__bubble a-bubbles__bubble-x44"></div>
            <div className="a-bubbles__bubble a-bubbles__bubble-x45"></div>
            <div className="a-bubbles__bubble a-bubbles__bubble-x46"></div>
            <div className="a-bubbles__bubble a-bubbles__bubble-x47"></div>
            <div className="a-bubbles__bubble a-bubbles__bubble-x48"></div>
            <div className="a-bubbles__bubble a-bubbles__bubble-x49"></div>
            <div className="a-bubbles__bubble a-bubbles__bubble-x50"></div>
            <div className="a-bubbles__bubble a-bubbles__bubble-x51"></div>
            <div className="a-bubbles__bubble a-bubbles__bubble-x52"></div>
            <div className="a-bubbles__bubble a-bubbles__bubble-x53"></div>
            <div className="a-bubbles__bubble a-bubbles__bubble-x54"></div>
            <div className="a-bubbles__bubble a-bubbles__bubble-x55"></div>
            <div className="a-bubbles__bubble a-bubbles__bubble-x56"></div>
            <div className="a-bubbles__bubble a-bubbles__bubble-x57"></div>
            <div className="a-bubbles__bubble a-bubbles__bubble-x58"></div>
            <div className="a-bubbles__bubble a-bubbles__bubble-x59"></div>
            <div className="a-bubbles__bubble a-bubbles__bubble-x60"></div>
        </div>
    )
}

export default bubbles
