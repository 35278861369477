import React, { useState } from "react";
import Slider from "react-slick";

import { ReferenciaContenido } from "../../../interfaces/ReferenciaContenido";
import { normalizeTwoWords } from "../../../shared/utils/stringUtilities";
import CoverPage from "../../atoms/cover-page/coverPage";

import "./featured.scss";

const Featured = (props: ReferenciaContenido) => {
  const [sliderIndex, setSliderIndex] = useState(0);
  const [slider, setSlider] = useState<any>();
  // Funciones para desktop
  const goToNext = () => {
    slider.slickGoTo(sliderIndex + 4);
  };

  const goToBack = () => {
    slider.slickGoTo(sliderIndex - 4);
  };

  const isActiveNext = (_true: any, _false: any) => {
    return sliderIndex < props.referenciaPlantillaContenido.length - 4
      ? _true
      : _false;
  };

  const isActiveBack = (_true: any, _false: any) =>
    sliderIndex > 0 ? _true : _false;

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    beforeChange: (_: number, next: number) => setSliderIndex(next),
    responsive: [
      {
        breakpoint: 960,
        settings: {
          centerMode: false,
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          dots: true,
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const renderSlides = () => {
    return (
      props.referenciaPlantillaContenido.map((cover_page, index) => (
        <CoverPage 
          key={`covepage${index}`} 
          coverpage={cover_page} 
          gtmMark={`${
              normalizeTwoWords(props.nombreCategoria)}${
              normalizeTwoWords(props.tituloGeneral)
            }`
          }
        />
      ))
    )
  }

  return (
    <section className="m-featured">
      <h2 className="m-featured-title">{props.tituloGeneral}</h2>
      <div className="hide-on-mobile">
        <Slider ref={(_slider) => setSlider(_slider)} {...settings}>
          { renderSlides() }
        </Slider>
        <div
          className="arrow-content arrow-content__left"
          onClick={isActiveBack(goToBack, undefined)}
        >
          <div className="arrows">
            <div className="arrows-contain">
              <div
                className={isActiveBack(
                  "arrow icon-chevron-left active",
                  "arrow icon-chevron-left"
                )}
              />
            </div>
          </div>
        </div>
        <div
          className="arrow-content arrow-content__right"
          onClick={isActiveNext(goToNext, undefined)}
        >
          <div className="arrows">
            <div className="arrows-contain">
              <div
                className={isActiveNext(
                  "arrow icon-chevron-right active",
                  "arrow icon-chevron-right"
                )}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="show-on-mobile">
        <Slider {...settings}>
          { renderSlides() }
        </Slider>
      </div>
    </section>
  );
};

export default Featured;
