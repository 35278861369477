import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { ICarrusel1Props } from '../carrusel1';

function carrusel1Adapter(data: any): ICarrusel1Props {
    return {
        title: data.tituloGeneral,
        subtitle: data.tituloBoton,
        description: documentToReactComponents(data.descripcion.json),
        cardsList:
            data.referenciaPlantillaContenido.map((card) => ({
                image: card.imagenPrincipal[0].file.url,
                name: card.tituloBanner,
                description: card.descripcion ? documentToReactComponents(card.descripcion.json) : '',
                role: card.tituloDescripcion,
                category: card.tipoTitulo
            })) ?? [],
    };
}

export default carrusel1Adapter;
