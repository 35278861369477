import React from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import "./elementGrid.scss";


const ElementGrid = (props: ElementGridInterface) => {
    const {
        description,
        info,
        imageSrc,
        imageAlt,
    preTitle,
    gtmMark,
    linkTo,
    eventDescription,
  } = props;

  return (
    <a
      href={linkTo}
      className={`m-elem-course ${gtmMark}`}
      target="_blank"
      rel="noreferrer"
    >
      <div className="m-elem-course__img">
        <img className={`${gtmMark}`} src={imageSrc} alt={imageAlt} />
      </div>
      <div className={`m-elem-course__cont ${gtmMark}`}>
        <span className={`m-elem-course__pretitle ${gtmMark}`}>{preTitle}</span>
        {description &&
            <div className={`m-elem-course__description ${gtmMark}`}>
                {documentToReactComponents(description)}
            </div>
        }
        {eventDescription &&
            <div className="m-elem-course-info">
                <div className={`m-elem-course-info__schedule ${gtmMark}`}>
                    {documentToReactComponents(eventDescription)}
                </div>
            </div>
        }
        {info &&
            <div className={`m-elem-course__info ${gtmMark}`}>{info}</div>
        }
      </div>
    </a>
  );
};

export default ElementGrid;


interface ElementGridInterface {
  description: any;
  info?: any;
  imageSrc: string;
  imageAlt: string;
  preTitle?: string;
  gtmMark?: string;
  linkTo?: string;
  eventDescription?: any;
}
