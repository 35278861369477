import React, { } from 'react';
import { get } from 'lodash';

import './contentLeftRight.scss';
import Button from '../../atoms/button/button';
import { ReferenciaContenido } from '../../../interfaces/ReferenciaContenido';
import { ReferenciaPlantillaContenido } from '../../../interfaces/ReferenciaPlantillaContenido';
import { normalizeTwoWords, OptimizeImage } from "../../../shared/utils/stringUtilities";
import RichtextDescription from '../../atoms/richtext-description/richtextDescription';
import Img from "gatsby-image";

enum DirectionEnum {
   Left = 'Left',
   Right = 'Right',
}

const ContentLeftRight = (props: ReferenciaContenido) => {
   const contentProps:ReferenciaPlantillaContenido = props.referenciaPlantillaContenido && props.referenciaPlantillaContenido[0];

   const titleBanner = get(contentProps, 'tituloBanner', '');
   const titleBoton = get(contentProps, 'tituloBoton', '');
   const redirecBoton = get(contentProps, 'redireccionBoton', '');
   const imageP = get(contentProps, 'imagenPrincipal', []);
   const description: {json:any } = get(contentProps, 'descripcion', { json: undefined });
   const directionContent = get(contentProps, 'posicionImagen', DirectionEnum.Left);
   const { nombreCategoria } = props

   return (
      <div className='o-content-left-right' >
         <div className={`o-content-left-right__background 
            ${directionContent == DirectionEnum.Right ? 'o-content-left-right__background__right' : ''}`}>
         </div>
         <div className='o-content-left-right__block' >
            <div className={`o-content-left-right__block__content 
               ${directionContent == DirectionEnum.Right ? 'o-content-left-right__block__content__right' : ''}`}>
               {imageP && imageP.length > 0 &&  
                  imageP[0].fluid ?
                  <Img  className="o-content-left-right__block__content__cover"
                        fluid= { imageP[0].fluid }
                        alt={ imageP[0].title || "imagen bienestar" }
                  />
                  :
                  <img  className="o-content-left-right__block__content__cover"
                        src= { (imageP && imageP[0]) && OptimizeImage(imageP[0].file.url, imageP[0].file.contentType, 768)}
                        alt={ (imageP && imageP[0]) ? imageP[0].title : "imagen bienestar" }
                        loading="lazy"
                  />
               }
               <div className='o-content-left-right__block__background'>
                  <div className={`o-content-left-right__block__content__text 
                           ${directionContent == DirectionEnum.Right ? 'o-content-left-right__block__content__text__right' : ''}`}>
                     <h2 className='o-content-left-right__block__content__text__title'>
                        {titleBanner}
                     </h2>
                     { description &&
                        <div className='o-content-left-right__block__content__text__description'>
                           <RichtextDescription {...props} descripcion={description} />
                        </div>
                     }
                     
                     <div className={`o-content-left-right__block__content__text__action ${directionContent == DirectionEnum.Right ? 'o-content-left-right__block__content__text__action__right' : ''}`}>
                        <Button
                           id={contentProps.idBoton ? contentProps.idBoton : 'buttonContentLeftRight'}
                           type='link'
                           href={redirecBoton}
                           classname={'primary '}
                           text={titleBoton}
                           isDisabled={false}
                           gtmMark= { `gtm${normalizeTwoWords(nombreCategoria)}${normalizeTwoWords(titleBanner)}ConoceMas` }
                        />
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
};

export default ContentLeftRight;
