import React, { useEffect, useState } from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Slider from "react-slick";

import Button from "../../atoms/button/button";
import ElementGrid from "../element-grid/elementGrid";
import { normalizeTwoWords, formatImageAlt } from "../../../shared/utils/stringUtilities";

import "./gridGroup.scss";
interface GridGroupInterface {
  title: string;
  contentCourses: any;
  gtmMark?: string;
  href?: string;
}

const GridGroup = (props: GridGroupInterface) => {
  const { title, contentCourses, gtmMark, href = "" } = props;
  
  const [mainCourseId, setMainCourseId] = useState(0);
  
  useEffect(() => {
    contentCourses.map((elem, i) => {
      elem.esDestacado && setMainCourseId(i);
    });
  }, []);

  const sliderSettings = {
    arrows: false,
    dots: true,
    infinite: false,
    speed: 200,
    slidesToShow: 2,
    slidesToScroll: 1,
  };

  const elementItem = (_elem, _i) => {
    return (
      <ElementGrid
        preTitle={_elem.tituloPrincipal}
        description={_elem.descripcion.json}
        eventDescription={_elem.descripcionEvento}
        imageSrc={_elem.imagenPrincipal && _elem.imagenPrincipal.file.url}
        imageAlt={formatImageAlt(_elem.imagenPrincipal.title) || "Imagen " + _elem.tituloPrincipal}
        key={`courseSide${_i}`}
        gtmMark={`${gtmMark}${normalizeTwoWords(
          title
        )}Laterales${normalizeTwoWords(_elem.tituloPrincipal)}`}
        linkTo={_elem.redireccionBoton}
      />
    );
  };

  return (
    <div className="m-grid-group">
      <div className="m-grid-group__cont">
        <div className="m-grid-group__dominant">
          <div className="m-grid-group__dominant-image-container">
            <img
              src={
                contentCourses[mainCourseId].imagenPrincipal &&
                contentCourses[mainCourseId].imagenPrincipal.file.url
              }
              alt={
                formatImageAlt(contentCourses[mainCourseId].imagenPrincipal.title) ||
                "Imagen " + contentCourses[mainCourseId].tituloPrincipal
              }
            />
            <div className="m-grid-group__dominant-info">
              {contentCourses[mainCourseId].tituloPrincipal &&
                <p className="m-grid-group__dominant-event">
                  {contentCourses[mainCourseId].tituloPrincipal}
                </p>
              }
              {contentCourses[mainCourseId].descripcion &&
                <div className={`m-grid-group__dominant-title`}>
                  {documentToReactComponents(
                    contentCourses[mainCourseId].descripcion.json
                  )}
                </div>
              }
              {contentCourses[mainCourseId].descripcionEvento &&
                <div className="m-grid-group__dominant-schedule">
                  {documentToReactComponents(
                    contentCourses[mainCourseId].descripcionEvento
                  )}
                </div>
              }
              <Button 
								type= {"link"} 
								classname= {`secondary`}
								text= { contentCourses[mainCourseId].textoBoton }
								href= { contentCourses[mainCourseId].redireccionBoton } 
								isDisabled= {false}
								gtmMark= { `${gtmMark}${normalizeTwoWords(title)}ConoceMas` }
							/>
            </div>
          </div>
        </div>
        <div className="m-grid-group__others">
          <div className="m-grid-group__others-cont hide-on-mobile">
            <div>
              {contentCourses &&
                contentCourses.map(
                  (elem, i) => i !== mainCourseId && elementItem(elem, i)
                )}
            </div>
          </div>
          <div className="show-on-mobile">
            <Slider {...sliderSettings} className="m-grid-group__slider">
              {contentCourses &&
                contentCourses.map(
                  (elem, i) => i !== mainCourseId && elementItem(elem, i)
                )}
              <div></div>
            </Slider>
          </div>
          {href &&
            <div className="m-grid-group__others-btn">
              <Button
                type={"link"}
                classname={"primary"}
                text={"Ver todos los eventos"}
                href={href}
                isDisabled={false}
                gtmMark={`${gtmMark}${normalizeTwoWords(title)}VerTodo`}
              />
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default GridGroup;
