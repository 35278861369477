import React, { useState } from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { ReferenciaContenido } from "../../../interfaces/ReferenciaContenido";
// import { postSub } from "./postSub";
import "./bulletin.scss"

const bulletin = (props: ReferenciaContenido) => {
    const [contact, setContact] = useState({
        name: "",
        email: ""
    });

    const [habeas, setHabeas] = useState(false)

    const handleInputChange = (event) => {
        setContact({
            ...contact,
            [event.target.name] : event.target.value
        })
    }

    const handleClick = () => {
        setHabeas(!habeas)
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        if (contact.name.length < 3) {
            alert("Debes ingresar un nombre válido")
        } else {
            if (!contact.email.includes("@") || !contact.email.slice(-4).includes(".") ) {
                alert("Debes ingresar un correo electrónico válido")
            } else {
                // postSub(contact)
                alert("DONE")
            }
        }
        
    }

    return (
        <div className='o-bulletin'>
            <div className="o-bulletin__cover">
                <img src={props.imagenPrincipal.file.url} alt="Boletin" />
            </div>
            <div className="o-bulletin__info">
                <div className="o-bulletin__info__left">
                    <div className="o-bulletin__info__left__main">
                        <h3 className="o-bulletin__info__left__main__title">{props.tituloGeneral}</h3>
                        <p className="o-bulletin__info__left__main__description">{documentToReactComponents(props.descripcion.json)}</p>
                    </div>
                    <form onSubmit={handleSubmit} className="o-bulletin__info__left__form">
                        <div className="o-bulletin__info__left__form__contact">
                            <div className="o-bulletin__info__left__form__contact__name">
                                <label><b>Nombres:</b></label>
                                <input type="text" className="form-control" onChange={handleInputChange} name="name"></input>
                            </div>
                            <div className="o-bulletin__info__left__form__contact__email">
                                <label><b>Correo electrónico:</b></label>
                                <input type="email" className="form-control" onChange={handleInputChange} name="email"></input>
                            </div>
                        </div>
                        <div className="o-bulletin__info__left__form__habeas">
                            <input type="checkbox" name="habeas" onClick={handleClick} />
                            <label>Acepto los <a href={props.redireccionBoton} target='_blank' rel="noreferrer">términos y condiciones</a> de habeas data</label>
                        </div>
                        <button className={`o-bulletin__info__left__form__button ${!habeas && "disabled"}`} type="submit">{props.tituloBoton}</button>
                    </form>
                </div>
                <div className="o-bulletin__info__right">
                    <div className="o-bulletin__info__right__picture">
                        <img src={props.referenciaPlantillaContenido[0].imagenPrincipal[0].file.url} alt="Boletin" />
                        <div className='circle'></div>
                    </div>
                    <h4 className="o-bulletin__info__right__main">{props.referenciaPlantillaContenido[0].tituloBanner}</h4>
                    <span className="o-bulletin__info__right__title">{props.referenciaPlantillaContenido[0].tituloDescripcion}</span>
                    <p className="o-bulletin__info__right__description">{documentToReactComponents(props.referenciaPlantillaContenido[0].descripcion.json)}</p>
                    <p className="o-bulletin__info__right__mail">Correo electrónico: <b>{props.referenciaPlantillaContenido[0].tipoTitulo}</b></p>
                    <p className="o-bulletin__info__right__phone">Celular: <b>{props.referenciaPlantillaContenido[0].tituloBoton}</b></p>
                </div>
            </div>
        </div>
    )
}

export default bulletin
