import React, { useContext, useState, useEffect } from "react";
import { ReferenciaContenido } from "../../../interfaces/ReferenciaContenido";
import GlobalContext from "../../../contexts/globalContext";
import Accordion from "../../molecules/accordion/accordion";
import Button from "../../atoms/button/button";
import { normalizeTwoWords } from "../../../shared/utils/stringUtilities";
import "./formLibranzas.scss";
import axios from "axios";
import Loading from "../../atoms/loading/loading";
import Modal from "../../atoms/modal/modal";
import Alerta from "../../../assets/images/icons/alertaroja.svg";
import Like from "../../../assets/images/icons/like_e2.svg";
import Check from "../../../assets/images/icons/check_green.svg";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "../../atoms/tooltip/tooltip";
import useLoginGigya from '../../../shared/hooks/useLoginGigya';

const formLibranzas = (props: ReferenciaContenido) => {
   const Context = useContext(GlobalContext);

   const { loginComfamaId, login } = useLoginGigya();

   const [showPopUpLogin, setShowPopUpLogin] = useState(true);

   const [verifyDatosEmpresa, setVerifyDatosEmpresa] = useState(false);
   const [verifyDatosContactoEmpreas, setVerifyDatosContactoEmpreas] = useState(
      false
   );
   const [
      verifyDatosRepresentanteLegal,
      setVerifyDatosRepresentanteLegal,
   ] = useState(false);
   const [verifyDatosConvenio, setVerifyDatosConvenio] = useState(false);
   const [
      verifyDatosAprobacionLibranza,
      setVerifyDatosAprobacionLibranza,
   ] = useState(false);
   const [verifyDatosPagoLibranza, setVerifyDatosPagoLibranza] = useState(
      false
   );
   const [verifyAttachmentLibranzas, setVerifyAttachmentsLibranzas] = useState(
      false
   );
   const [verifyAttachmentComercio, setVerifyAttachmentsComercio] = useState(
      false
   );

   const settersProps = {
      setVerifyDatosEmpresa,
      setVerifyDatosContactoEmpreas,
      setVerifyDatosRepresentanteLegal,
      setVerifyDatosConvenio,
      setVerifyDatosAprobacionLibranza,
      setVerifyDatosPagoLibranza,
      setVerifyAttachmentsComercio,
      setVerifyAttachmentsLibranzas,
   };

   const defaultFormValues = {
      naturalezaEmp: 1,
      tipo_doc_emp: 1,
      numero_doc_emp: "",
      digito_verificacion: "",
      nombre_comercial: "",
      razon_social: "",
      nombre_contacto_empresa: "",
      correo_empresa: "",
      contacto_empresa: "",
      nombre_contacto_empresa_opc: "",
      correo_empresa_opc: "",
      contacto_empresa_opc: "",
      tipo_doc_rep_legal: 1,
      doc_rep_legal: "",
      nombre_rep_legal: "",
      correo_rep_legal: "",
      contacto_rep_legal: "",
      info_convenio: {
         consumo: [true],
         creditos: [true],
         vivienda: [true],
         seguros: [true],
         porcentaje: "50",
         condicion_especial: "",
      },
      info_personas_aprob: [
         {
            tipo_doc: "Cédula de ciudadanía",
            doc_identidad: "",
            nombre: "",
            correo: "",
            num_contacto: "",
         },
      ],
      info_personas_pago: [
         {
            tipo_doc: "Cédula de ciudadanía",
            doc_identidad: "",
            nombre: "",
            correo: "",
            num_contacto: "",
         },
      ],
      typeFileFormat: "",
      typeFileCamara: "",
      estado_libranzas: 0,
   };

   const [formValues, setFormValues] = useState(defaultFormValues);
   const [breadcrumbsActive, setBreadcrumbsActive] = useState(1);
   const [loading, setLoading] = useState(false);
   const [statePopUp, setStatePopUp] = useState(false);
   const [statePopUp2, setStatePopUp2] = useState(false);
   const [statePopUp3, setStatePopUp3] = useState(false);
   const [errorPopUp, setErrorPopUp] = useState(false);
   const [verify, setVerify] = useState(true);
   const [verifyAttachments, setVerifyAttachments] = useState(true);
   const [optionsDocsEmp, setOptionsDocsEmp] = useState<any>([]);
   const [naturalezaEmp, setNaturalezaEmp] = useState<any>([]);
   const [loadingMagenta, setLoadingMagenta] = useState(false);

   const firstName = Context.gigyaContext.gigyaAccount?.profile?.firstName || ""
   const secondName = Context.gigyaContext.gigyaAccount?.data?.secondName || ""
   const lastName = Context.gigyaContext.gigyaAccount?.profile?.lastName || ""
   const secondLastName = Context.gigyaContext.gigyaAccount?.data?.secondLastName || ""

   const retrieveData = () => {

      if (Context.gigyaContext.gigyaAccount?.UID) {
         axios
            .get(
               process.env.LIBRANZAS_SERVICES_URL +
                  "get_register?id_session=" +
                  String(Context.gigyaContext.gigyaAccount?.UID)
            )
            .then((res) => {
               reloadValues(res.data);              
               setShowPopUpLogin(true);
               if (res.data.estado_libranzas == 8) {
                  setBreadcrumbsActive(3)
               }
            });
         setLoading(false);
      } else {
         console.log("No está logueado");
         setLoading(true);
      }
   };

   const sendData = async () => {
      setLoadingMagenta(true);
      let data = {
         user_session: Context.gigyaContext.gigyaAccount?.UID,
         naturaleza_emp_id: formValues.naturalezaEmp,
         tipo_doc_emp_id: formValues.tipo_doc_emp,
         numero_doc_emp: formValues.numero_doc_emp,
         digito_verificacion: formValues.digito_verificacion,
         razon_social: formValues.razon_social,
         nombre_comercial: formValues.nombre_comercial,
         nombre_contacto_emp: formValues.nombre_contacto_empresa,
         correo_empresa: formValues.correo_empresa,
         contacto_empresa: formValues.contacto_empresa,
         nombre_contacto_empresa_opc: formValues.nombre_contacto_empresa_opc,
         correo_empresa_opc: formValues.correo_empresa_opc,
         contacto_empresa_opc: formValues.contacto_empresa_opc,
         tipo_doc_rep_legal_id: formValues.tipo_doc_rep_legal,
         doc_rep_legal: formValues.doc_rep_legal,
         nombre_rep_legal: formValues.nombre_rep_legal,
         correo_rep_legal: formValues.correo_rep_legal,
         contacto_rep_legal: formValues.contacto_rep_legal,
         info_convenio: {
            consumo: [formValues.info_convenio.consumo[0]],
            creditos: [formValues.info_convenio.creditos[0]],
            vivienda: [formValues.info_convenio.vivienda[0]],
            seguros: [formValues.info_convenio.seguros[0]],
            porcentaje: formValues.info_convenio.porcentaje,
            condicion_especial: formValues.info_convenio.condicion_especial,
         },
         info_personas_aprob: formValues.info_personas_aprob,
         info_personas_pago: formValues.info_personas_pago,
         estado_libranzas: 2,
         canal_creacion: "AUTOGESTIÓN",
         nombre_creacion: firstName + " " + secondName + " " + lastName + " " + secondLastName,
         tipo_doc_creacion: Context.gigyaContext.gigyaAccount?.data?.docType,
         doc_creacion: Context.gigyaContext.gigyaAccount?.data?.docNumber,
         correo_creacion: Context.gigyaContext.gigyaAccount?.profile?.email,
         contacto_creacion: Context.gigyaContext.gigyaAccount?.data?.mobile
      };

      const resp = await axios.put(
         process.env.LIBRANZAS_SERVICES_URL + "empresas",
         JSON.stringify(data),
         {
            headers: {
               "Content-Type": "application/json",
            },
         }
      );
      if (resp.data.status == "OK") {
         setLoadingMagenta(false);
         setBreadcrumbsActive(2);
         scrollForm();
         setStatePopUp3(false);
         changeStatus(
            formValues.numero_doc_emp + formValues.digito_verificacion,
            2
         );
      } else if (resp.data.status === 202) {
         setStatePopUp2(true);
      } else {
         setLoadingMagenta(false);
         setErrorPopUp(true);
      }
   };

   const scrollForm = () => {
      scroll({
         top: 650,
         left: 0,
         behavior: "smooth",
      });
   };

   const reloadValues = (data) => {
      setFormValues({
         ...formValues,
         naturalezaEmp: data.naturaleza_emp_id,
         tipo_doc_emp: data.tipo_doc_emp_id,
         numero_doc_emp: data.numero_doc_emp,
         digito_verificacion: data.digito_verificacion,
         nombre_comercial: data.nombre_comercial || "",
         razon_social: data.razon_social,
         nombre_contacto_empresa: data.nombre_contacto_emp,
         correo_empresa: data.correo_empresa,
         contacto_empresa: data.contacto_empresa,
         nombre_contacto_empresa_opc: data.nombre_contacto_empresa_opc,
         correo_empresa_opc: data.correo_empresa_opc,
         contacto_empresa_opc: data.contacto_empresa_opc,
         tipo_doc_rep_legal: data.tipo_doc_rep_legal_id,
         doc_rep_legal: data.doc_rep_legal,
         nombre_rep_legal: data.nombre_rep_legal,
         correo_rep_legal: data.correo_rep_legal,
         contacto_rep_legal: data.contacto_rep_legal,
         info_convenio: data.info_convenio,
         info_personas_aprob: data.info_personas_aprob,
         info_personas_pago: data.info_personas_pago,
         estado_libranzas: data.estado_libranzas,
      });
   };

   const sendEmail = () => {
      setLoadingMagenta(true);

      changeStatus(
         formValues.numero_doc_emp +
            formValues.digito_verificacion,
         8
      );

      var data = JSON.stringify({
         num_doc: formValues.numero_doc_emp + formValues.digito_verificacion,
         nombre_rep_legal: formValues.nombre_rep_legal,
         nombre_usuario: formValues.nombre_contacto_empresa,
         correo_contacto: formValues.correo_rep_legal,
         nombre_comercial: formValues.razon_social,
         user_session: Context.gigyaContext.gigyaAccount?.UID,
         doc_rep_legal: formValues.doc_rep_legal,
         nombre_creacion: firstName + " " + secondName + " " + lastName + " " + secondLastName,
         correo_creacion: Context.gigyaContext.gigyaAccount?.profile?.email,
      });

      var url = process.env.LIBRANZAS_SERVICES_URL + "notification";
      var headers = {
         "Content-Type": "application/json",
      };

      axios
         .post(url, data, { headers })
         .then(function (response) {
            setBreadcrumbsActive(3)
            setLoadingMagenta(false);
         })
         .catch(function (error) {
            console.log(error);
            setBreadcrumbsActive(3)
            setLoadingMagenta(false);
         });
      
      scrollForm();
   };

   const changeStatus = (numDoc, newStatus) => {
      const jsonBody = JSON.stringify({
         num_doc: numDoc,
         new_status: newStatus,
      });

      axios.post(
         process.env.LIBRANZAS_SERVICES_URL + "update_status",
         jsonBody,
         {
            headers: {
               "Content-Type": "application/json",
            },
         }
      );
   };

   const getNaturalezaEmp = () => {
      return axios
         .get(process.env.LIBRANZAS_SERVICES_URL + "get_naturaleza_emp")
         .then((res) => {
            return res.data.data;
         });
   };

   const getTipoDocEmp = () => {
      return axios
         .get(process.env.LIBRANZAS_SERVICES_URL + "get_type_doc")
         .then((res) => {
            return res.data.data;
         });
   };

   useEffect(() => {
      if (Context.gigyaContext.gigyaAccount === undefined) {
         const timerId = setTimeout(() => {
            setLoading(true);
            Context.gigyaContext.login();
         }, 1500);
         return () => clearTimeout(timerId);
      }
   }, [Context.gigyaContext.gigyaAccount]);

   useEffect(() => {
      const timerId = setTimeout(() => {
         retrieveData();
      }, 0);
      return () => clearTimeout(timerId);
   }, [Context.gigyaContext.gigyaAccount]);

   useEffect(() => {
      Promise.all([getNaturalezaEmp(), getTipoDocEmp()]).then((values) => {
         setNaturalezaEmp(values[0]);
         setOptionsDocsEmp(values[1]);
      });

   }, []);

   useEffect(() => {
      setVerify(
         !verifyDatosEmpresa ||
            !verifyDatosContactoEmpreas ||
            !verifyDatosRepresentanteLegal ||
            !verifyDatosConvenio ||
            !verifyDatosAprobacionLibranza ||
            !verifyDatosPagoLibranza
      );
      // popup
      if (
         verifyDatosEmpresa &&
         verifyDatosContactoEmpreas &&
         verifyDatosRepresentanteLegal &&
         verifyDatosAprobacionLibranza &&
         verifyDatosPagoLibranza &&
         showPopUpLogin
      ) {
         setShowPopUpLogin(false);
         scrollForm();
         if (
            formValues.estado_libranzas === 1 ||
            formValues.estado_libranzas === 3
         ) {
            setBreadcrumbsActive(3)
            setStatePopUp2(true);
         } else if (formValues.estado_libranzas === 2) {
            setBreadcrumbsActive(2)
            setStatePopUp3(true);
         } else if (formValues.estado_libranzas === 8) {
            setBreadcrumbsActive(3)
         }
         
      } else {
         setShowPopUpLogin(false);
      }
   }, [
      verifyDatosEmpresa,
      verifyDatosContactoEmpreas,
      verifyDatosRepresentanteLegal,
      verifyDatosConvenio,
      verifyDatosAprobacionLibranza,
      verifyDatosPagoLibranza,
   ]);

   const docTypes: any = [];
   const otherDocTypes: any = [];

   for (let i = 0; i < optionsDocsEmp.length; i++) {
      if (i === 2) {
         continue;
      } else if (i === 4 || i === 5) {
         otherDocTypes.push(optionsDocsEmp[i]);
      } else {
         otherDocTypes.push(optionsDocsEmp[i]);
         docTypes.push(optionsDocsEmp[i]);
      }
   }
  

   return (
      <>
         {loading ? <Loading /> : ""}
         <GlobalContext.Consumer>
            {(data) => (
               <div className="o-form-libranzas">
                  {loadingMagenta ? (
                     <div className={`o-form-libranzas__loadingMag`}>
                        <CircularProgress
                           size={64}
                           thickness={6}
                           color="secondary"
                        />
                     </div>
                  ) : null}
                  <Modal
                     open={statePopUp}
                     onClose={() => setStatePopUp(!statePopUp)}
                     className="m-share-modal pointer-none"
                  >
                     <div className="o-form-libranzas__modal__body">
                        <div className="o-form-libranzas__modal__body__close">
                           <button
                              className="icon-close"
                              onClick={() => setStatePopUp(!statePopUp)}
                           />
                        </div>
                        <div className="o-form-libranzas__modal__body__header">
                           <div className="o-form-libranzas__modal__body__header__image">
                              <img src={Like} alt="Logo like" />
                           </div>
                           <span>
                              ¡Hola{" "}
                              {
                                 Context.gigyaContext.gigyaAccount?.profile
                                    .firstName
                              }
                              ! Hemos recibido tu solicitud.
                           </span>
                        </div>
                        <div className="o-form-libranzas__modal__body__help">
                           <p>
                              En este momento nuestro equipo está validando la
                              información.
                           </p>
                        </div>
                        <div className="o-form-libranzas__modal__body__buttons">
                           <Button
                              id="buttonVideoLink"
                              type="button"
                              href="#"
                              classname={"secondary pointer-auto"}
                              text="OK"
                              isDisabled={false}
                              gtmMark={`gtm${normalizeTwoWords(
                                 "Libranzas"
                              )}${normalizeTwoWords("OK")}Btn`}
                              onClickAction={() => {
                                 setStatePopUp(!statePopUp);
                                 window.open(
                                    "https://www.comfama.com/finanzas/creditos-con-proposito/libranza-digital/",
                                    "_self"
                                 );
                              }}
                           />
                        </div>
                     </div>
                  </Modal>

                  <Modal
                     open={loading}
                     onClose={() => setLoading(!loading)}
                     className="m-share-modal pointer-none"
                  >
                     <div className="o-form-libranzas__modal__body">
                        <div className="o-form-libranzas__modal__body__close">
                           <button
                              className="icon-close"
                              onClick={() => setLoading(!loading)}
                           />
                        </div>
                        <div className="o-form-libranzas__modal__body__header">
                           <div className="o-form-libranzas__modal__body__header__image">
                              <img src={Alerta} alt="Logo like" />
                           </div>
                        </div>
                        <div className="o-form-libranzas__modal__body__helper">
                           <p>
                              Para comenzar la solicitud del convenio de
                              deducción de nómina, es necesario que inicies
                              sesión
                           </p>
                        </div>
                        <div className="o-form-libranzas__modal__body__buttons">
                           <Button
                              id="buttonVideoLink"
                              type="button"
                              href="#"
                              classname={"secondary pointer-auto"}
                              text="Iniciar Sesión"
                              isDisabled={false}
                              onClickAction={() => loginComfamaId()}
                           />
                        </div>
                     </div>
                  </Modal>
                  <Modal
                     open={statePopUp2}
                     onClose={() => setStatePopUp2(!statePopUp2)}
                     className="m-share-modal pointer-none"
                  >
                     <div className="o-form-libranzas__modal__body">
                        <div className="o-form-libranzas__modal__body__header">
                           <div className="o-form-libranzas__modal__body__header__image">
                              <img src={Like} alt="Logo like" />
                           </div>
                           <span>
                              ¡Hola{" "}
                              {
                                 Context.gigyaContext.gigyaAccount?.profile
                                    .firstName
                              }
                              ! Ya recíbimos tu solicitud.
                           </span>
                        </div>
                        <div className="o-form-libranzas__modal__body__help__Top">
                           <p>
                              Si debes modificar información del convenio por
                              favor escribe al correo electronico:
                              <a href="mailto:conveniodxn@comfama.com.co">
                                 {" "}
                                 conveniodxn@comfama.com.co
                              </a>
                           </p>
                        </div>
                        <div className="o-form-libranzas__modal__body__buttons">
                           <Button
                              id="buttonVideoLink"
                              type="button"
                              href="#"
                              classname={"secondary pointer-auto"}
                              text="ACEPTAR"
                              isDisabled={false}
                              gtmMark={`gtm${normalizeTwoWords(
                                 "Libranzas"
                              )}${normalizeTwoWords("ACEPTAR")}Btn`}
                              onClickAction={() => {
                                 window.open(
                                    "https://www.comfama.com/finanzas/creditos-con-proposito/libranza-digital/",
                                    "_self"
                                 );
                              }}
                           />
                        </div>
                     </div>
                  </Modal>

                  <Modal
                     open={statePopUp3}
                     onClose={() => setStatePopUp3(!statePopUp3)}
                     className="m-share-modal"
                  >
                     <div className="o-form-libranzas__modal__body --moreHeight">
                        <div className="o-form-libranzas__modal__body__close">
                           <button
                              className="icon-close"
                              onClick={() => setStatePopUp3(!statePopUp3)}
                           />
                        </div>
                        <div className="o-form-libranzas__modal__body__header">
                           <div className="o-form-libranzas__modal__body__header__image">
                              <img src={Like} alt="Logo like" />
                           </div>
                           <span>
                              ¡Hola{" "}
                              {
                                 Context.gigyaContext.gigyaAccount?.profile
                                    .firstName
                              }
                              !
                           </span>
                        </div>
                        <div className="o-form-libranzas__modal__body__help">
                           <p>
                              Todo está casi listo, solo falta cargar los
                              documentos para continuar con el proceso y tener
                              el convenio de libranza con Comfama.
                              <br />
                              <br />
                              Debes cargar el formulario que descargaste en esta
                              plataforma, si tienes una versión anterior, esta
                              ya no es válida.
                           </p>
                        </div>
                        <div className="o-form-libranzas__modal__body__buttons">
                           <Button
                              id="buttonVideoLink"
                              type="button"
                              href="#"
                              classname={"secondary "}
                              text="Continuar"
                              isDisabled={false}
                              gtmMark={`gtm${normalizeTwoWords(
                                 "Libranzas"
                              )}${normalizeTwoWords("Continuar")}Btn`}
                              onClickAction={() => {
                                 setStatePopUp3(!statePopUp3);
                              }}
                           />
                        </div>
                     </div>
                  </Modal>

                  <Modal
                     open={errorPopUp}
                     onClose={() => setErrorPopUp(!errorPopUp)}
                     className="m-share-modal"
                  >
                     <div className="o-form-libranzas__modal__body">
                        <div className="o-form-libranzas__modal__body__close">
                           <button
                              className="icon-close"
                              onClick={() => setErrorPopUp(!errorPopUp)}
                           />
                        </div>
                        <div className="o-form-libranzas__modal__body__header_red">
                           <div className="o-form-libranzas__modal__body__header__image">
                              <img src={Alerta} alt="Logo alerta" />
                           </div>
                           <span>¡Lo sentimos!</span>
                        </div>
                        <div className="o-form-libranzas__modal__body__help_big">
                           <p>
                              En este momento el sistema no está disponible. Por
                              favor intenta más tarde
                           </p>
                        </div>
                        <div className="o-form-libranzas__modal__body__buttons">
                           <Button
                              id="buttonVideoLink"
                              type="button"
                              href="#"
                              classname={"secondary "}
                              text="Aceptar"
                              isDisabled={false}
                              gtmMark={`gtm${normalizeTwoWords(
                                 "Libranzas"
                              )}${normalizeTwoWords("Continuar")}Btn`}
                              onClickAction={() => {
                                 setErrorPopUp(!errorPopUp);
                              }}
                           />
                        </div>
                     </div>
                  </Modal>

                  <button onClick={data.gigyaContext.login} />
                  {breadcrumbsActive === 1 ? (
                     <>
                        <div className="o-form-libranzas__breadcrumbs__item">
                           <div style={{ width: '100%' }}>
                              <p>
                              Prepárate para el proceso. Necesitarás la
                              certificación de representación legal y el
                              documento de identidad del representante legal.
                              Los campos señalados con el signo (<span>*</span>)
                              son obligatorios.</p>
                           </div>
                           <Tooltip
                                 title="¿Qué es la certificación de representación legal?"
                                 textString="Es el documento oficial que certifica la existencia de una empresa o establecimiento, y que contiene el nombre del representante legal. Por ejemplo, cámara de comercio, personería jurídica, RUT, entre otros."
                                 id="unique-tooltip-id"
                              />
                        </div>
                        <div className="o-form-libranzas__stepper1">
                           <div className="o-form-libranzas__stepper1__box">
                              <p>Recolección de información</p>
                           </div>
                           <div className="o-form-libranzas__stepper1__box1">
                              <p>Carga de documentos</p>
                           </div>
                        </div>
                        <form className="o-form-libranzas__form">
                           <Accordion
                              showCheck={true}
                              title="Acuerdo de servicio y Aviso de Privacidad General"
                              formValues={formValues}
                              setFormValues={setFormValues}
                              setVerify={setVerify}
                              setVerifyAttachments={setVerifyAttachments}
                              naturalezaEmp={naturalezaEmp}
                              docTypes={docTypes}
                              otherDocTypes={otherDocTypes}
                              step={1}
                           />
                           <Accordion
                              showCheck={verifyDatosEmpresa}
                              {...settersProps}
                              title="Datos del empleador o entidad pagadora"
                              formValues={formValues}
                              setFormValues={setFormValues}
                              setVerify={setVerify}
                              setVerifyAttachments={setVerifyAttachments}
                              naturalezaEmp={naturalezaEmp}
                              docTypes={docTypes}
                              otherDocTypes={otherDocTypes}
                              step={2}
                           />
                           <Accordion
                              showCheck={verifyDatosContactoEmpreas}
                              {...settersProps}
                              title="Información para contacto de libranza"
                              formValues={formValues}
                              setFormValues={setFormValues}
                              setVerify={setVerify}
                              setVerifyAttachments={setVerifyAttachments}
                              naturalezaEmp={naturalezaEmp}
                              docTypes={docTypes}
                              otherDocTypes={otherDocTypes}
                              step={3}
                           />
                           <Accordion
                              showCheck={verifyDatosRepresentanteLegal}
                              {...settersProps}
                              title="Información de representante legal"
                              formValues={formValues}
                              setFormValues={setFormValues}
                              setVerify={setVerify}
                              setVerifyAttachments={setVerifyAttachments}
                              naturalezaEmp={naturalezaEmp}
                              docTypes={docTypes}
                              otherDocTypes={otherDocTypes}
                              step={4}
                           />
                           <Accordion
                              showCheck={verifyDatosConvenio}
                              {...settersProps}
                              title="Condiciones para descuento de nómina"
                              formValues={formValues}
                              setFormValues={setFormValues}
                              setVerify={setVerify}
                              setVerifyAttachments={setVerifyAttachments}
                              naturalezaEmp={naturalezaEmp}
                              docTypes={docTypes}
                              otherDocTypes={otherDocTypes}
                              step={5}
                           />
                           <Accordion
                              showCheck={verifyDatosAprobacionLibranza}
                              {...settersProps}
                              title="Información de personas autorizadas para aprobación de libranza"
                              formValues={formValues}
                              setFormValues={setFormValues}
                              setVerify={setVerify}
                              setVerifyAttachments={setVerifyAttachments}
                              naturalezaEmp={naturalezaEmp}
                              docTypes={docTypes}
                              otherDocTypes={otherDocTypes}
                              step={6}
                           />
                           <Accordion
                              showCheck={verifyDatosPagoLibranza}
                              {...settersProps}
                              title="Información de personas autorizadas para girar el valor de la libranza"
                              formValues={formValues}
                              setFormValues={setFormValues}
                              setVerify={setVerify}
                              setVerifyAttachments={setVerifyAttachments}
                              naturalezaEmp={naturalezaEmp}
                              docTypes={docTypes}
                              otherDocTypes={otherDocTypes}
                              step={7}
                           />
                           <div className="o-form-libranzas__form__button">
                              <div className="o-form-libranzas__form__button__next">
                                 <input
                                    type="button"
                                    value="Siguiente"
                                    onClick={() => sendData()} 
                                    disabled={verify}
                                    />
                              </div>
                           </div>
                        </form>
                     </>
                  ) : breadcrumbsActive === 2 ? (
                     <>
                        <div className="o-form-libranzas__breadcrumbs__item">
                           <div>
                              <p>
                              Antes de guardar el formulario, verifica
                              cuidadosamente la información ingresada. La
                              precisión es clave. <br></br>{" "}
                              <strong>
                                 ¡Revisa y confirma todo para asegurarte de que
                                 esté correcto!
                              </strong>
                              </p>
                           </div>
                        </div>
                        <div className="o-form-libranzas__breadcrumbs__line">
                           <br />
                           <br />
                        </div>
                        <div className="o-form-libranzas__stepper1">
                           <div className="o-form-libranzas__stepper1__box1">
                              <p>Recolección de información</p>
                           </div>
                           <div className="o-form-libranzas__stepper1__box">
                              <p>Carga de documentos</p>
                           </div>
                        </div>
                        <div className="o-form-libranzas__download">
                           <Accordion
                              showCheck={
                                 verifyAttachmentComercio &&
                                 verifyAttachmentLibranzas
                              }
                              title="Cargar documentos"
                              formValues={formValues}
                              setFormValues={setFormValues}
                              setVerify={setVerify}
                              setVerifyAttachments={setVerifyAttachments}
                              {...settersProps}
                              step={1}
                           />

                           <div className="o-form-libranzas__form__button">
                              <Button
                                 id="buttonVideoLink"
                                 type="button"
                                 href="#"
                                 classname={"primary "}
                                 text="Anterior"
                                 isDisabled={false}
                                 gtmMark={`gtm${normalizeTwoWords(
                                    "Libranzas"
                                 )}${normalizeTwoWords("Anterior")}Btn`}
                                 onClickAction={() => setBreadcrumbsActive(1)}
                              />
                              <Button
                                 id="buttonVideoLink"
                                 type="button"
                                 href="#"
                                 classname={"secondary "}
                                 text="Finalizar"
                                 isDisabled={
                                    !verifyAttachmentLibranzas ||
                                    !verifyAttachmentComercio
                                 }
                                 gtmMark={`gtm${normalizeTwoWords(
                                    "Libranzas"
                                 )}${normalizeTwoWords("Finalizar")}Btn`}
                                 onClickAction={() => {
                                    sendEmail();
                                 }}
                              />
                           </div>
                        </div>
                     </>
                  ) : (
                     <>
                        <div className="o-form-libranzas__succesful">
                           <img
                              className="o-form-libranzas__succesful__image"
                              src={Check}
                              alt="Green Check"
                              width="64px"
                              height="64px"
                           />
                        </div>
                        <div className="o-form-libranzas__succesful__text">
                           <p>¡Felicitaciones!</p>
                        </div>
                        <div className="o-form-libranzas__succesful__text">
                           <h2>Tu formulario ha sido enviado exitosamente</h2>
                        </div>
                        <div className="o-form-libranzas__succesful__text">
                           <span className="o-form-libranzas__succesful__text__small">
                              Próximamente, enviaremos el documento al
                              representante legal para su firma. Una vez
                              firmado, será revisado y aprobado. Mantente atento
                              a tu correo electrónico, allí recibirás la
                              notificación de aprobación.
                           </span>
                        </div>
                        <div className="o-form-libranzas__succesful__text">
                           <Button
                              type="button"
                              classname={"secondary "}
                              text="ENTENDIDO"
                              isDisabled={false}
                              onClickAction={() =>
                                 (window.location.href =
                                    'https://www.comfama.com/finanzas/creditos-con-proposito/libranza-digital/')
                              }
                           />
                        </div>
                     </>
                  )}
               </div>
            )}
         </GlobalContext.Consumer>
      </>
   );
};

export default formLibranzas;
