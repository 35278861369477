import React, { useState } from 'react';
import { ReferenciaContenido } from "../../../interfaces/ReferenciaContenido";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { OptimizeImage } from "../../../shared/utils/stringUtilities";
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import "./imagesSwitcher.scss"

const ImagesSwitcher = (props: ReferenciaContenido) => {

    const [selectedImage, setSelectedImage] = useState<number | null>(window.screen.availWidth > 960 ? 0 : null);
    const [active, setActive] = useState<number | null>(window.screen.availWidth > 960 ? 0 : null);

    const Bold = (children:any) => (
        <h4 className="o-images-switcher__description__text--bold">{children}</h4>
    );

    const Text = (children:any) => (
        <p className="o-images-switcher__description__text">{children}</p>
    )

    const textOptions = {
        renderMark: {
            [MARKS.BOLD]: (text:any) => Bold(text)
        },
        renderNode: {
            [BLOCKS.PARAGRAPH]: (_node:any, children:any) => Text(children)
        },
    };

    const clickFunction = (i) => {
        if (window.screen.availWidth > 960 && i === null) {
            return;
        } else if (window.screen.availWidth < 960 && i !== null) {
            const yOffset = -80; 
            const element = document.querySelectorAll(`.tab-${i}`)[0];
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
        }
        setSelectedImage(null)
        setActive(i)
    }

    const overFunction = (i) => {
        if (window.screen.availWidth > 960) {
            setSelectedImage(i)
        }
    }
    const outFunction = (non) => {
        if (window.screen.availWidth > 960) {
            setSelectedImage(non)
        }
    }

	return (
		<div className='o-images-switcher'>
            {
                props.descripcion ? 
                    <div className='o-images-switcher__description'>
                        {documentToReactComponents(props.descripcion.json, textOptions)}
                    </div>
                : null
            }
            <div className='o-images-switcher__content'>
                <div className="o-images-switcher__content__items">
                    <div className="o-images-switcher__content__items__title">
                        <h2 className="o-images-switcher__content__items__title__text">{props.tituloGeneral}</h2>
                    </div>
                    {
                        props.referenciaPlantillaContenido.map((button, i) => (
                            <div className="o-images-switcher__content__items__buttons" key={i}>
                                {selectedImage === i || active === i ? 
                                    <div className="o-images-switcher__content__items__buttons__button" style={{backgroundColor: button.tipoTitulo}}
                                        onClick={() => clickFunction(active === i ? null : i)}
                                        onMouseOut={() => setSelectedImage(null)}
                                    >
                                        <div className="o-images-switcher__content__items__buttons__button__arrow --mobile" style={{ color: "#FFF", transform: "rotate(180deg)" }}>
                                            <i className="icon-angle-down"></i>
                                        </div>
                                        <div className="o-images-switcher__content__items__buttons__button__text"  style={{color: "#FFF"}}>
                                            {button.tituloBoton}
                                        </div>
                                        <div className="o-images-switcher__content__items__buttons__button__icon">
                                            <img loading="lazy" src={button.imagenPrincipal[3].file.url} />
                                        </div>
                                    </div>
                                :
                                    <div className={`o-images-switcher__content__items__buttons__button tab-${i}`}
                                        onMouseOver={() => overFunction(i)}
                                        onMouseOut={() => outFunction(null)}
                                        onClick={() => clickFunction(i)}
                                    >
                                        <div className="o-images-switcher__content__items__buttons__button__arrow --mobile">
                                            <i className="icon-angle-down"></i>
                                        </div>
                                        <div className="o-images-switcher__content__items__buttons__button__text">
                                            {button.tituloBoton}
                                        </div>
                                        <div className="o-images-switcher__content__items__buttons__button__icon">
                                            <img loading="lazy" src={button.imagenPrincipal[2].file.url} />
                                        </div>
                                    </div>
                                }
                                {
                                    active === i ? 
                                        <div className="o-images-switcher__content__items__buttons__img --mobile">
                                            <img loading="lazy" src={button.imagenPrincipal[1].file.url} alt={button.tituloBoton} />
                                        </div>
                                    : null
                                }
                            </div>
                        ))
                    }
                </div>
                <div className="o-images-switcher__content__img --desktop">
                    {
                        props.referenciaPlantillaContenido.map((image, i) => (
                            <div className={`o-images-switcher__content__img__img ${active !== i ? " --disabled" : ""}`}  key={i}>
                                <img loading="lazy" 
                                    src={OptimizeImage(image.imagenPrincipal[0].file.url, image.imagenPrincipal[0].file.contentType, 768)}
                                    alt={image.tituloBoton}
                                />
                            </div>
                        ))
                    }
                </div>
            </div>
		</div>
)}

export default ImagesSwitcher
