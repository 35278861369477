import React from "react";
import concatCx from "../../../utils/concatCx";
import "./select.scss"

type SelectProps = React.ComponentProps<"select"> & {
  children: React.ReactElement | React.ReactElement[];
};

const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
  (props: SelectProps, ref) => {
    const { children, ...rest } = props;
    const compClass = "cfmui-select";
    let attributes = {
      ...rest,
      className: concatCx(compClass, props.className)
    };
    React.Children.map(children, (child: React.ReactElement) => {
      if (child.type !== "option")
        throw new Error("Only option element supported!");
    });
    return (
      <select ref={ref} {...attributes}>
        {children}
      </select>
    );
  }
);

export default Select;
