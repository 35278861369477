import React, { useEffect, useState } from 'react'
import { ReferenciaContenido } from "../../../interfaces/ReferenciaContenido"
import Description from "../../atoms/description/description";
import Switcher from "../../atoms/switcher/switcher";
import ReviewSlider from '../../molecules/review-slider/reviewSlider';
import { normalizeTwoWords } from "../../../shared/utils/stringUtilities";
import './botoneraHome.scss'

const BotoneraHome = (props:ReferenciaContenido) => {

    const [checked, setChecked] = useState(false)
    const [people, setPeople]: any = useState([])
    const [companies, setCompanies]: any = useState([])

    useEffect(() => {
        props.referenciaPlantillaContenido.forEach(card => {
            if (card.tipoTitulo === "personas") {
                setPeople(people => [...people, card])
            } else {
                setCompanies(companies => [...companies, card])
            }
        })
        setTimeout(() => {
            let arrows = document.querySelectorAll('.slick-arrow')
            Array.from(arrows).forEach(arrow => {
                arrow.setAttribute('tabIndex', '-1')
            })
        }, 2000);
    }, [])

    return (
        <div className='o-botonera-home'>
            <div className="o-botonera-home__upper">
                <h2 className="o-botonera-home__upper__title">{props.tituloGeneral}</h2>
                { props.descripcion &&
                    <div className="o-botonera-home__upper__description">
                        <Description
                            description={JSON.stringify(props.descripcion.json)}
                            noCentered
                        />
                    </div>
                }
            </div>
            <div className="o-botonera-home__switcher">
                <Switcher checked={checked} handleClick={setChecked} />
            </div>
            <div className="o-botonera-home__carousel">
                <ReviewSlider 
                    reviews={checked ? companies : people}
                    type="card-bh"
                    gtmClass={`${normalizeTwoWords(props.nombreCategoria)}${normalizeTwoWords(props.tituloGeneral)}`}
                    slides={{ show:4, rows:1, s1560:3 }}
                />
            </div>
        </div>
    )
}

export default BotoneraHome
