import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { IImageCardContainerProps } from "../imageCardContainer";

function imageCardContainerAdapter(data: any): IImageCardContainerProps {
	return ({
		title: data.tituloGeneral,
		cards: data?.referenciaPlantillaContenido?.map(card => (
			{
				image: card.imagenPrincipal[0].file.url,
				alt: card.tituloDescripcion,
				title: card.tituloBanner,
				description: documentToReactComponents(card.descripcion.json),
				redirection: card.redireccionBoton,
				buttonText: card.tituloBoton,
			}
		)) ?? [],
		redirection: data.redireccionBoton,
		buttonText: data.tituloBoton,
	})
}
  
export default imageCardContainerAdapter;