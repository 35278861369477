import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Presenter from "../../molecules/presenter/presenter";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { ReferenciaContenido } from "../../../interfaces/ReferenciaContenido";
import useBrowserMode from '../../../shared/hooks/useBrowserMode';
import Img from "gatsby-image";
import YoutubePlayer from "../../molecules/youtube-player/youtubePlayer";
import { OptimizeImage } from "../../../shared/utils/stringUtilities";
import "./carousel.scss";

const Carousel = (props:ReferenciaContenido) => {

   const [isEdgeOld, setIsEdgeOld] = useState<boolean>(false);
   const [slider, setSlider] = useState<any>();
   const backNext = props.tituloBoton.split(',');
   const [sliderIndex, setSliderIndex] = useState<number>(0);
   const { isBrowser, navigator } = useBrowserMode();

   const goToNext = () => {
      slider.slickGoTo(sliderIndex+1);
   }

   const goToBack = () => {
      slider.slickGoTo(sliderIndex - 1);
   }

   const isActiveBack = ( _true: any, _false: any ) => sliderIndex > 0 ? _true : _false;
   const isActiveNext = ( _true: any, _false: any ) => sliderIndex < props.referenciaPlantillaContenido.length-1 ? _true : _false;

   const settings = {
      arrows: false,
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      beforeChange: (_: number, next: number) => setSliderIndex(next)
   };

   const activeResizeAllMobile = () => {
      if(isBrowser) {
         // We execute the same script as before
         let vh = window.innerHeight * 0.01;
         document.documentElement.style.setProperty('--vh', `${vh}px`);
      }
   }

   useEffect( ()=> {
      try {
         if ( isBrowser ) {
            const indexEdge = navigator.appVersion.indexOf('Edge');
            if(indexEdge >= 0) {
               const edgeName = navigator.appVersion.substring(indexEdge);
               const edgeVersion = parseInt(edgeName.substring(edgeName.indexOf('/')+1));
               setIsEdgeOld(edgeVersion <= 19);
            }
            activeResizeAllMobile();
            window.addEventListener('resize', activeResizeAllMobile);
         }
      } catch (error) {
         setIsEdgeOld(false);
      }
   }, [])

   return(
      <div className='o-slider' >
         <Slider ref={ _slider => setSlider(_slider)} {...settings} className='o-slider__carrusel' >
            {props.referenciaPlantillaContenido.map( (value, index) => (
               <div className={`o-slider__carrusel__container${isEdgeOld ? ' o-slider__carrusel__container--edge':''}`} key={index}  >
                  {!value.youtubeId ? (
                     <Presenter 
                        className="o-slider__carrusel__container__content"
                        title={value.tituloBanner}
                        subtitle={value.descripcion.json.content && documentToReactComponents(value.descripcion.json)}
                        buttonName={value.tituloBoton}
                        buttonLink={value.redireccionBoton}
                        index={index+1}
                     />)
                     :
                     null
                  }
                  {value.youtubeId ? (
                     <div className="o-slider__carrusel__container__video">
                        <div className="--black__block--first"></div>
                        <YoutubePlayer
                           youtubeId={value.youtubeId}
                           id="slider__carrusel"
                        />
                        <div className="--black__block--last"></div>
                     </div>
                     )
                     :
                     (value.imagenPrincipal && value.imagenPrincipal[0].fluid) ?
                        <Img  className='o-slider__carrusel__container__cover'
                              fluid= { value.imagenPrincipal[0].fluid }
                              alt={ value.imagenPrincipal[0].file.fileName || "imagen carrusel"}
                        />
                        :
                        <img className='o-slider__carrusel__container__cover'
                           loading="lazy"
                           src= { OptimizeImage(value.imagenPrincipal[0].file.url, value.imagenPrincipal[0].file.contentType, 960) }
                           alt={ value.imagenPrincipal[0].file.fileName || "imagen carrusel"}
                        />
                  }
               </div>
            ) )}
         </Slider>
         {props.referenciaPlantillaContenido.length > 1 ?
         <div className='arrow-content' >
            <div className='arrows' >
               <div className='arrows-contain' >
                  <span
                     className={ `gtmHomeSupBannerAtras ${isActiveBack('direction active', 'direction')}` }
                     onClick={ isActiveBack(goToBack, undefined) } 
                  >{backNext[0]}</span>
                  <div 
                     className={ `gtmHomeSupBannerAtras ${isActiveBack('arrow icon-arrow-light-left active', 'arrow icon-arrow-light-left')}` }
                     onClick={ isActiveBack(goToBack, undefined) } 
                  />
                  <div 
                     className={ `gtmHomeSupBannerAdelante ${isActiveNext('arrow icon-arrow-light-right active', 'arrow icon-arrow-light-right')}` }
                     onClick={ isActiveNext(goToNext, undefined) }
                  />
                  <span 
                     className={ `gtmHomeSupBannerAdelante ${isActiveNext('direction active', 'direction')}` } 
                     onClick={ isActiveNext(goToNext, undefined) }  
                  >{backNext[1]}</span>
               </div>
               <span 
                  className='direction-mobile active' 
                  onClick={ isActiveNext(goToNext, goToBack) }
               >{ isActiveNext(backNext[1], backNext[0]) }</span>
               <span className='counter'>{sliderIndex+1} / {props.referenciaPlantillaContenido.length}</span>
            </div>
            <div className='text' >{props.referenciaPlantillaContenido[sliderIndex].tituloDescripcion}</div>
         </div>
         :
         null}
      </div>
   )
}

export default Carousel;
