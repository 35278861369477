import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import React from 'react'
import { ReferenciaContenido } from '../../../interfaces/ReferenciaContenido'
import Button from '../../atoms/button/button';
import './imageCardContainer.scss'

export interface IImageCardContainerProps {
	title: string;
	cards: [
		{
			image: string;
			alt: string;
			title: string;
			description: React.ReactNode;
			redirection: string;
			buttonText: string;
		}
	],
	redirection: string;
	buttonText: string;
}

const ImageCardContainer = (props: IImageCardContainerProps) => {

	const { title, cards, redirection, buttonText } = props;
	
  return (
		<div id={title} className="o-image-card-container">
			{title && <h2 className="o-image-card-container__title">{title}</h2>}
			<div className="o-image-card-container-content">
				{
					cards.map((card, idx) => (
						<div className="o-image-card-container-content__card" key={idx}>
							<img className="o-image-card-container-content__card__image" src={card.image} alt={card.alt} />
							<div className='o-image-card-container-content__card__container'>
								<h3 className="o-image-card-container-content__card__title">{card.title}</h3>
							 	{card.description && <div className="o-image-card-container-content__card__text">{card.description}</div>}
								<Button classname='o-image-card-container-content__card__button' isDisabled={false} gtmMark='primary' type='link' href={card.redirection || '#'} text={card.buttonText}></Button>
							</div>
						</div>
					))
				}
			</div>
			<Button classname='o-image-card-container-content__button' isDisabled={false} gtmMark='secondary' type='link' href={redirection || '#'}  target='_blank' text={buttonText}></Button>
		</div>	
  )
}

export default ImageCardContainer