import React from "react";
import "./simpleDescription.scss";
import { ReferenciaContenido } from "../../../interfaces/ReferenciaContenido";
import Button from "../../atoms/button/button";
import Description from "../../atoms/description/description";

const SimpleDescription = (props: ReferenciaContenido) => {

   return (  
        <div className="m-simple-description">
            {props.tituloGeneral ? <h2 className="m-simple-description__title">{props.tituloGeneral}</h2> : null}
            {props.descripcion.json.content ?
               <div className="m-simple-description__description">
                  <Description
                     description={JSON.stringify(props.descripcion.json)}
                     noCentered={true}
                  />
               </div>
               : null
            }
            {props.tituloBoton &&
               <div className="m-simple-description__button">
                  <Button
                     id='buttonDescription'
                     type='link'
                     href={props.redireccionBoton}
                     classname={'secondary '}
                     text={props.tituloBoton}
                     isDisabled={false}
                  />
               </div>
            }
        </div>
)}

export default SimpleDescription
