import axios from "axios";

export const getAccessToken = () => {
    const apiToken = process.env.URL_TOKENIZER || '';
    const apiTokenKey = process.env.URL_TOKENIZER_APIKEY || '';
    const apiTokenOrigin = process.env.URL_TOKENIZER_ORIGIN || '';
    const bodyToken = { "action": "getJWT" };

    var headers = {
        Apikey: apiTokenKey,
        Origin: apiTokenOrigin,
        "Content-Type": "application/json",
    };

    let responseToken = axios.post(
        apiToken,
        bodyToken,
        { headers }
    );

    return responseToken;

};