import React from "react"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Button from '../../atoms/button/button';
import StepDescription from '../../molecules/step-description/step-description'
import SubsectionContainer from "../../molecules/subsection-container/subsectionContainer";
import LayoutSlider from "../../templates/layout-slider/layoutSlider";
import { ReferenciaContenido } from "../../../interfaces/ReferenciaContenido";
import { OptimizeImage } from "../../../shared/utils/stringUtilities";
import "./videoService.scss";

const VideoService = (props:ReferenciaContenido) => {

   return (
      <div className='o-videoservicontainer-body'>
         {
            props.imagenPrincipal || props.redireccionBoton ?
            <div className='o-videoservicontainer-topsection'>
               <div className='o-videoservicontainer-infocontainer'>
                  <h2 className='o-videoservicontainer-title'>{props.tituloGeneral}</h2>
                  {props.descripcion && <div className='o-videoservicontainer-description'>{props.descripcion.json.content && documentToReactComponents(props.descripcion.json)}</div>}
                  <div className="o-videoservicontainer__buttonBox">
                     <Button
                        type={'link'}
                        isDisabled={false}
                        text={props.tituloBoton}
                        href={props.redireccionBoton}
                        classname={'o-videoservicontainer__button'}
                        strictCss={true}
                        gtmMark="gtm_inscripcion_ruta_aprendizaje"
                     />
                  </div>
               </div>
               <div className='o-videoservicontainer-videocontainer' >
                  <div className='o-videoservicontainer-videoresponsive'>
                     {  props.imagenPrincipal ? 
                        <img   
                           className='o-videoservicontainer-videoresponsive-img'
                           src={OptimizeImage(props.imagenPrincipal.file.url, props.imagenPrincipal.file.contentType, 768)}
                           alt={props.imagenPrincipal.title}
                           loading="lazy"
                        />  
                        : props.redireccionBoton ?
                        <iframe 
                           src={props.redireccionBoton}
                           frameBorder={"0"} 
                           allow={"accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"}
                           allowFullScreen={true}
                        /> : <></>
                     }
                  </div>
               </div>
            </div>
            :
            <div className="o-videoservicontainer-aprendizaje">
               {props.referenciaPlantillaContenido && 
                  <div className="o-videoservicontainer-aprendizaje">
                     {
                        props.tituloGeneral &&
                        <div className="o-videoservicontainer-aprendizaje-title">
                           <h2>{props.tituloGeneral}</h2>
                        </div>
                     }
                     {
                        props.descripcion &&
                        <div className="o-videoservicontainer-aprendizaje-description">
                           <p>{props.descripcion.json.content[0].content[0].value}</p>
                        </div>
                     }
                  </div> 
               } 
            </div>
         }

         {(props && props.nombreCategoria === "Trabajo con propósito") ? 
            <SubsectionContainer {...props}/>
            :
            <div className={`o-videoservicontainer-stepsContainer`}>
               { props.referenciaPlantillaContenido &&
                  <LayoutSlider customClass={'o-videoservicontainer__layout-slider'} widthRelative={true} nSlides={3} showDots={true} hideArrowDisabled={true} >
                     { props.referenciaPlantillaContenido.map((elem, i) => 
                        (
                           <StepDescription
                              key={i}
                              number={i + 1}
                              title={elem.tituloBanner}
                              description={(elem.descripcion && elem.descripcion.json.content) && documentToReactComponents(elem.descripcion.json)}
                           />
                        )
                     )}
                  </LayoutSlider>
               }
            </div>
         }
      </div>

   )
}


export default VideoService;
