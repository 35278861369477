import React, { useState, useEffect, useRef } from 'react';
import { ReferenciaContenido } from '../../../interfaces/ReferenciaContenido';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import useBrowserMode from '../../../shared/hooks/useBrowserMode';
import useDirectionScroll from '../../../shared/hooks/useDirectionScroll';
import { normalizeTwoWords, OptimizeImage } from "../../../shared/utils/stringUtilities";
import Img from "gatsby-image";
import './history.scss';

const History = (props:ReferenciaContenido) => {

   const [indexTag, setIndexTag] = useState(0);
   const { isBrowser, window, document } = useBrowserMode();
   const [isScrollActive, setIsScrollActive] = useState<boolean>(false);
   const [upScroll, setUpScroll] = useState(false);
   const [hideTags, setHideTags] = useState('');
   const refComponent = useRef(null);
   const { lastDirection } = useDirectionScroll();
   const gtmTagsDinamic = `gtm${normalizeTwoWords(props.nombreCategoria)}${normalizeTwoWords(props.tituloGeneral)}`;
   const tags = props.referenciaPlantillaContenido.filter(
      value => value.tituloBoton
   ).map( value => ({ tag: value.tituloBoton, title: value.tituloBanner } ) );

   let timerScroll:any;

   const onScrollAction = () => {
      window.clearTimeout( timerScroll );
      timerScroll = setTimeout(() => {
         if( upScroll ) {
            window.scroll(0, window.pageYOffset - 180 );
            setUpScroll(false);
         }
         const el: HTMLElement = refComponent.current || new HTMLElement();
         const boundElement = el.querySelector('.o-history__container__zone')?.getBoundingClientRect();
         let tagSelect = 0;
         const items = el.querySelectorAll('.o-history__container__content__item--principal');
         for( let i = 0; i < items.length; i++ ) {
            if ( items.item(i).getBoundingClientRect().top <= 190 ) {
               tagSelect = i;
            } else {
               setIndexTag(tagSelect);
               setIsScrollActive( (boundElement || {top}).top < 160 );
               return;
            }
         }
         setIndexTag(tagSelect);
         setIsScrollActive( (boundElement || {top}).top < 160 );
      }, 66);
   }

   useEffect(() => {
      isBrowser && document.addEventListener('scroll', onScrollAction);
      return () => {
         isBrowser && document.removeEventListener('scroll', onScrollAction);
      }
   }, [upScroll] );

   const goToDecade = (value:string) => {
      const el: HTMLElement = refComponent.current || new HTMLElement();
      const tag = el.querySelector(`.D-${value}`);
      setUpScroll(true);
      tag?.scrollIntoView();
   }

   const Bold = (children:any) => (
      <b className="o-history__container__content__item__body__text--bold">{children}</b>
   );
   
   const Text = (children:any) => (
      <p className="o-history__container__content__item__body__text">{children}</p>
   )

   const List = (children:any) => (
      <li className="o-history__container__content__item__body__list">{children}</li>
   )
   
   const options = {
      renderMark: {
         [MARKS.BOLD]: (text:any) => Bold(text)
      },
      renderNode: {
         [BLOCKS.LIST_ITEM]: (_node:any, children:any) => List(children),
         [BLOCKS.PARAGRAPH]: (_node:any, children:any) => Text(children)
      },
   };
   
   function getOffset(el) {
      const rect = el.getBoundingClientRect();
      return {
        bottom: rect.bottom + window.scrollY
      };
   }

   const toggleVisibility = () => {
      let history = document.getElementById("tags_zone")
      if (window.pageYOffset >= getOffset(history).bottom - 600) {
         setHideTags("hide-tags")
      } else {
         setHideTags("")
      }
   }

   document.addEventListener('scroll', toggleVisibility);

   useEffect(() => {
      toggleVisibility();
   }, [window.pageYOffset])

   return (
      <div className='o-history' ref={refComponent} >
         <article className='o-history__header' >
            <h2 className='o-history__header__h2' >{props.tituloGeneral}</h2>
            {props.descripcion.json.content && documentToReactComponents(props.descripcion.json)}
         </article>
         <div id="tags_zone" className='o-history__container' >
            <div className="o-history__container__zone" >
               <ul className={`${hideTags} o-history__container__zone__tags${isScrollActive ?' o-history__container__zone__tags--freezer' : ''}`} >
                  {tags.map( (tag, key) => (
                     <li 
                        key={key} 
                        className={`${gtmTagsDinamic}${normalizeTwoWords(tag.tag)} o-history__container__zone__tags__tag${key === indexTag ? ' o-history__container__zone__tags__tag--active' : ''}`} 
                        onClick={() => goToDecade(tag.tag) }
                     >{tag.tag}</li>
                  ))}
               </ul>
               <div className={`${hideTags} o-history__container__zone__box${isScrollActive ?' o-history__container__zone__box--freezer' : ''}${lastDirection == 1 ? ' o-history__container__zone__box--move':'' }`} >
                  <button 
                     disabled={indexTag <= 0} 
                     className='o-history__container__zone__box__circle'
                     onClick={ () => goToDecade(tags[indexTag-1].tag) }
                  />
                  {tags.map( (tag, key) => (
                     <span 
                        key={key} 
                        className={`o-history__container__zone__box__item${key === indexTag ? ' o-history__container__zone__box__item--active' : ''}`} 
                     >{tag.title}</span>
                  ))}
                  <button 
                     disabled={indexTag+1 >= tags.length}  
                     className='o-history__container__zone__box__circle'
                     onClick={ () => goToDecade(tags[indexTag+1].tag) } 
                  />
               </div>
            </div>
            <div className='o-history__container__content' >
               {props.referenciaPlantillaContenido.map( (value, key) => (
                  <section 
                     key={key}
                     className={`o-history__container__content__item${value.tituloBoton ? ` o-history__container__content__item--principal D-${value.tituloBoton}`: ''}`}  
                  >
                     {!value.tituloBoton && <span className='o-history__container__content__item__span' >{value.tituloBanner}</span>}
                     <figure className='o-history__container__content__item__cover' >
                        {value.imagenPrincipal[0].fluid ?
                           <Img  className="o-history__container__content__item__cover__img"
                              fluid= { value.imagenPrincipal[0].fluid }
                              alt={ value.imagenPrincipal[0].title || "imagen historia"}
                           />
                           :
                           <img  className="o-history__container__content__item__cover__img"
                              src={OptimizeImage(value.imagenPrincipal[0].file.url, value.imagenPrincipal[0].file.contentType, 738)}
                              alt={ value.imagenPrincipal[0].title || "imagen historia"}
                              loading="lazy"
                           />
                        }
                     </figure>
                     <article className='o-history__container__content__item__body' >
                        {value.tituloBoton && <h3 className='o-history__container__content__item__body__h3' >{value.tituloBanner}</h3>}
                        {value.descripcion.json.content && documentToReactComponents(value.descripcion.json, options)}
                     </article>
                  </section>
               ))}
            </div>
         </div>
      </div>
   )
}

export default History;
