import React from "react";

import { normalizeTwoWords, formatImageAlt, findRel } from "../../../shared/utils/stringUtilities";

import "./coverPage.scss";

interface CoverPageInterface {
  coverpage: {
    redireccionBoton: string;
    imagenPrincipal: [
      {
        file: {
          url: string;
        };
        title: string;
      }
    ];
  };
  gtmMark: string;
}

const CoverPage = (props: CoverPageInterface) => {
  const { coverpage, gtmMark } = props;

  return (
    <a href={coverpage.redireccionBoton} className="a-coverp" rel={findRel(coverpage.redireccionBoton)}>
      <div className={'a-coverp__content ' + `gtm${gtmMark}${normalizeTwoWords(coverpage.imagenPrincipal[0].title) }`}>
        <img
          className={`gtm${gtmMark}${normalizeTwoWords(coverpage.imagenPrincipal[0].title) }`}
          src={
            coverpage.imagenPrincipal[0] &&
            coverpage.imagenPrincipal[0].file.url
          }
          alt={
            coverpage.imagenPrincipal[0] && formatImageAlt(coverpage.imagenPrincipal[0].title)
          }
        />
      </div>
    </a>
  );
};

export default CoverPage;