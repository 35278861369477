import React from "react";
import { ReferenciaContenido } from "../../../interfaces/ReferenciaContenido";
import RichtextDescription from "../../atoms/richtext-description/richtextDescription";

const Richtext = (props: ReferenciaContenido) => {

    return (
        <RichtextDescription descripcion={props.descripcion} />
    )
}

export default Richtext;
