import React, { useEffect, useState } from "react";
import Button from "../../atoms/button/button";
import ElementCourse from "../element-course/elementCourse";
import { normalizeTwoWords, findTarget, findRel, OptimizeImage } from "../../../shared/utils/stringUtilities";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Img from "gatsby-image";
import Slider from "react-slick";
import './coursesGroup.scss'

const CoursesGroup = (props: CoursesGroupInterface) => {
	const {
		title,
      contentCourses,
      gtmMark,
      btnTitle,
      btnLink
	} = props;

	const sliderSettings = {
		arrows: false,
		dots: true,
		infinite: false,
		speed: 200,
		slidesToShow: 2,
		slidesToScroll: 1
	};

	const [mainCourseId, setMainCourseId] = useState(0);
	
	useEffect(() => {
		contentCourses.map((elem, i) => {
			elem.esDestacado && setMainCourseId(i);
		});
	},[])

	const elementItem = (_elem, _i) => {
		return ( 
			<ElementCourse
				title= { _elem.tituloPrincipal } 
				info= { _elem.descripcion }
				imageSrc= { _elem.imagenPrincipal ? _elem.imagenPrincipal : "" }
				imageAlt= { _elem.imagenPrincipal && _elem.imagenPrincipal.title }
				key= {`courseSide${_i}`}
				gtmMark= {`${gtmMark}${normalizeTwoWords(title)}Laterales${normalizeTwoWords(_elem.tituloPrincipal)}`}
				linkTo= { _elem.redireccionBoton }
			/>
		)
	}

	return (
		<div className='m-courses-group'>
			<p className='m-courses-group__title show-on-mobile'> 
				{title} 
			</p>
			<div className='m-courses-group__cont'>	
				<a href={contentCourses[mainCourseId].redireccionBoton} 
					className={ `m-courses-group__dominant ${gtmMark}${normalizeTwoWords(title)}Destacado` }
					target={ findTarget(contentCourses[mainCourseId].redireccionBoton) }
					rel={findRel(contentCourses[mainCourseId].redireccionBoton)}  
				>
					{contentCourses[mainCourseId].imagenPrincipal && contentCourses[mainCourseId].imagenPrincipal.fluid ?
						<Img  className={ `${gtmMark}${normalizeTwoWords(title)}Destacado` }
								fluid= {contentCourses[mainCourseId].imagenPrincipal && contentCourses[mainCourseId].imagenPrincipal.fluid }
								alt={ contentCourses[mainCourseId].imagenPrincipal && contentCourses[mainCourseId].imagenPrincipal.title }
						/>
						:
						<img 
							className={ `${gtmMark}${normalizeTwoWords(title)}Destacado` }
							src= { contentCourses[mainCourseId].imagenPrincipal && 
									OptimizeImage(contentCourses[mainCourseId].imagenPrincipal.file.url, contentCourses[mainCourseId].imagenPrincipal.file.contentType, 768)}
							alt= { contentCourses[mainCourseId].imagenPrincipal.title || "Imagen destacado cursos" }
							loading="lazy"
						/>
					}
					<div className='m-courses-group__dominant-info'>
						<p className={`m-courses-group__dominant-title ${gtmMark}${normalizeTwoWords(title)}Destacado`}> 
							{ contentCourses[mainCourseId] && 
							contentCourses[mainCourseId].tituloPrincipal } 
						</p>
						<span className={`m-courses-group__dominant-txt ${gtmMark}${normalizeTwoWords(title)}Destacado`}> 
							{ (contentCourses[mainCourseId] && contentCourses[mainCourseId].descripcion.json.content) &&
							documentToReactComponents( contentCourses[mainCourseId].descripcion.json ) } 
						</span>
					</div>
				</a>
				<div className='m-courses-group__others'>
					<div className='m-courses-group__others-cont hide-on-mobile'>
						<div>
							{ contentCourses && contentCourses.map((elem, i) => (
                        i !== mainCourseId &&
								elementItem(elem, i)
							))}
						</div>
					</div>
					<div className='show-on-mobile'>
						<Slider {...sliderSettings} className='m-courses-group__slider' >
							{ contentCourses && contentCourses.map((elem, i) => (
								i !== mainCourseId &&
								elementItem(elem, i)
							))}
							<div></div>
						</Slider>
					</div>
					<div className='m-courses-group__others-btn'>
						<Button 
							type= { "link" } 
							classname= {`primary`}
							text= { btnTitle }
							href= { btnLink } 
							isDisabled= {false}
							gtmMark= {`${gtmMark}${normalizeTwoWords(title)}VerTodo`}
						/>
					</div>
				</div>
			</div>
			
		</div>
	)
}

interface CoursesGroupInterface {
	title: string,
   contentCourses: any,
   gtmMark?: string,
   btnTitle?: string,
   btnLink?: string
}

export default CoursesGroup
