import React, { useEffect } from "react"
import useBrowserMode from "../../../shared/hooks/useBrowserMode";
import { findRel } from "../../../shared/utils/stringUtilities";
import './step-description.scss'


const StepDescription = (props: Interfaz) => {

   const {
      number,
      title,
      description,
   } = props;

   const { isBrowser } = useBrowserMode();

   useEffect(() => {
      if (isBrowser) {
         const cardsText = document.querySelectorAll('.m-stepDescription__details-desc a');

         cardsText.forEach(link => {
             const anchor = link as HTMLAnchorElement;
             anchor.setAttribute('target', '_blank');
             anchor.setAttribute('rel', findRel(anchor.href));
         });
      }
   }, [])

   return (
      <div className='m-stepDescription__details-info'>
         <div className='m-stepDescription__details-numbercontainer'>
            <p className='m-stepDescription__details-number'>{number}</p>
         </div>
         <h3 className='m-stepDescription__details-title'>
            {title}
         </h3>
         { description &&
            <div className='m-stepDescription__details-desc'>
               {description}
            </div>
         }
      </div>
   )
}

interface Interfaz {
   number: number,
   title: string,
   description: any,
}

export default StepDescription
