import React from 'react';
import { ReferenciaContenido } from "../../../interfaces/ReferenciaContenido";
import './spotifyListen.scss'
const SpotifyListen = (props: ReferenciaContenido) => {

   return (
      <div className='a-spotifybox'>
         <div className='a-spotifybox-container'>
            <iframe className='a-spotifybox-container-link' src={props.redireccionBoton}
            frameBorder="0" 
            allowTransparency= {true}
            allow="encrypted-media" 
         />
         </div>
      </div>
   )
}
export default SpotifyListen;
