import React, { useState } from "react";
import "./how-it-works.scss";
import GlobalContext from "../../../contexts/globalContext";
import Img from "gatsby-image";
import { ReferenciaContenido } from "../../../interfaces/ReferenciaContenido";
import Button from "../../atoms/button/button";

const HowItWorksSection = (props: ReferenciaContenido) => {
  const [step, setStep] = useState(0);
  const howtoworkData = props;

  return (
    <GlobalContext.Consumer>
      {(data) => (
        <div className="m-how-it-works">
          <div
            className={`m-how-it-works__steps ${
              props.titulo.split(">")[0] === "Empresas red de amor "
                ? "enterprises"
                : ""
            }`}
          >
            <div className="m-how-it-works__steps__rotator">
              <div
                className={`m-how-it-works__steps__rotator__up-arrow arrow ${
                  props.titulo.split(">")[0] === "Empresas red de amor "
                    ? "enterprises"
                    : ""
                }`}
                onClick={() => {
                  if (step > 0) {
                    setStep(step - 1);
                  }
                }}
              ></div>
              <p className="m-how-it-works__steps__rotator__step">
                {step + 1 < 10 ? "0" + (step + 1) : step + 1}
              </p>
              <div
                className={`m-how-it-works__steps__rotator__down-arrow arrow ${
                  props.titulo.split(">")[0] === "Empresas red de amor "
                    ? "enterprises"
                    : ""
                }`}
                onClick={() => {
                  if (
                    step <
                    howtoworkData?.referenciaPlantillaContenido?.length - 1
                  ) {
                    setStep(step + 1);
                  } else {
                    setStep(0);
                  }
                }}
              ></div>
            </div>
            <div className="m-how-it-works__steps__description">
              <h2
                className={`m-how-it-works__steps__description__step-title ${
                  props.titulo.split(">")[0] === "Empresas red de amor "
                    ? "enterprises"
                    : ""
                }`}
              >
                {howtoworkData.tituloGeneral}
              </h2>
              <p className="m-how-it-works__steps__description__step-description">
                {
                  howtoworkData?.referenciaPlantillaContenido[step]?.descripcion
                    ?.json?.content[0]?.content[0]?.value
                }
              </p>
              <div className="m-how-it-works__steps__description__btn">
                {howtoworkData?.referenciaPlantillaContenido[step]
                  .tituloBoton ? (
                  <Button
                    text={
                      howtoworkData?.referenciaPlantillaContenido[step]
                        ?.tituloBoton
                    }
                    classname={"a-button  secondary"}
                    type={ howtoworkData.nombreCategoria === "Empresas" ? "link": data.gigyaContext.gigyaAccount ? "link" : "button"}
                    isDisabled={false}
                    strictCss={false}
                    gtmMark={
                      data.gigyaContext.gigyaAccount
                        ? "gtmRedDeAmorSesionIniciadaCitaSinCobro"
                        : "gtmRedDeAmorCitaSinCobro"
                    }
                    href={
                      howtoworkData?.referenciaPlantillaContenido[step]
                        ?.redireccionBoton
                    }
                    onClickAction={data.gigyaContext.login}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="m-how-it-works__img hide-on-mobile">
            {howtoworkData?.imagenPrincipal?.fluid ? 
              <Img
                className="m-how-it-works__img"
                fluid={howtoworkData?.imagenPrincipal?.fluid}
                alt={
                  howtoworkData?.imagenPrincipal?.title
                    ? howtoworkData?.imagenPrincipal?.title
                    : "Como trabajamos"
                }
              />: 
              <img
                loading="lazy"
                className="m-how-it-works__img"
                src={howtoworkData?.imagenPrincipal?.file?.url}
                alt={
                  howtoworkData?.imagenPrincipal?.title
                    ? howtoworkData?.imagenPrincipal?.title
                    : "Como trabajamos"
                }
              />
            }
          </div>
        </div>
      )}
    </GlobalContext.Consumer>
  );
};

export default HowItWorksSection;
