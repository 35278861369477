import React, { useState}  from 'react';
import FilterButton from '../../atoms/filter-button/filterButton';
import './filterRegiones.scss';

// const getUnique = (items: string[], value: any) => {
//     return [...new Set(items.map(item => item[value]))]
// }

type FilterRegionesProps = {
    sedes: string[],
    regionChanger: any
}

function FilterRegiones({ sedes, regionChanger }: FilterRegionesProps) {
    const [chosen, setChosen] = useState("Valle de Aburrá");

    const handleChange = (region: string, active: boolean) => {
        setChosen(region);
        return !active;
      };

    const handleClick = (region: any) => {
        regionChanger(region);
        setChosen(region);
        handleChange(region, region === chosen);
    }

    let regiones: any[];
    regiones = sedes.map((region, index) => {
        return <FilterButton key={index} active={region === chosen} name={region} onClick={() => handleClick(region)} />
    })

    return (
        <div className="m-filter__btn__container">
            {regiones}
        </div>
    )
}

export default FilterRegiones;