import React from 'react'
import './cardButtonContainer.scss'

export interface ICardButtonContainer {
	title: string;
	cards: [
		{
			redirection?: string;
			image: string;
			description: string;
		}
	]
}

const CardButtonContainer = (props: ICardButtonContainer) => {

	const { title, cards } = props;

	return (
		<div id={title} className="o-card-button-container">
			{title && <h2 className="o-card-button-container__title">{title}</h2>}
			<div className="o-card-button-content">
				{
					cards.map((card, idx) => (
						<a className="o-card-button-content__card" href={card.redirection || '#'} target='_blank' key={idx}>
							<img className="o-card-button-content__card__image" src={card.image} alt={card.description} />
							<p className="o-card-button-content__card__text">{card.description}</p>
						</a>
					))
				}
			</div>
		</div>
	)
}

export default CardButtonContainer