import React from "react"
import './sectionContainer.scss'
import { ReferenciaContenido } from "../../../interfaces/ReferenciaContenido";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const SectionContainer = (props: ReferenciaContenido) => {
   const SectionInfo = (props: SubsectionInfoInterface) => {
      const {
         title,
         description,
         image,
      } = props;

      return (

         <div className='m-sectionInfo__details-info'>
            <div className='m-sectionInfo__details-img'>
               <img loading="lazy" className='m-sectionInfo__details-img-img' src={image && image.file.url}  alt={image.title} />
            </div>
            <div className='m-sectionInfo__details-text'>
               <p className='m-sectionInfo__details-text-title'>
                  {title}
               </p>

               <div className='m-sectionInfo__details-text-desc'>
                  {description.content && documentToReactComponents(description)}
               </div>
            </div>

         </div>
      )
   }

interface SubsectionInfoInterface {
   title: string,
   description: any,
   image: ImageInterface,
}

interface ImageInterface {
   title: string;
   file: {
      url: string
   };
}

   return (

      <div className='m-sectioncontainer__info-container-body'>
         <div className='m-sectioncontainer__info-container-titleContainer' >
            <h2>{ props.tituloGeneral}</h2>
         </div>
         <div className='m-sectioncontainer__info-container-featureContainer'>
            {
               props.referenciaPlantillaContenido.map((elem, i) => <SectionInfo key={`elem${i}`}
                  title={elem.tituloBanner}
                  description={elem.descripcion && elem.descripcion.json}
                  image={elem.imagenPrincipal && elem.imagenPrincipal[0]}
                  />)
            }
         </div>
      </div>

   )
}

export default SectionContainer;
