import React from "react";

import RichTextDescription from "../../atoms/richtext-description/richtextDescription";
import { ReferenciaContenido } from "../../../interfaces/ReferenciaContenido";

import "./articleContentDescription.scss";

const ArticleContentDescription = (props: ReferenciaContenido) => {
   const { autoresArticulo, etiquetasArticulo } = props;

   return (
      <div className="m-article-content-description">
         <RichTextDescription {...props} />
         {(autoresArticulo || etiquetasArticulo) && (
            <div className="m-article-content-description m-article-content-description__footer">
               <div className="m-article-content-description m-article-content-description__separator" />
               {autoresArticulo && (
                  <p>
                     Por:{" "}
                     {autoresArticulo.map((author, i) => (
                        <React.Fragment key={`author${i}`}>
                           <span>{author}</span>
                           {i !== autoresArticulo.length - 1 && ", "}
                        </React.Fragment>
                     ))}
                  </p>
               )}
               {etiquetasArticulo && (
                  <ul className="m-tabs-sec__details-liltags">
                     {etiquetasArticulo.map((item, i) => (
                        <li key={`etiq${i}`}>
                           <p>{item}</p>
                        </li>
                     ))}
                  </ul>
               )}
               <div className="m-article-content-description m-article-content-description__separator" />
            </div>
         )}
      </div>
   );
};

export default ArticleContentDescription;
