import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { ICarrusel2Props } from '../carrusel2';

function carrusel2Adapter(data: any): ICarrusel2Props {
    return {
        title: data.tituloGeneral,
        description: documentToReactComponents(data.descripcion.json),
        bgImage: data.listaImagenes[0].file.url,
        bgImage2: data.listaImagenes[1].file.url,
        cardsList:
            data?.referenciaPlantillaContenido?.map((card) => ({
                image: card.imagenPrincipal[0].file.url,
                title: card.tituloBanner,
                description: documentToReactComponents(card.descripcion.json),
                bgColor: card.tituloDescripcion,
                textColor: card.tipoTitulo,
                redirect: card.redireccionBoton ? card.redireccionBoton : '',
                btnTitle: card.tituloBoton,
            })) ?? [],
    };
}

export default carrusel2Adapter;
