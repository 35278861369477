import axios from "axios";
import parseXMLuser from "../utils/parseXMLuser";
import { getAccessToken } from "../../../../services/accessToken";

// definition of environment variables
const apiVerificationUser = process.env.URL_VERIFICADOR_DERECHOS || '';
const apiVerificationUserKey = process.env.URL_VERIFICADOR_DERECHOS_APIKEY || '';

export const verifyDocument = async ({
   type,
   num,
}: {
   type: any;
   num: any;
}) => {
   try {
      // get token
      

      let responseToken = await getAccessToken();
      let token = responseToken.data.data.id_token;


      // verify user
      var headers = {
         apikey: apiVerificationUserKey,
         Authorization: "Bearer " + token,
         "Content-Type": "application/xml",
      };
      var raw = `<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:urn="urn:comfama.com.co:verificador_de_derechos:pscd_verificarderecho">\r\n   <soapenv:Header/>\r\n   <soapenv:Body>\r\n      <urn:VERIFICADOR_DE_DERECHOS_PSCD_VerificarDerecho_MT>\r\n         <document>${num}</document>\r\n         <document_type>${type}</document_type>\r\n         <service>6</service>\r\n         <application>1</application>\r\n      </urn:VERIFICADOR_DE_DERECHOS_PSCD_VerificarDerecho_MT>\r\n   </soapenv:Body>\r\n</soapenv:Envelope>`;
      let responseVerfication = await axios.post(
         apiVerificationUser,
         raw,
         { headers }
      );
      let xml = responseVerfication.data;


      // parse xml and return value
      return parseXMLuser({ xml, num, type });
   } catch (error) {
      console.log(error);
      return false;
   }
};
