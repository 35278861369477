import React, { useState, useEffect, useMemo } from 'react'
import Description from "../../atoms/description/description";
import GalleryCard from '../../molecules/gallery-card/galleryCard';
import ReviewSlider from '../../molecules/review-slider/reviewSlider';
import { normalizeTwoWords } from "../../../shared/utils/stringUtilities";
import { ReferenciaPlantillaContenido } from '../../../interfaces/ReferenciaPlantillaContenido';
import { ReferenciaContenido } from "../../../interfaces/ReferenciaContenido";
import useBrowserMode from '../../../shared/hooks/useBrowserMode';
import "./multiGallery.scss"

const MultiGallery = (props: ReferenciaContenido) => {
    const { isBrowser, window } = useBrowserMode();
    const [content, setContent] = useState<ReferenciaPlantillaContenido[]>([]);
    const [pager, setPager] = useState<number>(0);
    const [limit, setLimit] = useState<number>(3);
    const isMoreSix = props.referenciaPlantillaContenido.length > limit;
  
    useEffect( () => {
      change();
      isBrowser && window.addEventListener('resize', change);
      return () => window.removeEventListener('resize', change);
   }, [] )
  
   useMemo( () => reset(), [limit] )
  
   function change() {
      if(isBrowser) {
         setLimit(3);
      }
   }
  
   function reset() {
      setContent( isMoreSix ? props.referenciaPlantillaContenido.slice(0, limit) : props.referenciaPlantillaContenido );
      setPager(limit);
   }
  
    const getMore = () => {
      setContent(content.concat(props.referenciaPlantillaContenido.slice(pager, pager+limit)));
      setPager(pager+limit);
   }
  
    return (
      <div className="o-multi-gallery">
        <h2 className="o-multi-gallery__title">{props.tituloGeneral}</h2>
        { props.descripcion &&
          <div className="o-multi-gallery__description">
              <Description
                description={JSON.stringify(props.descripcion.json)}
              />
          </div>
        }
        <div className="o-multi-gallery__list hide-on-mobile">
          {content.map((gallery, key) => (
            <GalleryCard gallery={gallery} key={key}/>
          ))}
        </div>
        { props.referenciaPlantillaContenido.length > pager &&
          <button className='o-multi-gallery__button hide-on-mobile' 
              onClick={getMore}
          >Ver más</button>
        }
        <div className="o-multi-gallery__slider show-on-mobile">
          <ReviewSlider 
            reviews={content}
            type="gallery"
            gtmClass={`${normalizeTwoWords(props.nombreCategoria)}${normalizeTwoWords(props.tituloGeneral)}`}
          />
        </div>
      </div>
    );
  };

export default MultiGallery
