import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import React from 'react'
import Slider from 'react-slick'
import { ReferenciaContenido } from '../../../interfaces/ReferenciaContenido'
import Button from '../../atoms/button/button'
import './bodyAsamblea2022.scss'

const BodyAsamblea2022 = (props: ReferenciaContenido) => {

    const sliderSettings = {
		arrows: false,
		dots: true,
		infinite: false,
		speed: 200,
		slidesToShow: 2,
		slidesToScroll: 1
	};

    return (
        <div className='o-body-asamblea2022'>
            <img src={props.imagenPrincipal.file.url} alt="" className="o-body-asamblea2022__bg" />
            <img src={props.listaImagenes[0].file.url} alt="" className="o-body-asamblea2022__paper hide-on-medium" />
            {
                props.referenciaPlantillaContenido.map((card, index) => (
                    <div key={`${index}-card`} className='o-body-asamblea2022__card'>
                        <div className='o-body-asamblea2022__card__left'>
                        <img src={props.listaImagenes[2].file.url} alt="" className="o-body-asamblea2022__card__left__paper show-on-medium" />
                            <div className='o-body-asamblea2022__card__left__info'>
                                <h2>{card.tituloBanner}</h2>
                                {documentToReactComponents(card.descripcion.json)}
                                <div className="o-body-asamblea2022__card__left__info__btn">
                                    <Button type='link' classname='primary' text={card.tituloBoton} isDisabled={false} href={card.redireccionBoton}/>
                                </div>
                            </div>
                        </div>
                        <div className='o-body-asamblea2022__card__right'>
                            {
                                card.imagenPrincipal && <img src={card.imagenPrincipal[0].file.url} alt={card.imagenPrincipal[0].file.fileName} />
                            }
                            {
                                card.tituloDescripcion && <h3 className="o-body-asamblea2022__card__right__title">{card.tituloDescripcion}</h3>
                            }
                            {
                                card.redireccionTabs &&
                                <div className="o-body-asamblea2022__card__right__tabs hide-on-medium">
                                    {
                                        card.redireccionTabs.map((tab, index) => (
                                            <a key={`${index}-tab`} href={tab.redireccionTab} rel='noreferrer follow' className="o-body-asamblea2022__card__right__tabs__tab">
                                                <img src={tab.imagenLibro.file.url} alt={tab.imagenLibro.file.fileName} />
                                                <div className="o-body-asamblea2022__card__right__tabs__tab__info">
                                                    <span>{tab.autorLibro}</span>
                                                    <h4>{tab.nombreTab}</h4>
                                                </div>
                                            </a>
                                        ))
                                    }
                                </div>
                            }
                            {
                                card.redireccionTabs &&
                                <div className="show-on-medium">
                                    <Slider {...sliderSettings} className='o-body-asamblea2022__card__right__slider'>
                                        {
                                            card.redireccionTabs.map((tab, index) => (
                                                <a key={`${index}-tab`} href={tab.redireccionTab} rel='noreferrer follow' className="o-body-asamblea2022__card__right__tabs__tab">
                                                    <img src={tab.imagenLibro.file.url} alt={tab.imagenLibro.file.fileName} />
                                                    <div className="o-body-asamblea2022__card__right__tabs__tab__info">
                                                        <span>{tab.autorLibro}</span>
                                                        <h4>{tab.nombreTab}</h4>
                                                    </div>
                                                </a>
                                            ))
                                        }
                                    </Slider>

                                </div>
                            }
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default BodyAsamblea2022
