import React from "react";

type LayoutProps = {
  columns?: string;
  gap?: string;
  children: React.ReactNode;
};

const Layout = (props: LayoutProps) => {
  const { children } = props;
  return (
    <section
      style={{
        display: "grid",
        gridTemplateColumns: props.columns || "1fr",
        gap: props.gap || "1rem"
      }}
    >
      {children}
    </section>
  );
};

export default Layout;
