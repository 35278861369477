import React from 'react';
import Button from '../../atoms/button/button';
import './cardCarousel.scss';

interface CardCarouselInterface {
    productTypology: string;
    modality: string;
    percentage: Number;
    name: string;
    description: string;
    image: any;
    url: any;
    gtm: string;
    utm: string;
}

const CardCarousel = (props: CardCarouselInterface) => {
    const {
        productTypology,
        modality,
        percentage,
        name,
        description,
        image,
        url,
        gtm,
        utm
    } = props;

    return (
        <div className={`m-card-carousel ${gtm}`}>
            <div className={`m-card-carousel__header ${gtm}`}>
                <img className={`m-card-carousel__header__img ${gtm}`} src={image} />
            </div>
            <div className={`m-card-carousel__body ${gtm}`}>
                <div className={`m-card-carousel__body__description ${gtm}`}>
                    <h4 className={`${gtm}`}>{productTypology}</h4>
                    <h4 className={`${gtm}`}>Modalidad: <span>{modality}</span></h4>
                </div>
                {
                    percentage > 0 &&
                    <div className={`m-card-carousel__body__percentage ${gtm}`}>
                        <div className={`m-card-carousel__body__percentage__text ${gtm}`}>
                            {percentage}% recomendado para ti
                        </div>
                        <div className={`m-card-carousel__body__percentage__container-bar ${gtm}`}>
                            <div className={`m-card-carousel__body__percentage__container-bar m-card-carousel__body__percentage__container-bar--magenta ${gtm}`} m-card-carousel__body__percentage__container-bar--magenta style={{ width: percentage + '%' }}></div>
                        </div>
                    </div>
                }
                <div className={`m-card-carousel__body__info ${gtm}`}>
                    <h4 className={`${gtm}`}>{name}</h4>
                    <p className={`${gtm}`}>{description}</p>
                </div>
            </div>
            <Button
                type='link'
                href={url + utm}
                classname="primary m-card-carousel__btn"
                text="COMPRAR"
                isDisabled={false}
                gtmMark={gtm}
            />
        </div>
    );
};

export default CardCarousel;