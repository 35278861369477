import React from 'react';
import Loading from '../../atoms/loading/loading';
import './loadingAlgoritmo.scss';

interface LoadingAlgoritmoInterface {
    text: string;
}

const LoadingAlgoritmo = (props: LoadingAlgoritmoInterface) => {
    return (
        <div className="m-loading-algoritmo">
            <Loading />
            <span className="m-loading-algoritmo__text">{props.text}</span>
        </div>
    )
}

export default LoadingAlgoritmo;
