import React from 'react'
import Description from '../../atoms/description/description';
import "./howItWorksContainer.scss"

export interface IHowItWorksContainerProps {
	title: string;
	image: string;
	description: React.ReactNode;
}

const HowItWorksContainer = (props: IHowItWorksContainerProps) => {

	const { image, title, description } = props;

	return (
		<div className="o-how-it-works-container">
			<img className="o-how-it-works-container__image" src={image} alt={title} />
			<div className='o-how-it-works-container-content'>
				<h2 className='o-how-it-works-container-content__title'>{title}</h2>
				{
					description && 
					<div className='o-how-it-works-container-content__description'>
						<Description description={JSON.stringify(description)} noCentered />
					</div>
				}
			</div>
		</div>
	)
}

export default HowItWorksContainer
