import React from 'react';
import './switcher.scss';

const Switcher = (props: SwitcherInterface) => {

    return (    
        <label className="a-switcher">
            <input type="checkbox" checked={props.checked} tabIndex={-1} />
            <span className="bh-slider">
                <button type="button" aria-label="Servicios para personas y familias" id='bh-slider-1' onClick={() => props.handleClick(false)}>Personas y familias</button>
                <button type="button" aria-label="Servicios para empresas" id='bh-slider-2' onClick={() => props.handleClick(true)}>Empresas</button>
            </span>
        </label>
    )
};

interface SwitcherInterface {
    checked: boolean;
    handleClick: any;
}

export default Switcher;
