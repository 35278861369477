import React, { useEffect, useRef, useState } from 'react';
import {
    Box,
    Card,
    CardContent,
    Container,
    Fade,
    Grow,
    Typography,
} from '@material-ui/core';
import NextArrowButton from '../../atoms/next-arrow-button/nextArrowButton';
import PrevArrowButton from '../../atoms/prev-arrow-button/prevArrowButton';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { normalizeTwoWords } from '../../../shared/utils/stringUtilities';
import styles from './carrusel1.module.scss';
import './carrusel1.scss';

export interface ICarrusel1Props {
    title: string;
    subtitle: string;
    description: any;
    cardsList: [
        {
            image: string;
            name: string;
            description: any;
            role: string;
            category: string;
        },
    ];
}

const Carrusel1 = ({
    title,
    subtitle,
    description,
    cardsList,
}: ICarrusel1Props) => {
    const [isHovered, setIsHovered] = useState(false);
    const [selected, setSelected] = useState(-1);
    const [isVisible, setIsVisible] = useState(false);
    const [isVisible2, setIsVisible2] = useState(false);

    function handleMouseEnter(index) {
        setIsHovered(true);
        setSelected(index);
    }
    function handleMouseLeave() {
        setIsHovered(false);
        setSelected(-1);
    }

    const elemRef = useRef(null);
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                setIsVisible(entries[0].isIntersecting);
            }
        });
        if (elemRef.current) {
            observer.observe(elemRef.current);
        }
        return () => observer.disconnect();
    }, []);

    const elemRef2 = useRef(null);
    useEffect(() => {
        const observer2 = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                setIsVisible2(entries[0].isIntersecting);
            }
        });
        if (elemRef2.current) {
            observer2.observe(elemRef2.current);
        }
        return () => observer2.disconnect();
    }, []);

    const settings = {
        dots: false,
        infinete: false,
        slidesToShow: 4,
        slidesToScroll: 4,
        autoplay: false,
        nextArrow: <NextArrowButton />,
        prevArrow: <PrevArrowButton />,
        responsive: [
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 968,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <Container
            component="section"
            className={`${styles.o_carrusel1} o_carrusel1`}
        >
            <Grow in={isVisible} timeout={1000}>
                <Box className={styles.o_carrusel1__intro}>
                    <Typography
                        className={styles.o_carrusel1__h2}
                        variant="h2"
                        ref={elemRef}
                    >
                        {title}
                    </Typography>
                    <Typography
                        className={styles.o_carrusel1__subtitle}
                        variant="body1"
                        ref={elemRef}
                    >
                        {subtitle}
                    </Typography>
                    <Typography
                        className={styles.o_carrusel1__text}
                        variant="body2"
                        ref={elemRef}
                    >
                        {description}
                    </Typography>
                </Box>
            </Grow>
            <Fade in={isVisible2} timeout={2000}>
                <div ref={elemRef2}>
                    <Slider {...settings} className="o_carrusel1__slider">
                        {cardsList.map((card: any, index: number) => (
                            <Card
                                key={`carrusel1-card${index}-${normalizeTwoWords(
                                    card.name,
                                )}`}
                                className={`o_carrusel1__slide ${
                                    selected === index && isHovered
                                        ? ' o_carrusel1__slide__open'
                                        : ''
                                }`}
                                onMouseEnter={() => handleMouseEnter(index)}
                                onMouseLeave={() => handleMouseLeave()}
                            >
                                <img
                                    src={card.image}
                                    alt={card.name}
                                    loading="eager"
                                    className={`o_carrusel1__img ${
                                        selected === index && isHovered
                                            ? ' o_carrusel1__img__open'
                                            : ''
                                    }`}
                                />
                                <Typography
                                    variant="body1"
                                    component="span"
                                    className={`o_carrusel1__cat ${
                                        selected === index && isHovered
                                            ? ' o_carrusel1__cat__open'
                                            : ''
                                    }`}
                                >
                                    {card.category}
                                </Typography>
                                <CardContent
                                    className={`o_carrusel1__info ${
                                        selected === index && isHovered
                                            ? ' o_carrusel1__info__open'
                                            : ''
                                    }`}
                                >
                                    <Typography
                                        variant="body2"
                                        className={`o_carrusel1__desc
                                         ${
                                             selected === index && isHovered
                                                 ? ' o_carrusel1__desc__open'
                                                 : ''
                                         }`}
                                    >
                                        {card.description}
                                    </Typography>
                                    {card.name && (
                                        <Typography
                                            variant="h3"
                                            className="o_carrusel1__h3"
                                        >
                                            {card.name}
                                        </Typography>
                                    )}
                                    <span
                                        className={`o_carrusel1__role 
                                        ${
                                            selected === index && isHovered
                                                ? ' o_carrusel1__role__open'
                                                : ''
                                        }`}
                                    >
                                        {card.role}
                                    </span>
                                </CardContent>
                            </Card>
                        ))}
                    </Slider>
                </div>
            </Fade>
        </Container>
    );
};

export default Carrusel1;
