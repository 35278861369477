import React, { useEffect, useRef, useState } from 'react';
import { Container, Grow, Typography } from '@material-ui/core';
import styles from './warning.module.scss';

export interface IWarningProps {
    text: any;
}

const Warning = ({ text }: IWarningProps) => {
    const [isVisible, setIsVisible] = useState(false);

    const elemRef = useRef(null);
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                setIsVisible(entries[0].isIntersecting);
            }
        });
        if (elemRef.current) {
            observer.observe(elemRef.current);
        }
        return () => observer.disconnect();
    }, []);

    return (
        <Grow in={isVisible} timeout={1000}>
            <Container component='section' className={styles.o_warning} ref={elemRef}>
                <Typography className={styles.o_warning__text}>
                    {text}
                </Typography>
            </Container>
        </Grow>
    );
};

export default Warning;
