import React, { useEffect, useState } from "react";
import { ReferenciaContenido } from "../../../interfaces/ReferenciaContenido";
import "./readTime.scss";
import useBrowserMode from "../../../shared/hooks/useBrowserMode";

const Readtime = (props: ReferenciaContenido) => {
   const wordsperminute = (lenght: number) => {
      const estimated = lenght / 150;
      return Math.round(estimated);
   };
   const replaceText = (desc: string) => {
      if (timeRead === 1)
         return desc
            .replace("minutos", "minuto")
            .replace("$minuto", String(timeRead));

      return desc.replace("$minuto", String(timeRead));
   };

   const { isBrowser, document } = useBrowserMode();
   const [timeRead, setTimeRead] = useState(0);

   useEffect(() => {
      if (isBrowser) {
         const contentFlavor = document.querySelector(`.${props.tituloBoton}`)
            ?.textContent;
         setTimeRead(wordsperminute(contentFlavor?.split(" ").length || 0));
      }
   }, []);

   return (
      <div className="flavorcont">
         <span className="flavorcont-text">
            {replaceText(props.tituloGeneral)}
         </span>
      </div>
   );
};
export default Readtime;
