import React, { useState, useEffect, useMemo } from "react";
import Description from "../../atoms/description/description";
import DocumentCard from "../../atoms/document-card/documentCard";
import { ReferenciaPlantillaContenido } from '../../../interfaces/ReferenciaPlantillaContenido';
import { ReferenciaContenido } from "../../../interfaces/ReferenciaContenido";
import { normalizeTwoWords } from "../../../shared/utils/stringUtilities";
import useBrowserMode from '../../../shared/hooks/useBrowserMode';
import "./documentsPlayer.scss";
import ReviewSlider from "../review-slider/reviewSlider";

const DocumentsPlayer = (props: ReferenciaContenido) => {

  const { isBrowser, window } = useBrowserMode();
  const [content, setContent] = useState<ReferenciaPlantillaContenido[]>([]);
  const [pager, setPager] = useState<number>(0);
  const [limit, setLimit] = useState<number>(3);
  const isMoreSix = props.referenciaPlantillaContenido.length > limit;

  useEffect( () => {
    change();
    isBrowser && window.addEventListener('resize', change);
    return () => window.removeEventListener('resize', change);
 }, [] )

 useMemo( () => reset(), [limit] )

 function change() {
    if(isBrowser) {
       setLimit(3);
    }
 }

 function reset() {
    setContent( isMoreSix ? props.referenciaPlantillaContenido.slice(0, limit) : props.referenciaPlantillaContenido );
    setPager(limit);
 }

  const getMore = () => {
    setContent(content.concat(props.referenciaPlantillaContenido.slice(pager, pager+limit)));
    setPager(pager+limit);
 }

  return (
    <div className="m-documents-player">
      <h2 className="m-documents-player__upper__title">{props.tituloGeneral}</h2>
      { props.descripcion &&
        <div className="m-documents-player__upper__description">
            <Description
              description={JSON.stringify(props.descripcion.json)}
            />
        </div>
      }
      <div className="m-documents-player__list hide-on-mobile">
        {content.map((document, key) => (
          <DocumentCard document={document} key={key}/>
        ))}
      </div>
      { props.referenciaPlantillaContenido.length > pager &&
        <button className='m-documents-player__button hide-on-mobile' 
            onClick={getMore}
        >Ver más</button>
      }
      <div className="m-documents-player__list show-on-mobile">
        <ReviewSlider 
            reviews={content}
            type="document"
            gtmClass={`${normalizeTwoWords(props.nombreCategoria)}${normalizeTwoWords(props.tituloGeneral)}`}
        />
      </div>
    </div>
  );
};

export default DocumentsPlayer;
