import React, { useRef, useState, useEffect } from "react";
import {
  GoogleMap,
  useLoadScript,
  MarkerClusterer,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import markerEmpty from '../../../assets/images/icons/Marker.svg'
import markerHover from '../../../assets/images/icons/Marker_hover.svg'
import InfoBox from "../info-box/infoBox";
import "./mapComponent.scss";

const libraries: any = ["places"];

type MapProps = {
  sedes: any[];
  geolocationLat: number;
  geolocationLon: number;
  center: {};
  zoom: number;
};

export default function Map({
  sedes,
  geolocationLat,
  geolocationLon,
  center,
  zoom
}: MapProps) {

  const [selected, setSelected] = useState<any | null>(null);
  const [chosen, setChosen] = useState<number | null>(null);
  const [defaultCenter, setDefaultCenter] = useState<any | null>(center);

  useEffect(() => {
    setDefaultCenter(center);
  }, [center]);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.GOOGLE_MAPS_API_KEY,
    libraries,
  });

  const mapRef = useRef(null);
  function handleLoad(map: any) {
    mapRef.current = map;
  }

  if (loadError)
    return <div>Error al cargar el mapa. Actualice la página.</div>;
  if (!isLoaded) return <div>Cargando mapa...</div>;

  function handleCenter() {
    if (!mapRef.current) return;
    let currentMap: any = mapRef.current;
    const newCenter = currentMap.getCenter().toJSON();
    setDefaultCenter(newCenter);
  }

  const google = window.google;

  const handleGeolocation = () => {
    return (
      <Marker
        animation={google.maps.Animation.DROP}
        position={{ lat: geolocationLat, lng: geolocationLon }}
        title="Mi ubicación"
      />
    );
  };

  return (
    <div className="m-map__container">
      <GoogleMap
        id="map"
        mapContainerStyle={{
          height: "100%",
          width: "100%",
        }}
        zoom={zoom}
        center={defaultCenter}
        onDragStart={handleCenter}
        onLoad={handleLoad}
        options={{scrollwheel: false}}
      >
        <MarkerClusterer maxZoom={17} averageCenter={true} enableRetinaIcons>
          {(clusterer) =>
            sedes.map((sede, i) => (
              <Marker
                clusterer={clusterer}
                key={i}
                icon={{
                  url:
                    chosen === i || zoom === 17 ? markerHover : markerEmpty,
                    scaledSize: new window.google.maps.Size(70, 70),
                }}
                position={{
                  lat: sede.location.lat,
                  lng: sede.location.lon,
                }}
                title={`Comfama ${sede.name}`}
                onClick={() => {
                  setSelected(sede);
                }}
                onMouseOver={() => {
                  setChosen(i);
                }}
                onMouseOut={() => {
                  setChosen(null);
                }}
              ></Marker>
            ))
          }
        </MarkerClusterer>
        {geolocationLon !== 0 ? handleGeolocation() : null}
        {selected ? (
          <InfoWindow
            position={{
              lat: selected.location.lat,
              lng: selected.location.lon,
            }}
            onCloseClick={() => {
              setSelected(null);
            }}
          >
            <InfoBox sede={selected} zoom={zoom} />
          </InfoWindow>
        ) : null}
      </GoogleMap>
    </div>
  );
}
